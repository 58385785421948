var garageMenu = {
    /**
    * Initializes the menu toggle functionality and sets up event listeners.
    * 
    * This function binds a click event to elements with the class "toggle-icon" to
    * toggle the expansion of menu items. It also highlights the current menu item
    * and handles window resize events to adjust the menu layout accordingly.
    */
    init: function () {
        $(document).on("click", ".toggle-icon", function () {
            var $menuWrapper = $(".menu-items-wrapper");
            var $menuItem = $(this).closest(".menu-item");
            var $icon = $(this).find("i");

            if ($menuWrapper.hasClass("expanded")) {
                $menuWrapper.removeClass("expanded");
                $icon.removeClass("fa-angle-up").addClass("fa-angle-down");
                $menuItem.siblings().slideUp();
            } else {
                $menuWrapper.addClass("expanded");
                $icon.removeClass("fa-angle-down").addClass("fa-angle-up");
                $menuItem.siblings().slideDown();
            }
        });

        this.highlightMenuItem();
        $(window).on("resize", this.handleResize.bind(this)).trigger("resize");
    },

    /**
    * Highlights a menu item in the secondary navigation based on the class name.
    * 
    * This function searches for a class name in the secondary navigation that matches
    * a menu item. If such a class is found, the corresponding menu item is highlighted
    * by adding the 'highlighted' class to it.
    */
    highlightMenuItem: function () {
        var secondaryNavClass = $(".my-garage-secondary-nav").attr("class").split(" ").find(function (className) {
            return $(".menu-item." + className).length > 0;
        });

        if (secondaryNavClass) {
            $(".menu-item." + secondaryNavClass).addClass("highlighted");
        }
    },

    /**
    * Handles the resize event for the menu, adjusting the display of menu items
    * based on the current window width. If the window width is 768 pixels or more,
    * it ensures that the menu items are fully expanded. If the window width is less
    * than 768 pixels and the menu is not expanded, it collapses non-highlighted
    * menu items and updates the toggle icon.
    */
    handleResize: function () {
        const $menuWrapper = $(".menu-items-wrapper");

        if ($(window).width() >= 768) {
            $menuWrapper.removeClass("expanded");
            $(".menu-item").siblings().slideDown(0);
        } else if (!$menuWrapper.hasClass("expanded")) {
            $(".menu-item.highlighted").siblings().slideUp(0);
            $(".toggle-icon i").removeClass("fa-angle-up").addClass("fa-angle-down");
        }
    }
};

module.exports = garageMenu;
