"use strict";

const CarfaxFormCtrl = require("../FormCtrl/carfaxFormCtrl");
const visualizer = require("./visualizer");
let carfaxCtrl;

/**
 * @function
 * @description To load the car images when the page is landing
 */
function loadCarImages() {
    const $carSpinners = $(".car-image .loader");
    const $carContainers = $(".car-container");
    $carSpinners.show();

    visualizer.initMygarageVehicleImageUrl($carContainers);
    $carSpinners.hide();
}

/**
 * @function
 * @description Dinamically sets height of mobile screens for Carfax modal's content
 */
function setMobileViewHeight() {
    let mobileScreenSize = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("dynamicScreenSize", `${mobileScreenSize}px`);
}

/**
 * @function
 * @description Select the current vehicle when the page is loaded in case we have the vehicle id present in selectedVehicle URL's param
 * @returns {void}
 */
function selectCurrentVehicle() {
    const vehicles = $(".car-container");
    const url = new URLSearchParams(window.location.search);
    const vehicleId = url.get("selectedVehicle");

    if (vehicleId) {
        vehicles.filter((_, vehicle) => {
            const $vehicle = $(vehicle);
            const currentId = $vehicle.data("vehicle-id");

            if (currentId === vehicleId) {
                $vehicle.trigger("click");
                $vehicle.find("input[type=\"radio\"]").trigger("click");
            }
        });

        carfaxCtrl.setCurrentVehicle(vehicleId);
    }
}

/**
 * @function
 * @description Add the listeners page's exclusive listeners
 * @returns {void}
 */
function addListeners() {
    // Listen to the resize event on mobile screens to update the modal content's height to new screen size
    window.addEventListener("resize", () => {
        setMobileViewHeight();
    });

    // Cars cards, select the vehicle clicked
    $(".js-car-container").on("click", function () {
        const $this = $(this);
        const vehicle = carfaxCtrl.getVehicle($this.data("vehicle-id")); // eslint-disable-line

        if (vehicle) {
            // Filling the form with the existents values
            if (vehicle.plate) {
                carfaxCtrl.setField("plate", vehicle.plate);
            }

            if (vehicle.vin) {
                carfaxCtrl.setField("vin", vehicle.vin);
            }

            const dataStored = carfaxCtrl.getFromSessionStorage(vehicle.id); // eslint-disable-line
            if (dataStored) {
                carfaxCtrl.renderCarfaxResults(dataStored.serviceHistory, carfaxCtrl);
            } else {
                carfaxCtrl.switchVehicleDataView("no-service");
            }
        }

        if (carfaxCtrl.validateFields()) { // eslint-disable-line
            carfaxCtrl.elements.$continueButton.removeAttr("disabled"); // eslint-disable-line
        } else {
            carfaxCtrl.elements.$continueButton.attr("disabled", "true"); // eslint-disable-line
        }
    });
}

exports.init = function () {
    // Initialize the CarfaxFormCtrl for the Carfax service history query modal
    carfaxCtrl = new CarfaxFormCtrl(CarfaxFormCtrl.getConfig("carfax"));

    loadCarImages();
    setMobileViewHeight();
    addListeners();
    selectCurrentVehicle();
};
