"use strict";

var $mobileMenuTrigger = $(".mobile-submenu-trigger");
var _ = require("lodash");
var myGarageMenu = require("./my-garage-menu");
var { storeLocatorHelpers } = require("./store-locator/store-locator-helpers");

// set new Drilldown menu Back button text
$mobileMenuTrigger.on("click", function () {
    var newDrilldownBackButtonText = $(this).closest(".mega-menu").find(".mobile-menu-item-link.hide-for-medium span").text();
    if (newDrilldownBackButtonText.length > 0) {
        $(".js-drilldown-back a").text(newDrilldownBackButtonText);
    }
});

/**
 * Initialize Custom Menu Overlay Events
 */
function initializeCustomMenuOverlayEvents() {
    var $document = $(document);
    var $window = $(window);
    var $customMenuOverlay = $(".custom-menu-overlay");
    var $menuIcon = $(".menu-icon");

    // show the dropdown menu overlay on Desktop
    $document.on("show.zf.dropdownmenu", function () {
        $customMenuOverlay.show();
    });
    
    // hide the dropdown menu overlay on Desktop
    $document.on("hide.zf.dropdownmenu", function () {
        $customMenuOverlay.hide();
    });

    // add overlay on Mobile menu toggle
    $document.on("toggled.zf.responsiveToggle", function () {
        if ($menuIcon.hasClass("is-active")) {
            $customMenuOverlay.hide();

            var $drilldownSubmenuActive = $(".is-drilldown-submenu.is-active");
            if ($drilldownSubmenuActive.length > 0) {
                $("#mainNav").trigger("hide.zf.drilldown");
                $drilldownSubmenuActive.removeClass("is-active");
            }
        } else {
            $customMenuOverlay.show();
        }
    });

    // close the Mobile menu on the custom menu overlay click event
    $customMenuOverlay.on("click", function () {
        if ($window.width() < 768 && $menuIcon.hasClass("is-active")) {
            $menuIcon.trigger("click");
        }
    });
    
    $window.resize(function () {
        // hide the custom menu overlay on window resize with more than 768px width
        if ($window.width() >= 768) {
            $customMenuOverlay.hide();
        }
    });
}

initializeCustomMenuOverlayEvents();

var productselector = require("./productselector");

/**
 * @description Google places search actions
 */
var locationFormActions = function () {
    var $wrapper             = $(".headerNavLocationWrapper");
    var $trigger             = $("#headerNavLocationCTA");
    var $triggerWrapper      = $("#locationChangeTriggerWrapper");
    var $formWrapper         = $("#locationChangeFormWrapper");
    var $closeForm           = $("#locationChangeFormClose");
    var $changeLocationBox   = $(".psresults-wrapper");

    $trigger.on("click touchstart", function (e) {
        e.preventDefault();
        $wrapper.addClass("active");
        $triggerWrapper.hide();
        $formWrapper.css({"width":200});
        if ($("#locationChangeInput").val() == "") {
            $("#locationChangeInput").focus();
            $closeForm.fadeIn();
        } else {
            $closeForm.fadeIn(300, function () {$("#locationChangeInput").focus();});
        }
    });

    // open change location from sticky box - search results page
    $changeLocationBox.on("click touchstart", "#stickyLocationCTA", function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 800);

        if (Foundation.MediaQuery.current === "small") {
            // open hamburger menu with focus on change location input
            $("#mainMenuWrapper").fadeIn();
            $(".menu-icon").addClass("is-active");
            $("#mobileLocationChangeFormWrapper").css("height", 50);
            $("#mobileLocationChangeTriggerWrapper").addClass("active");
            $("#mobileLocationChangeInput").focus();
        } else {
            $wrapper.addClass("active");
            $triggerWrapper.hide();
            $formWrapper.css({"width":200});
            if ($("#locationChangeInput").val() == "") {
                $("#locationChangeInput").focus();
                $closeForm.fadeIn();
            } else {
                $closeForm.fadeIn(300, function () {$("#locationChangeInput").focus();});
            }
        }
    });

    $closeForm.on("click touchstart", function () {
        if ($wrapper.hasClass("active")) {
            $wrapper.removeClass("active");
            $triggerWrapper.show();
            $formWrapper.css({"width":0});
            $(this).fadeOut();
        }
    });

    // sticky change location box - hidding functionality
    $changeLocationBox.on("click touchstart", ".change-location-box .button", function () {
        $(".change-location-box").fadeOut();
    });
};

/**
 * Calculates the vertical and horizontal offsets for positioning a login modal.
 *
 * This function determines the offsets based on the current media query size and the dimensions
 * of the window and login modal. For smaller screens (small and medium media queries), it centers
 * the modal horizontally and applies a fixed vertical offset. For larger screens, it calculates
 * offsets based on the provided offset values.
 *
 * @param {number} windowWidth - The width of the browser window.
 * @param {number} loginModalWidth - The width of the login modal.
 * @param {Object} $offset - An object containing the top and left offset values.
 * @param {number} $offset.top - The top offset value.
 * @param {number} $offset.left - The left offset value.
 * @returns {Object} An object containing the calculated vertical and horizontal offsets.
 * @returns {number} return.vOffset - The calculated vertical offset.
 * @returns {number} return.hOffset - The calculated horizontal offset.
 */
function calculateOffsets(windowWidth, loginModalWidth, $offset) {
    let vOffset, hOffset;
    if (Foundation.MediaQuery.current === "small" || Foundation.MediaQuery.current === "medium") {
        vOffset = 68;
        hOffset = Math.round((windowWidth - loginModalWidth) / 2);
    } else {
        vOffset = $offset.top + 34;
        hOffset = $offset.left - 200;
    }
    return { vOffset, hOffset };
}

/**
 * Updates the position of the login modal based on the current window size and header navigation actions offset.
 * This function calculates the horizontal and vertical offsets required to position the modal correctly
 * and applies these offsets to the modal using jQuery.
 *
 * @returns {void}
 */
function updateModalOffsets() {
    var $modal = $(".login-modal");
    var $offset = $("#headerNavActions").offset();
    var loginModalWidth = $(".login-modal").outerWidth();
    var windowWidth = $(window).width();
    var offsets = calculateOffsets(windowWidth, loginModalWidth, $offset);

    // Apply the offsets to the modal using jQuery
    $modal.css({
        top: offsets.vOffset + "px",
        left: offsets.hOffset + "px"
    });
}

/**
 * Initializes and manages the login modal behavior.
 * 
 * This function sets up the login modal by calculating its position, initializing it with Foundation's Reveal plugin, 
 * and handling various user interactions such as closing the modal and resizing the window.
 * 
 * @returns {void}
 * 
 * @fires click - On elements with the class `.login-no-thanks` to close the modal.
 * @fires closed.zf.reveal - On elements with the attribute `data-reveal` to handle post-close actions.
 * 
 * @listens window:resize - Debounced event to update modal offsets on window resize.
 * 
 * @requires Foundation.Reveal - Foundation's Reveal plugin for modal functionality.
 * @requires Urls.setLoginPopupSession - URL endpoint to set the login popup session state.
 * 
 * @example
 * // Automatically invoked when the page is loaded to initialize the login modal.
 * initLoginModal();
 */
function initLoginModal() {
    var $document = $(document);
    var $modal = $(".login-modal");
    if ($modal.length > 0) {
        var $offset = $("#headerNavActions").offset();
        $modal.foundation("destroy");

        var loginModalWidth = $(".login-modal").outerWidth();
        var windowWidth = $(window).width();
        var modalData = calculateOffsets(windowWidth, loginModalWidth, $offset);
        var initializedModal = new Foundation.Reveal($modal, modalData);
        initializedModal.open();

        $document.on("click", ".login-no-thanks", function (e) {
            e.preventDefault();
            $(".login-modal").find(".close-button").click();
        });

        $("[data-reveal]").on("closed.zf.reveal", function (event) {
            event.preventDefault();
            var modal = $(this);
            var dataObject = {
                "closed": true
            };

            $.ajax({
                type: "POST",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.setLoginPopupSession,
                data: JSON.stringify(dataObject)
            }).done(function () {
                modal.close();
            });
        });
        
        // Debounce the resize event to avoid excessive firing
        $(window).on("resize", _.debounce(function () {
            updateModalOffsets();
        }, 200));
    }
}

/**
 * Sets a session variable to hide the province modal message until the next day or the session is cleared.
 * @returns {Object} jQuery ajax object
 */
function setProvinceModalMessageSession() {
    var dataObject = {
        "provinceModalMessageClosed": true
    };

    return $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: Urls.setProvinceModalMessageSession,
        data: JSON.stringify(dataObject)
    });
}

/**
 * If the province modal message has content being rendered, open modal. On close or redirect, calls
 * setProvinceModalMessageSession.
 */
function initProvinceModalMessage() {
    var $provinceModal = $("#province-modal-message");
    var $provinceModalContent = $(".province-modal-content");

    if ($provinceModal.length > 0 && $provinceModalContent.children().length > 0) {
        $provinceModal.foundation("open");
        setProvinceModalMessageSession();

        if (Foundation.MediaQuery.current.indexOf("large") !== -1) {
            var modalData = { vOffset: 394 };
            $provinceModal.foundation("destroy");

            var desktopProvinceModal = new Foundation.Reveal($provinceModal, modalData);
            desktopProvinceModal.open();
        } else {
            $provinceModal.foundation("open");
        }
        $provinceModal.on("closed.zf.reveal", function () {
            initLoginModal();
        });
    }
}

/**
* Toggles the express consent checkbox when the newsletter signup or add to email list checkbox is changed
*/
function initExpressConsent() {
    $("#dwfrm_singleshipping_shippingAddress_addressFields_addtoemaillist, #dwfrm_profile_personalinfo_newslettersignup").on("change", function () {
        var $this = $(this);
        var consentButton = $this.attr("id");
        var caslComment = "";
        if (consentButton.indexOf("newslettersignup") > -1) {
            caslComment = Resources.REGISTRATION_EXPRESS_CONSENT;
        } else if (consentButton.indexOf("addtoemaillist")  > -1) {
            caslComment = Resources.SHIPPING_EXPRESS_CONSENT;
        }

        if ($this.is(":checked")) {
            $("#expressConsent, #expressConsent-source, #formType").trigger("click");
            $("#caslComment").val(caslComment);
        } else {
            $("#expressConsent-default, #expressConsent-source-default, #formType-default").trigger("click");  
            $("#caslComment").val("");
        }
    });
}

/**
* Checks if an element is visible in the viewport
* @param {Element} element - the element to check
* @returns {boolean} - true if the element is visible, false otherwise
*/
function checkElementVisible(element) {
    if (element !== undefined && element.offsetWidth > 0 && element.offsetHeight > 0) {
        var elementRect = element.getBoundingClientRect();
        var windowHeight = window.innerHeight;
        var elementTop = elementRect.top;
        var elementBottom = elementRect.bottom;

        return (elementTop < windowHeight) && (elementBottom > 0);
    }

    return false;
}

/**
 * Check if the sticky call-to-action (CTA) should be displayed or hidden based on the current screen size and visibility of certain elements.
 * @returns {undefined}
 */
function checkStickyCTA() {
    if (Foundation.MediaQuery.current == "large") {
        return;
    }

    var $footer = $(".footer-copy-wrapper");
    var $stickyCTA = $("#stickyCTA");
    var $staticCTA = $(".brakes-inspection-wrapper .button");

    if ($stickyCTA.length && $footer.length && $staticCTA.length) {
        if (checkElementVisible($footer[0]) || checkElementVisible($staticCTA[0])) {
            if ($stickyCTA.hasClass("slide")) {
                $stickyCTA.removeClass("slide");
            }
        } else {
            if (!$stickyCTA.hasClass("slide")) {
                $stickyCTA.addClass("slide");
            }
        }
    }
}

/**
* Closes the store details sidebar and updates the URL on storelocator page
*/
function closeStoreDetailsSidebar() {
    $(".store-details-sidebar").removeClass("open");
    history.replaceState(undefined, "", Urls.locationsPage);
}

/**
* Closes the store details sidebar when the location in the header is changed
*/
function closeSidebarWhenLocationIsChanged() {
    var $storeDetailsSidebar = $(".store-details-sidebar");
    if ($storeDetailsSidebar.length > 0 && $storeDetailsSidebar.hasClass("open")) {
        closeStoreDetailsSidebar();
    }
}

/**
 * @function
 * @description Initializes the kaltire
 */
var kaltire = function () {
    //menu button toggle
    $(".menu-icon").on("click", function () {
        $(this).toggleClass("is-active");
    });

    //google places search
    function initializeGoogleLocationSearch(inputID) {
        var input = document.getElementById(inputID),
            id    = "#" + inputID;

        // [KT-363] Avoiding initialization on non existing elements
        if (input === null) {
            return;
        }

        var options = {
            componentRestrictions: { country: "ca" }
        };
        var autocomplete = new google.maps.places.Autocomplete(input, options);

        autocomplete.addListener("place_changed", function () {
            var place        = autocomplete.getPlace(),
                city         = place.name,
                lat          = place.geometry.location.lat(),
                long         = place.geometry.location.lng(),
                storeFindUrl = $(id).data("url") + "?latitude=" + lat + "&longitude=" + long + "&city=" + city;

            $(document).trigger("changeLocation");

            $.ajax({
                url: storeFindUrl,
                dataType: "json",
                type: "GET",
            }).done(function () {
                closeSidebarWhenLocationIsChanged();
                window.location.reload();
            });
        });

        $(id).on("keydown", function (e) {
            var keycode = e.keyCode || e.which;
            if (keycode == 13) {
                var place        = autocomplete.getPlace(),
                    city         = place.name,
                    lat          = place.geometry.location.lat(),
                    long         = place.geometry.location.lng(),
                    storeFindUrl = $(this).data("url") + "?latitude=" + lat + "&longitude=" + long + "&city=" + city;

                $.ajax({
                    url: storeFindUrl,
                    dataType: "json",
                    type: "GET",
                }).done(function () {
                    closeSidebarWhenLocationIsChanged();
                    window.location.reload();
                });
            }
        });
    }

    //google places search mobile expand
    function expandMobileGoogleLocationSearch() {
        $("#mobileLocationChangeFormWrapper").css("height", 50);
        $("#mobileLocationChangeTriggerWrapper").addClass("active");
    }

    $("#mobileLocationChangeFormClose").on("touchstart click", function (e) {
        e.preventDefault();
        $("#mobileLocationChangeFormWrapper").css("height", 0);
        $("#mobileLocationChangeInput").val("");
        $("#mobileLocationChangeTriggerWrapper").removeClass("active");
        $(".pac-container").hide();
    });

    $("#mobileLocationChangeTriggerWrapper").on("touchstart click", function (e) {
        e.preventDefault();
        expandMobileGoogleLocationSearch();
    });

    // header search events
    var $searchWrapper = $(".headerSearchWrapper");
    var $mobileSearchWrapper = $("#mobileSearchWrapper");
    var $searchFormTrigger = $(".searchFormTrigger");
    var $searchFormClose = $(".searchFormClose");
    var $mainNav = $("#mainNav");
    var mainNavInitialHeight;
    var $stickyNav = $(".header-main-nav");
    var searchToggleMobile = true;
    var $window = $(window);

    function closeSearchSuggest() {
        $searchWrapper.removeClass("active");
        $mobileSearchWrapper.removeClass("active");
        $(".mobileSearchFormTrigger").removeClass("active");
        $(".search-suggestions").hide();
        $(".seachFormInput").blur();
        $(".seachFormInput").val("");
    }

    function removeDinamicHeight() {
        $mainNav.removeAttr("style");
    }

    $mainNav.on("open.zf.drilldown", function () {
        //Saves base height of menu
        mainNavInitialHeight = $("#mainNav").height();
        var $touchetTrigger = $(event.target);
        var $activeSubmenu = $touchetTrigger.siblings("ul.is-drilldown-submenu");
        var correctHeight = $activeSubmenu.children(".js-drilldown-back").height() + $activeSubmenu.children(".is-drilldown-submenu-item").height();
        $mainNav.animate({height: correctHeight}, 100, function () {
            $mainNav.css("height", correctHeight + "px");
        });
    });

    $mainNav.on("hide.zf.drilldown", function () {
        $mainNav.animate({height: mainNavInitialHeight}, 100, "swing", function () {
            $mainNav.removeAttr("style");
            mainNavInitialHeight = $("#mainNav").height();
        });
    });

    $searchFormTrigger.on("click touchstart", function (e) {
        e.preventDefault();
        $searchWrapper.toggleClass("active");
        $(".seachFormInput").focus();
    });

    $searchFormClose.on("click touchstart", function (e) {
        e.preventDefault();
        closeSearchSuggest();
    });

    $(".mobileSearchFormTrigger").on("click touchstart", function (e) {
        e.preventDefault();
        $mobileSearchWrapper.toggleClass("active");
        if ($mobileSearchWrapper.hasClass("active")) {
            $(".mobileSearchFormTrigger").addClass("active");
            $(".seachFormInput").focus();
            searchToggleMobile = true;
        } else {
            closeSearchSuggest();
            searchToggleMobile = false;
        }
    });

    function openSearchMobile() {
        $searchWrapper.addClass("active");
        if ($searchWrapper.hasClass("active")) {
            $("#mobileSearchWrapper").addClass("active");
            $(".mobileSearchFormTrigger").addClass("active");
        } else {
            openSearchMobile();
        }
    }

    if ($(".mobileHomeSearchFormTrigger").length) {
        // Opens search on load;
        if (Foundation.MediaQuery.current === "small") {
            openSearchMobile();
            searchToggleMobile = false;
        }

        $window.resize(function () {
            if (Foundation.MediaQuery.current === "small" && !$searchWrapper.hasClass("active") && searchToggleMobile) {
                openSearchMobile();
                searchToggleMobile = false;
            } else if (Foundation.MediaQuery.current != "small") {
                removeDinamicHeight();
                if (!searchToggleMobile) {
                    searchToggleMobile = true;
                }
            }
        });

        //Close search wrapper if in mobile window
        $(".menu-icon").on("click touchstart", function () {
            if (Foundation.MediaQuery.current === "small") {
                if ($(".menu-icon").hasClass("is-active")) {
                    closeSearchSuggest();
                } else if (searchToggleMobile) {
                    openSearchMobile();
                }
            }
        });
    }

    //makes logo smaller on scroll
    $window.on("scroll", Foundation.util.throttle(function () {
        if ($stickyNav.length) {
            var windowTop = $window.scrollTop();
            var navPosition = $stickyNav.offset().top;

            $stickyNav.toggleClass("header-main-nav-sticked", windowTop == navPosition);
        }
    }, 500));

    var $infotilewrapperspacearound = $(".info-tile-wrapper-spacearound");

    if (Foundation.MediaQuery.atLeast("large")) {
        $infotilewrapperspacearound.css("height", getTheHighestElement($("div.hompage-info-tile")) + 200);
    }

    $(window).on("changed.zf.mediaquery", function (event, newSize, oldSize) { // eslint-disable-line
        // fix for PS slider when changing breakpoints
        $(".selector_options .orbit-slide.is-active").show();

        if (newSize === "small") {
            $(".menu-icon").removeClass("is-active");
        }

        if (newSize === "medium") {
            removeDinamicHeight();
            closeSearchSuggest();
        }
        if (newSize === "large" || newSize === "xlarge") {
            removeDinamicHeight();
            closeSearchSuggest();
            $infotilewrapperspacearound.css("height", getTheHighestElement($("div.hompage-info-tile")) + 200);
        }
    });

    // responsive tap functionality
    $(".l1MenuItem").on("touchstart", function () {
        if (!$(this).parent().hasClass("is-active")) {
            $("#" + $(this).data("toggle")).foundation("toggle");
        }
    });

    $(window).on("down.zf.accordionMenu", function (event, target) {
        $(target.context).addClass("active-submenu");
    });

    $(window).on("up.zf.accordionMenu", function (event, target) {
        $(target.context).removeClass("active-submenu");
    });

    // alert bar hidding functionality
    $("#homepageAlertBarClose").on("click", function () {
        $("#homepageAlertBar").hide();
    });

    // hello bar hidding functionality
    $("#helloBarClose").on("click", function () {
        $("#helloBarContainer").hide();
        sessionStorage.setItem("helloBarClosed", "true");
        initLoginModal();
    });


    // homepage 3 tiles group functionality
    $(".hompage-info-tile").on({
        mouseenter: function () {
            if (Foundation.MediaQuery.atLeast("large")) {
                var $othersiblings = $(".hompage-info-tile").not(this);
                $othersiblings.css("height", getTheHighestElement($othersiblings));
                $(this).addClass("showbutton");
                $(".info-tile-wrapper").addClass("hovered");
            }
        },

        mouseleave: function () {
            if (Foundation.MediaQuery.atLeast("large")) {
                var $othersiblings = $(".hompage-info-tile").not(this);
                $(this).removeClass("showbutton");
                $(".info-tile-wrapper").removeClass("hovered");
                $othersiblings.css("height", "100%");
            }
        }
    });

    function getTheHighestElement(elems) {
        var heights = elems.map(function ()
        {
            return $(this).outerHeight();
        }).get();

        return Math.max.apply(null, heights);
    }

    $("#homepageThreeAssetGroup img").on("load", function () {
        Foundation.reInit("equalizer");
    });

    // reinitialize the euqalizer on tabs change
    $(".search-results-tabs").on("change.zf.tabs", function () {
        Foundation.reInit("equalizer");
    });

    // Erasing selected vehicle data from the browser session storage when the user logout.
    $(".btn-logout").on("click", () => window && sessionStorage.clear());

    locationFormActions();

    storeLocatorHelpers.executeWithGoogleMaps(storeLocatorHelpers.getGoogleMapLibTypes().PLACES, function () {
        initializeGoogleLocationSearch("locationChangeInput");        // init google search on desktop
        initializeGoogleLocationSearch("mobileLocationChangeInput");  // init google search on mobile
    });

    productselector.init();

    initProvinceModalMessage();
    initExpressConsent();

    window.addEventListener("scroll", _.debounce(function () {
        $window.on("scroll", checkStickyCTA()); //NOSONAR
        $("#stickyCTA").removeClass("hide");
    }));

    // If the user is on the homepage and the province modal message Content Asset is not displayed, open the login modal
    if (pageContext && pageContext.ns === "storefront" && $(".province-modal-content").length == 0) {
        initLoginModal();
    }

    // Initialize my garage menu functionality
    if ($(".my-garage-secondary-nav").length > 0) {
        myGarageMenu.init();
    }
};

module.exports.locationFormActions = locationFormActions;
module.exports.init = kaltire;
