"use strict";
var util = require("../../util");

exports.init = function () {
    var slotPopup;
    var service_id = "";
    var customServiceId = $("#customServiceID").val();
    service_id          = (customServiceId == "null") ? iamota_store_locator.serviceId : customServiceId; // eslint-disable-line

    if ($(".slot-popup").length > 0) {
        slotPopup = new Foundation.Reveal($(".slot-popup")); // eslint-disable-line

        $(document).on("closed.zf.reveal", "[data-reveal][id!=\"noopenslotsavailablepopup\"]", function () {
            $(".edit-appointment").trigger("click");
        });
    }

    if ($("#noopenslotsavailablepopup").length > 0) {
        var noOpenSlotsPopup = new Foundation.Reveal($("#noopenslotsavailablepopup")); // eslint-disable-line
        $(document).on("noopenslotsavailablepopup", function () {
            noOpenSlotsPopup.open();
        });
    }

    function refreshSummary() {
        var $summary      = $("#secondary.summary");
        $summary.fadeOut();

        $summary.load(Urls.summaryRefreshURL, function () { // eslint-disable-line
            $summary.fadeIn("fast");
        });
    }

    function addOpenSlotByDate(storeID, date, hide) {
        $.getJSON(
            iamota_store_locator.getOpenSlotByDate, // eslint-disable-line
            {
                storeID  : storeID,
                date     : date,
                serviceId: service_id
            }
        ).success(function (data) {
            var dataFromResponse = data.data, // eslint-disable-line
                appointmentsData = [],
                appointmentsList = [];

            if (dataFromResponse != undefined && dataFromResponse.length > 0) {
                for (var i = 0; i < dataFromResponse.length; i++) {
                    var appointmentsItem = dataFromResponse[i],
                        currentTime = appointmentsItem.start_time / 60;

                    if (appointmentsData.indexOf(currentTime) === -1) {
                        var appointmentTime = currentTime.toString().split(".")[0],
                            ampm = "AM";

                        if (appointmentTime > 11) {
                            ampm = "PM";
                        }

                        if (appointmentTime > 12) {
                            appointmentTime = appointmentTime - 12;
                        }

                        if (currentTime % 1 !== 0) {
                            appointmentTime = appointmentTime + ":" + 30 + ampm;
                        } else {
                            appointmentTime = appointmentTime + ":" + "00" + ampm;
                        }

                        appointmentsData.push(currentTime);
                        appointmentsItem.time = appointmentTime;
                        appointmentsList.push(appointmentsItem);
                    }

                }

                $(".appointment-date").html(appointmentsTemplate({"appointments": appointmentsList})); // eslint-disable-line
            } else {
                $(".appointment-error-message").removeClass("hide");
            }

            if (hide) {
                $(".appointment-date").hide();
            }
        });
    }

    function returnToCalendar() {
        $("#appointmentSecure").addClass("hide");
        $(".appointment-page").removeClass("hide");
    }

    function prepareConfirmation() {
        var $storeDetails      = $("#store-details");
        var locationName       = $storeDetails.find(".location-item-name").clone()[0];
        var locationAddress    = $storeDetails.find(".location-item-address").clone()[0];
        var $appointmentSecure = $("#appointmentSecure");
        var $addressArea       = $("#appointmentAddress");

        $addressArea.text("");

        locationName.innerHTML = util.capitalizeWords(locationName.innerHTML);
        locationAddress.innerHTML = util.capitalizeWords(locationAddress.innerHTML);
        locationAddress.innerHTML = locationAddress.innerHTML.replace("<br>", " ");

        $addressArea.append(locationName);
        $addressArea.append(locationAddress);

        var rawText = $addressArea.find(".location-item-name").text();
        $addressArea.find(".location-item-name").text(rawText.substring(rawText.indexOf("#")));

        $appointmentSecure.removeClass("hide");
        $(".appointment-page").addClass("hide");

        refreshSummary();
    }

    function scheduleAppointment(data, url) {
        if (data["selectedApptId"]) {
            prepareConfirmation();
        } else {
            var searchData = $("#headerNavLocationCTA").data();

            data["searchText"] = $("#start_location").val();
            data["longitude"] = searchData.longitude;
            data["latitude"] = searchData.latitude;

            $.ajax({
                type: "GET",
                url: url,
                data: data,
                success: function (response) {
                    $(".loader").fadeOut();
                    if (response && response.outOfStock) {
                        window.location = Urls.cartShow;
                    } else if (!$.isEmptyObject(response)) {
                        prepareConfirmation();
                    } else {
                        handleFailedScheduleAppt(data);
                    }
                },
                error: function () {
                    handleFailedScheduleAppt(data);
                }
            });
        }
    }

    /**
     * Shows error message pop up, stops loader and refreshes available slots if it is calendar page
     * @param {Object} data schedule appointment data
     */
    function handleFailedScheduleAppt(data) {
        // check if it is calendar page
        if ($(".appointment-calendar").length > 0) {
            addOpenSlotByDate(data.locationId, data.startDate, false);
        }
        $(".appointment-error-message").removeClass("hide");
        $(document).trigger("gtm_appointment_not_available", data.locationId);
        $(".loader").fadeOut();
        slotPopup.open();
    }

    $(document).on("click", ".restart-appointment", function () {
        returnToCalendar();
    });

    $(document).on("click", "#confirm-appointment", function () {
        var isServiceCheckout = $("#isServiceCheckout").val() == "true";
        var hasStoredTires    = $("#hasStoredTires").val() == "true";

        if (hasStoredTires && isServiceCheckout) {
            window.location = Urls.shippingStartRedFlag; // eslint-disable-line
        } else {
            window.location = $(this).data("url");
        }
    });

    $(document).on("click", ".appointment-date .appointments span", function (event) {
        event.preventDefault();
        var data = $(this).data(),
            $appointmentDate = $(".appointment-date"),
            $appointmentDateTime = $(".appointment-date-time"),
            $appointmentButtonsBox = $(".appointment-buttons-box");

        $appointmentDate.find(".appointments span").removeClass("appointment-active");
        $(this).addClass("appointment-active");
        $appointmentDateTime.find(".appointment-details").text($(".active-event").data("datatime"));
        $appointmentDateTime.find(".appointment-time").text($(this).text());
        $appointmentDateTime.show();
        $appointmentButtonsBox.addClass("with-appointment-date-time");
        $(".appointment-error-message").addClass("hide");

        if ($(".active-event span").length > 0) {
            $(".active-event span").remove();
        }

        $(".appointment-continue").data("request", {
            locationId : data["locationid"],
            startDate : data["date"],
            startTime : data["time"],
            employeeId : data["employeeid"],
            serviceId : service_id,
            creation_emp_id : iamota_store_locator.creationEmployeeID, // eslint-disable-line
            storeId : $(".map-infobox").data("id")
        });

        $(".appointment-continue").prop("disabled", false);
    });

    $(document).on("click", ".edit-appointment", function (event) {
        event.preventDefault();
        var $appointmentDateTime    = $(".appointment-date-time"),
            $appointmentButtonsBox  = $(".appointment-buttons-box");

        $(".appointment-continue").prop("disabled", true);
        $(".appointment-date .appointments").show();
        $appointmentDateTime.find(".appointment-details").text("");
        $appointmentDateTime.find(".appointment-time").text("");
        $(".active-event span").remove();
        $appointmentDateTime.hide();
        $appointmentButtonsBox.removeClass("with-appointment-date-time");
        $(".appointments span").removeClass("appointment-active");
        $(".appointment-error-message").addClass("hide");
        $(".appointment-date").show();
    });

    /**
     * "Book Now" (earliest available) or "Secure Appointment" (calendar page) button click handle
     * Retrieves appointment info from element and tries to schedule appointment
     */
    $(document).on("click", ".appointment-continue", function (event) {
        event.preventDefault();
        $(this).closest(".location-item").find(".loader").fadeIn();
        //if comes from a book-now situation, populate the store-details area for the rest of the calls
        if ($(event.target.parentElement).hasClass("book-now")) {
            var $locationItem = $(event.target).closest(".location-item");
            $("#store-details")[0].innerHTML = "";
            if (!$("#store-details").hasClass("hide")) {
                $("#store-details").addClass("hide");
            }
            $("#store-details").append($locationItem.clone()[0]);
            $(".appointment-details").text($locationItem.find(".slot-info").data("day"));
            $(".appointment-time").text($locationItem.find(".slot-info").data("time"));
            $(".appointment-date-time").show();
        }
        var data = $(this).data("request"),
            url = $(this).data("url") || Urls.scheduleAppointment,
            storedTires = $(this).data("storedtires");

        data.storedTires = storedTires;

        if (data) {
            if (data["selectedApptId"]) {
                window.location = Urls.shippingStart; // eslint-disable-line
            } else {
                scheduleAppointment(data, url);
            }
        }
    });

    $(document).on("click", ".skip-step-btn", function (event) {
        event.preventDefault();
        var url =  $(this).data("url");
        if ($(".map-infobox").length > 0) {
            url = url + "?storeId=" + $(".map-infobox").data("id");
        }
        window.location = url;
    });
};

