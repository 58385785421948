"use strict";

var compareWidget  = require("../compare-widget"),
    productTile    = require("../product-tile"),
    progress       = require("../progress"),
    util           = require("../util"),
    kaltire        = require("../kaltire"),
    visualizer     = require("./visualizer"),
    selector       = require("../productselector"),
    addToCart      = require("./product/addToCart"),
    initPlpReviews = require("../powerreviews-plp");

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $(".infinite-scroll-placeholder[data-loading-state=\"unloaded\"]");
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr("data-grid-url");
    if ($(".loader").length > 0)
        var $loader = $(".loader");

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        $loader.fadeIn(); //NOSONAR
        loadingPlaceHolder.attr("data-loading-state", "loading");
        loadingPlaceHolder.addClass("infinite-scroll-loading");


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass("infinite-scroll-loading");
            loadingPlaceHolder.attr("data-loading-state", "loaded");
            $("div.search-result-content").append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: "GET",
            dataType: "html",
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage["scroll-cache_" + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                $(".search-result-content").foundation();
                productTile.init();
                $loader.fadeOut();
            }
        });
    }
}

/**
 * Get all or minimized product info and replace it on the page
 *
 * @param {String} guidedPath the string to append to the AJAX URL
 * @param {Object} $productselinfo target object for html replacement
 * @param {Boolean} minimized flag for getting the product info minimized
 */
function getProductSelInfo(guidedPath, $productselinfo, minimized) {
    var ajaxUrl = "";
    if (minimized) {
        ajaxUrl = Urls.getproductselinfominimized; // eslint-disable-line
    } else {
        ajaxUrl = Urls.getproductselinfo; // eslint-disable-line
    }

    ajaxUrl = ajaxUrl + guidedPath;

    $.ajax({
        url: ajaxUrl,
        dataType: "html",
        type: "GET",
    }).done(function (data) {
        $productselinfo.html(data);

        // added product selector initialization for edit button
        selector.init();

        // reinit Foundation on needed elements
        $("#psmodal").foundation();
        $("#product-sel-info").foundation();

        if (!minimized) {
            // RideStyler Visualizer integration
            if ($("#v-visualizer-default-vehicle-small").length) {
                visualizer.getDefaultVehicleImageUrl();
            }
        }
    });
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($(".search-result-content"));
    $("#main").load(util.appendParamToURL(url, "format", "ajax"), function () {
        addToCart();
        compareWidget.init();
        productTile.init();
        progress.hide();
        history.pushState(undefined, "", url);
        $(".pt_product-search-result").foundation();
        // Google places search actions
        kaltire.locationFormActions();
        // RideStyler Visualizer integration
        visualizer.searchInit();
        $(".compare-check:checked").each(function () {
            $(this).parents(".product-tile").addClass("product-tile-active");
        });

        var $productselinfo = $("#product-sel-info");
        if ($productselinfo.length > 0) {
            var guidedPath = "";

            if (window.location.href.indexOf("guidedSellingPath") > 0) { //NOSONAR
                guidedPath = "?guidedSellingPath=" + window.location.href.split("guidedSellingPath=")[1];
            }

            if (window.location.href.indexOf("packages") > -1) {
                if (guidedPath == "") {
                    if (window.location.href.indexOf("tires/") > -1) {
                        guidedPath = "?packages=true&cgid=tires";
                    } else {
                        guidedPath = "?packages=true";
                    }
                } else {
                    if (window.location.href.indexOf("tires/") > -1) {
                        guidedPath += "&packages=true&cgid=tires";
                    } else {
                        guidedPath += "&packages=true";
                    }
                }
            }

            // check if minimized vehicle vizualizer must be present
            var minimizedVehicleVizualizer = $(".minimized-vehicle-vizualizer");

            if (minimizedVehicleVizualizer.length > 0) {
                getProductSelInfo(guidedPath, $productselinfo, true);
            } else {
                getProductSelInfo(guidedPath, $productselinfo, false);
            }
        }

        //hollow button toggle
        $(".close-hollow-btn").on("click", function () {
            $(this).toggleClass("is-active");
        });
        initPlpReviews();
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $("#main");

    $(".compare-check:checked").each(function () {
        $(this).parents(".product-tile").addClass("product-tile-active");
    });

    // handle view all
    $main.on("click", "#view-all-articles", function (e) {
        e.preventDefault();
        $(".folder-content-list li").removeClass("visually-hidden");
        $(this).addClass("visually-hidden");
    });

    // compare checked
    $main.on("click", "input[type=\"checkbox\"].compare-check", function () {
        var $checkBox = $(this);
        var $tile = $checkBox.closest(".product-tile");
        var $itemImg = $tile.find(".product-image a img").first();
        var itemTitle = $tile.find(".name-link:not(.brand-name)").text();

        $checkBox.parents(".product-tile").toggleClass("product-tile-active");
        $(".product-count").text($(".product-tile-active").length);

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        func({
            checkBox: $checkBox,
            img: $itemImg,
            itemid: $tile.data("itemid"),
            title: itemTitle,
            uuid: $tile[0].id
        });
    });

    // handle toggle refinement blocks
    $main.on("click", ".refinement h3", function () {
        $(this).toggleClass("expanded")
            .siblings("ul").toggle();
    });

    // handle events for updating grid
    $main.on("click", ".refinements-wrapper a:not(\".plusminus\"), .pagination a, .breadcrumb-refinement-value a", function (e) {
        var $target = $(e.target);
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents(".category-refinement").length > 0 || $(this).parents(".folder-refinement").length > 0 || $(this).parent().hasClass("unselectable")) {
            return;
        }
        e.preventDefault();
        updateProductListing(this.href);

        // scrolls to the top if the user selects a new page
        if ($target.is(".pagination a")) {
            $("html, body").animate({ scrollTop: 0 }, 1500);
        }
    });

    // handle events item click. append params.
    $main.on("click", ".product-tile a:not(\"#quickviewbutton\")", function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        //remove guidedSellingPath param so the section is not visible on PDP page
        if (qsParams.guidedSellingPath) {
            delete qsParams.guidedSellingPath;
        }

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest(".product-tile");
        var idx = tile.data("idx") ? + tile.data("idx") : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on("change", ".sort-by select", function (e) {
        e.preventDefault();
        updateProductListing($(this).find("option:selected").val());
    })
        .on("change", ".items-per-page select", function () {
            var refineUrl = $(this).find("option:selected").val();
            if (refineUrl === "INFINITE_SCROLL") {
                $("html").addClass("infinite-scroll").removeClass("disable-infinite-scroll");
            } else {
                $("html").addClass("disable-infinite-scroll").removeClass("infinite-scroll");
                updateProductListing(refineUrl);
            }
        });

    $main.on("click", ".start-new-search", function (event) {
        event.preventDefault();
        var $target = $(event.target);
        var $loader = $target.siblings(".loader");
        var url = $target.attr("href");
        var $productSelectorContent = $("#productSelectorContent");
        var $modalContainer = $("#newSearchModal");

        $loader.fadeIn();
        $.ajax({
            type: "GET",
            url: url,
            dataType: "html",
        }).done(function (response) {
            if ($productSelectorContent.length > 0) {
                $productSelectorContent.remove();
            }
            $modalContainer.html(response);
            var searchModal = new Foundation.Reveal($modalContainer); // eslint-disable-line
            selector.init();

            $modalContainer.find(".selector-tab").trigger("click");
            $modalContainer.find(".selector_close").remove();

            searchModal.open();
            $loader.fadeOut();
        });
    });

    // RideStyler Visualizer integration
    visualizer.searchInit();

    $main.on("click", ".selection-information #change-responses-cta", function (e) {
        e.preventDefault();
        window.location.href = $("#results-info-from-gsp").data("changeresponse");
    });
}

/**
 * Checks if the page is a page designer page and if the product tiles have a height greater than 200px.
 * If not, it will re-initialize the Foundation equalizer.
 */
function checkPageDesignerEqualizer() {
    var $productTile = $(".product-tile");

    if ($(".page-designer-kaltire").length && $productTile.length) {
        var interval = setInterval(function () {
            if ($productTile.last().height() > 200) {
                clearInterval(interval);
            } else {
                Foundation.reInit("equalizer");
            }
        }, 1000);
    }
}

exports.init = function () {
    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) { // eslint-disable-line
        $(window).on("scroll", infiniteScroll);
    }
    productTile.init();
    initializeEvents();
    addToCart();
    initPlpReviews();
    checkPageDesignerEqualizer();
};
