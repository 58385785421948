"use strict";

var util                  = require("./util.js");
var _                     = require("lodash");
var insideChat            = require("./insidechat");
var FormCtrl              = require("./FormCtrl");
var shipping              = require("./pages/checkout/shipping");
var currentQuestionNumber = 0;
var visualizer            = require("./pages/visualizer.js");
var myGarage              = require("./pages/mygarage.js");
var delay                 = 1000;

var guidedSellingPath = {
    init : function () {
        var $tiretypecontent          = $(".tire-type-content");
        var $selInfo                  = $(".sel-info-wrapper .selection-information");
        var $gsp                      = $("#guidedSellingPathWrapper");
        var $gspStartCTA              = $("#gspStartCTA");
        var $reinitGSP                = $("#GSPNoResults .reinit-gsp");
        var $GSPNoResults             = $("#GSPNoResults");
        var $tireCategoriesCTA        = $("#tireCategoriesCTA");
        var $tireCategoriesContent    = $("#tireCategoriesContent");
        var $arrow                    = $(".arrow");
        var $tiresGuidedPath          = $(".tires-guided-path");
        var $tireCategoriesGuidedPath = $tireCategoriesCTA.closest(".tires-guided-path");
        var isDesktop                 = $(window).width() > 1024;

        $tiresGuidedPath.on("mouseenter mouseleave", guidedSellingPath.initTiresGuidedPathHover);

        $reinitGSP.on("click", function (e) {
            e.preventDefault();
            $tiretypecontent.fadeIn();
            $selInfo.fadeIn();
            $GSPNoResults.fadeOut();
            guidedSellingPath.getQuestion("gspQ1");
            currentQuestionNumber = 0;
        });

        $gspStartCTA.on("click", function (e) {
            e.preventDefault();

            if ($(this).hasClass("disabled")) {
                return false;
            }

            $tiretypecontent.fadeOut("fast");
            $tireCategoriesContent.hide();
            $arrow.hide();
            $gsp.fadeIn();

            $("html, body").animate({
                scrollTop: $gsp.offset().top
            }, 400);
        });

        $tireCategoriesCTA.on("click", function (e) {
            e.preventDefault();
            $tireCategoriesContent.show();
            $arrow.show();

            if (isDesktop) {
                $tiresGuidedPath.removeClass("active");
                $tireCategoriesGuidedPath.addClass("selected").addClass("active");
            }

            $("html, body").animate({
                scrollTop: $(this).closest(".tires-guided-path").offset().top
            }, 400);
        });

        guidedSellingPath.getQuestion("gspQ1");

        $(window).on("scroll", function () {
            if (isDesktop) {
                return;
            }

            $("[data-is-active=\"true\"]").hide();
        });
    },

    initTiresGuidedPathHover: function (e) {
        var isMobile                  = $(window).width() < 1025;
        var $tireCategoriesCTA        = $("#tireCategoriesCTA");
        var $tireCategoriesGuidedPath = $tireCategoriesCTA.closest(".tires-guided-path");
        var $tiresGuidedPath          = $(".tires-guided-path");

        if (isMobile) {
            return;
        }

        if ($tireCategoriesGuidedPath.hasClass("selected")) {
            if ($(e.currentTarget) !== $tireCategoriesGuidedPath) {
                $(e.currentTarget).toggleClass("active");
                $tireCategoriesGuidedPath.toggleClass("active");
            }
        } else {
            if (e.type === "mouseenter") {
                $tiresGuidedPath.removeClass("active");
                $(e.currentTarget).addClass("active");
            } else {
                $tiresGuidedPath.addClass("active");
            }
        }
    },

    initActions : function () {
        var $answer        = $(".gspAnswer");
        var selectedAnswer = "";
        var $nextButton    = $("#gspNextAction");
        var $gspStartCTA   =  $("#gspStartCTA");
        var questiontype   = $(".gsp-question-wrapper").data("questiontype");
        var $closeButton   = $(".gsp-question-title .fa-times, #gspMobileClose");
        var $GSPNoResults  = $("#GSPNoResults");
        var $gsp           = $("#guidedSellingPathWrapper");

        if ($gspStartCTA.hasClass("disabled")) {
            $gspStartCTA.removeClass("disabled");

            if (util.getURLParameterByName("startgsp", window.location.href)) {
                $gspStartCTA.click();
            }
        }

        $answer.on("click", function () {
            if (questiontype === "radioselect") {
                $(".gspAnswer").removeClass("selected");
                $(this).addClass("selected");
            } else {
                $(this).toggleClass("selected");
            }

            selectedAnswer = [];

            $(".gspAnswer.selected").each(function () {
                selectedAnswer.push($(this).data("answer-id").toString());
            });

            if (selectedAnswer.length > 0) {
                $nextButton.removeClass("disabled");
            } else {
                $nextButton.addClass("disabled");
            }
        });

        //next button click for each question
        $nextButton.on("click", function (e) {
            e.preventDefault();

            //prevent clicking next if the button is disabled
            if ($(this).hasClass("disabled")) {
                return false;
            }

            var qid = $(".gsp-question-wrapper").data("questionid").toString();

            var answers = insideChat.getData("answers");
            if (answers) {
                answers += "|";
            }
            var temp = "";
            $(".gspAnswer.selected").each(function () {
                var questionAndAnswers = $(this).data("answer-id").toString(),
                    answer             = questionAndAnswers.substr(5),
                    question           = questionAndAnswers.substr(3, 2);

                if (!temp) {
                    temp = question + "=" + answer;
                } else {
                    temp += "," + answer;
                }
            });

            if (!answers) {
                answers = temp;
            } else {
                answers += temp;
            }

            insideChat.saveData("answers", answers);

            // get the current question answers logic as JSON in order to compute next question or redirect
            $.ajax({
                type: "GET",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.getgspquestionlogic + "?qid=" + qid // eslint-disable-line
            }).success(function (response) {
                var responseObj = guidedSellingPath.getNextQuestionID(selectedAnswer, response);
                var nextQID = responseObj.nextQ;
                var redirectPriorityQ = responseObj.redirectPriorityQ;
                var redirectSecondaryQ = responseObj.redirectSecondaryQ;

                if (redirectPriorityQ === redirectSecondaryQ) {
                    redirectSecondaryQ = null;
                }

                // If next question is of type redirect to category use redirectPriorityQ and or redirectSecondaryQ to set the refinements
                if (nextQID === "redirect") {
                    var priorityRef  = guidedSellingPath.getOptions(redirectPriorityQ);
                    var secondaryRef = guidedSellingPath.getOptions(redirectSecondaryQ);
                    var newUrl       = "";

                    // if both priorities refinements are the same type
                    if (priorityRef) {
                        if (priorityRef === "tire_categories") {
                            // If the choosen terrain is gravel with dry pavement and/or rain, also adds the '3-SEASON' category to the filter
                            var gravelSeason = (temp.indexOf("Q1=A1") >= 0 || temp.indexOf("Q1=A2") >= 0) ? "|3-SEASON" : "";

                            newUrl = changeRefinementURL($(".tire-types #productGridUrl").attr("href"), "tire_categories", redirectPriorityQ + gravelSeason);
                        } else {
                            $gsp.fadeOut();
                            $GSPNoResults.fadeIn();
                        }
                        window.location = newUrl  + "&guidedSellingPath=" + redirectPriorityQ;
                    } else if (!priorityRef && secondaryRef) {
                        // if we have only tire_category_2 pref
                        if (secondaryRef === "tire_categories") {
                            // If tire_category_2 is the 'PERFORMANCE' category, also adds the '3-SEASON' category to the filter
                            var performanceSeason = (redirectSecondaryQ === "PERFORMANCE") ? "|3-SEASON" : "";

                            newUrl = changeRefinementURL($(".tire-types #productGridUrl").attr("href"), "tire_categories", redirectSecondaryQ + performanceSeason);
                        } else {
                            $gsp.fadeOut();
                            $GSPNoResults.fadeIn();
                        }
                        window.location = newUrl  + "&guidedSellingPath=" + redirectSecondaryQ;
                    } else {
                        $gsp.fadeOut();
                        $GSPNoResults.fadeIn();
                    }
                } else {
                    guidedSellingPath.getQuestion(nextQID);
                    //animate selection so it's easier to navigate through options
                    $("html, body").animate({
                        scrollTop: $("#guidedSellingPathWrapper").offset().top
                    }, 200);
                }
            });
        });

        $closeButton.on("click", function (e) {
            e.preventDefault();

            var $gspWrapper             = $("#guidedSellingPathWrapper");
            var $tiretypecontent = $(".tire-type-content");
            var $selInfo         = $(".sel-info-wrapper .selection-information");
            var $tiresGuidedPath = $(".tires-guided-path");

            $gspWrapper.fadeOut("fast");
            $tiretypecontent.fadeIn();
            $selInfo.fadeIn();
            guidedSellingPath.getQuestion("gspQ1");
            currentQuestionNumber = 0;
            $tiresGuidedPath.addClass("active").removeClass("selected");
        });

    },

    // Get available categories
    getAvailableOptions : function (catid) {
        var $checkboxElement   = $(".tire-types .selector_checkbox .checkbox_element");
        var checkboxExists     = $checkboxElement.filter(function () {return this.value == catid;}).length;
        var checkboxRefinement = $checkboxElement.filter(function () {return this.value == catid;}).data("refinement");
        var tireCat            = null;

        if (checkboxExists) {
            tireCat = checkboxRefinement;
        }

        return tireCat;
    },

    /**
    * Retrieves the options for a given category ID.
    *
    * @param {string} catid - The category ID to get options for.
    * @returns {string|null} - The options for the given category ID, or null if none are found.
    */
    getOptions: function (catid) {
        return guidedSellingPath.getAvailableOptions(catid)
            || guidedSellingPath.getPerformanceOption(catid);
    },

    /**
    * Retrieves the performance option for a given category ID.
    *
    * @param {string} catid - The category ID to get the performance option for.
    * @returns {string|null} - The performance option for the given category ID, or null if none are found.
    */
    getPerformanceOption: function (catid) {
        // If the fitment does not contain any performance tire, we need to add it as querystring
        return catid === "PERFORMANCE" ? "tire_categories" : null;
    },

    getQuestion : function (qid) {
        var $gsp = $("#guidedSellingPathWrapper");

        $.ajax({
            type: "GET",
            dataType: "html",
            cache: false,
            contentType: "application/html",
            url: Urls.getgspquestion + "?qid=" + qid // eslint-disable-line
        }).success(function (response) {
            $gsp.html(response);
            $("#gspQuestionNumber").html(currentQuestionNumber += 1);
            guidedSellingPath.initActions();
        });
    },

    getNextQuestionID : function (answerResponse, rulesResponse) {
        // get current question rules via AJAX
        // var rules = rulesResponse;
        var nextQ              = "";
        var redirectPriorityQ  = "";
        var redirectSecondaryQ = "";

        function testArray(answer, rules) {
            var includes          = rules["includes"],
                andor             = rules["andor"],
                only              = rules["only"],
                except            = rules["except"],
                exceptandor       = rules["exceptandor"],
                next              = rules["next"],
                redirectPriority  = rules["redirectPriority"],
                redirectSecondary = rules["redirectSecondary"];

            //Case A
            if (includes !== undefined && andor === "false" && only === "false" && except === "false" && exceptandor === "false" && next !== undefined) {

                //verify if the answers contains at least ruleset answers
                if (_.intersection(answer, includes).length == includes.length) {
                    nextQ = next;
                    if (next === "redirect") {
                        redirectPriorityQ  = redirectPriority;
                        redirectSecondaryQ = redirectSecondary;
                    }
                }
            }

            //Case B
            if (includes !== undefined && andor !== undefined && only === "true" && except === "false" && exceptandor === "false" && next !== undefined) {

                // if match includes in answers
                if (_.intersection(answer, includes).length == includes.length) {
                    // if anwers is excat length of includes then go next question/redirect
                    if (answer.length == includes.length) {
                        nextQ = next;
                        if (next === "redirect") {
                            redirectPriorityQ  = redirectPriority;
                            redirectSecondaryQ = redirectSecondary;
                        }
                    }
                }

                // if match andor in answers
                if (_.intersection(answer, andor).length == andor.length) {
                // if anwers is excat length of andor then go next question/redirect
                    if (answer.length == andor.length) {
                        nextQ = next;

                        if (next === "redirect") {
                            redirectPriorityQ  = redirectPriority;
                            redirectSecondaryQ = redirectSecondary;
                        }
                    }
                }

                //if match includes and andor
                if (_.intersection(answer, includes).length + _.intersection(answer, andor).length == answer.length) {
                    // if anwers is exacat length of andor then go next question/redirect
                    nextQ = next;
                    if (next === "redirect") {
                        redirectPriorityQ  = redirectPriority;
                        redirectSecondaryQ = redirectSecondary;
                    }
                }
            }

            //Case C
            if (includes !== undefined && andor ==="false" && only === "false" && except !== "false" && exceptandor !== "false" && next !== undefined) {

                // if match except or exceptandor or except and exceptandor in answers
                if (_.intersection(answer, except).length > 0 ||
                    _.intersection(answer, exceptandor).length > 0 ||
                    _.intersection(answer, except).length + _.intersection(answer, exceptandor).length  > 0) {
                    return;
                }
                else {
                    if (_.intersection(answer, includes).length == includes.length) {
                        nextQ = next;
                        if (next === "redirect") {
                            redirectPriorityQ  = redirectPriority;
                            redirectSecondaryQ = redirectSecondary;
                        }
                    }
                }
            }

            //Case D
            if (includes !== undefined && andor ==="false" && only === "false" && except !== "false" && exceptandor === "false" && next !== undefined) {

                // if match except or exceptandor or except and exceptandor in answers
                if (_.intersection(answer, except).length == except.length) {
                    return;
                }
                else {
                    if (_.intersection(answer, includes).length == includes.length) {
                        nextQ = next;
                        if (next === "redirect") {
                            redirectPriorityQ  = redirectPriority;
                            redirectSecondaryQ = redirectSecondary;
                        }
                    }
                }
            }

        }

        _.each(rulesResponse, function (rule) {
            testArray(answerResponse, rule);
        });

        return {
            "nextQ" : nextQ,
            "redirectPriorityQ" : redirectPriorityQ,
            "redirectSecondaryQ" : redirectSecondaryQ
        };
    }
};

/**
 * Reset selected values in vehicle / products selector
 * @param {Object} $selectorStep
 * @param {Object} $selectorSteps
 * @param {Object} $selectorOptions
 * @param {Object} $productSelector
 * @param {Object} $frontRear
 */
function resetSelector($selectorStep, $selectorSteps, $selectorOptions, $productSelector, $frontRear) {
    $selectorStep.removeClass("current").removeClass("chosen");
    $(".selector_steps").find(".step:first").addClass("current");

    $selectorStep.each(function () {
        let $this = $(this);
        $this.children("a").text($this.data("label"));
    });

    $selectorOptions.each(function () {
        let $this = $(this);
        $this.find(".selector_options").not(":eq(0)").empty().hide();
        $this.find(".selector_options").first().show();
    });

    $selectorSteps.each(function () {
        let $this = $(this);
        $this.find(".selector_options_mobile").not(":eq(0)").empty().hide();
        $this.find("step").first().find(".selector_options_mobile").show();
    });

    $productSelector.parents(".vertical.menu > li").show().removeClass("insideSelector");

    // reset front rear
    $frontRear.hide();
    $(".front-rear").removeClass("staggered-active");
    $(".productSelector[data-frontrear=\"rear\"]").removeClass("staggered-active");
    $(".productSelector[data-frontrear=\"front\"]").removeClass("staggered-active");

    $frontRear.each(function () {
        $(this).children("div").removeClass("active").eq(0).addClass("active");
    });

    $(".add-rear-size a").each(function () {
        var $this = $(this);
        $this.text($this.data("label"));
        $this.data("selected", false);
    });
}

/**
 * Searches if there is a match between the fitment and the selected master product.
 * @param {String} fitmentID
 * @param {String} refUrl
 * @param {String} httpParams
 */
function getProductFitmentMatch(fitmentID, refUrl, httpParams) {
    $.ajax({
        type        : "POST",
        dataType    : "json",
        cache       : false,
        contentType : "application/json",
        url         : Urls.getProductFitmentMatch + httpParams, // eslint-disable-line
        data        : {}
    }).done(function (response) {
        if (response.idProductMatch) {
            refUrl = util.appendParamToURL(Urls.getProductUrl, "pid", response.idProductMatch); // eslint-disable-line
            window.location = refUrl;
        } else {
            refUrl = util.appendParamToURL(refUrl, "fitmentIDs", fitmentID);

            // Displays the modal window with the no match message.
            var $modal = $("#fitmentModal");
            $modal.foundation("destroy");

            var psmod  = new Foundation.Reveal($modal); // eslint-disable-line
            psmod.open();

            // Sets the on click method to the continue button from the modal window.
            $modal.on("click", ".continueButton", function (e) {
                e.preventDefault();
                window.location = refUrl;
            });
        }
    });
}

/**
 * Mobile vs Tablet toggle functionality
 */
function syncTabletToMobile() {
    var currentTabID = $(".main-selector-tab.active").data("selectorid"),
        $activeTab   = $(".menu.rootlevelps[data-selectorid=\""+ currentTabID +"\"]"),
        $psMobile    = $("#psMobile");

    $("#psMobile > li ul.menu").each(function () {
        $psMobile.foundation("up", $(this));
    });

    if ($psMobile.length > 0 && $activeTab.length > 0) {
        $psMobile.foundation("down", $activeTab);
    }
}

/**
 * Save vehicle into cookie / session
 *
 * @param {String} vehicleId
 * @param {String} serviceUrl
 */
function saveVehicleIntoCookieSession(vehicleId, serviceUrl) {
    var dataObject = {
        "vehicleId": vehicleId
    };

    $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: Urls.setVehicleInCookies, // eslint-disable-line
        data: JSON.stringify(dataObject)
    }).done(function (response) {
        if (response.success) {
            // redirect to the service URL
            window.location = serviceUrl;
        }
    });
}

/**
 * Handle Post Data Context for tire, trailer, wheel, package, service, add_edit and ymmo vehicle check
 *
 * @param {Object} response
 * @param {String} stringToAppend
 * @param {Object} dataObject
 */
function handlePostDataContext(response, stringToAppend, dataObject) {
    var postData          = dataObject.postData,
        serviceUrl        = postData.serviceUrl,
        vehicleId         = postData.vehicleId;

    if (postData.context.indexOf("tire") > -1 && postData.context.indexOf("trailer") === -1) {
        var tireTypeUrl   = Urls.showtiretypespage, // eslint-disable-line
            isMatchSearch = false;

        if (response.FitmentMPMatch) {
            // Removes pmid parameter from url.
            stringToAppend = "";

            isMatchSearch  = true;

            // Searches if there is a match between the fitment and the selected master product.
            getProductFitmentMatch(response.FitmentID, tireTypeUrl, response.FitmentMPMatch.httpParams);
        } else if (response.FitmentID) {
            tireTypeUrl = util.appendParamToURL(tireTypeUrl, "fitmentIDs", response.FitmentID);
        } else {
            for (var ref in response.CurrentRef) {
                tireTypeUrl = util.appendParamToURL(tireTypeUrl, response.CurrentRef[ref].refName, response.CurrentRef[ref].refValue);
            }
        }

        tireTypeUrl += stringToAppend.replace("?", "&");

        // set tire url cookie
        var d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = "selResponseUrl=" + tireTypeUrl + ";" + expires + ";path=/; SameSite=Lax;";

        if (serviceUrl && serviceUrl !== "undefined") {
            // save vehicle into cookie / session and redirect to service page
            saveVehicleIntoCookieSession(vehicleId, serviceUrl);
        } else if (!isMatchSearch) {
            window.location = tireTypeUrl;
        }
    } else if (postData.context.indexOf("tire") > -1 && postData.context.indexOf("trailer") > -1) {
        var tireUrl = response.RefUrl;
        if (response.FitmentMPMatch) { // master product match
            // Searches if there is a match between the fitment and the selected master product.
            getProductFitmentMatch(response.FitmentID, tireUrl, response.FitmentMPMatch.httpParams);
        } else {
            window.location = tireUrl;
        }
    } else if (postData.context.indexOf("wheel") > -1) {
        var wheelUrl = response.RefUrl;
        if (!response.performSearch) {
            window.location = wheelUrl + "&noHits=0&context=wheels";
        } else if (response.FitmentMPMatch) {
            // Searches if there is a match between the fitment and the selected master product.
            getProductFitmentMatch(response.FitmentID, wheelUrl, response.FitmentMPMatch.httpParams);
        } else {
            window.location = wheelUrl;
        }
    } else if (postData.context.indexOf("package") > -1) {
        var packagesUrl = Urls.showpackagespage; // eslint-disable-line
        if (response.FitmentID) {
            packagesUrl = util.appendParamToURL(packagesUrl, "fitmentIDs", response.FitmentID);
        } else {
            for (var refInCurrentRef in response.CurrentRef) { // eslint-disable-line
                packagesUrl = util.appendParamToURL(packagesUrl, response.CurrentRef[refInCurrentRef].refName, response.CurrentRef[refInCurrentRef].refValue);
            }
        }

        window.location = packagesUrl;
    } else if (postData.context.indexOf("brakes") > -1) {
        var brakesUrl = serviceUrl;

        if (!postData.isActionsModal) {
            brakesUrl = Urls.showbrakespage;
            for (var refBrakes in response.CurrentRef) {
                brakesUrl = util.appendParamToURL(brakesUrl, response.CurrentRef[refBrakes].refName, response.CurrentRef[refBrakes].refValue);
            }
        }
        window.location = brakesUrl;
    } else if (postData.context.indexOf("service") > -1) {
        var servicesUrl = Urls.showservicespage; // eslint-disable-line
        if (response.FitmentID) {
            servicesUrl = util.appendParamToURL(servicesUrl, "fitmentID", response.FitmentID);
        }
        for (var refInCUrrentRef in response.CurrentRef) { // eslint-disable-line
            servicesUrl = util.appendParamToURL(servicesUrl, response.CurrentRef[refInCUrrentRef].refName, response.CurrentRef[refInCUrrentRef].refValue);
        }
        window.location = servicesUrl;
    } else if (postData.context.indexOf("add_edit_vehicle") > -1) {
        var selymmo = response.selYmmo;
        var $modal = $("#psmodal");
        $modal.foundation("close");

        if (dataObject.$isMyGarage && dataObject.$isMyGarage !== "undefined") {
            // add my garage vehicle
            addMyGarageNewVehicle(selymmo);
        } else {
            var $vehicleContainer = $(".vehicle");
            var formattedYmmo = selymmo.year + " " + selymmo.make + " " + selymmo.model;
            const addNewVehicleForm = FormCtrl.search("addNewVehicle");
            var newVehicleData = addNewVehicleForm.getValue("newVehicle");
            $vehicleContainer.find(".vehicle-info").html(formattedYmmo);
            $vehicleContainer.removeClass("hide");

            // hide the informations and select vehicle texts
            $(".informations").addClass("hide");
            $(".select-vehicle").addClass("hide");
            $(".add-vehicle").hide();

            $("form[id$=\"shippingAddress\"]").trigger("vehiclechanged");

            if (!newVehicleData) {
                return;
            }

            $.ajax({
                type: "POST",
                contentType: "application/json",
                url: Urls.checkForMatchingVehicles,
                data: JSON.stringify(postData)
            }).then(response => { //NOSONAR
                const matchingVehicles = response && response.customerMatchingVehicles;
                const verifyVehicleForm = FormCtrl.search("verifyVehicle");
                const resetSelectedVehicle = verifyVehicleForm.getValue("resetSelectedVehicle");
                
                resetSelectedVehicle();
                if (Array.isArray(matchingVehicles) && matchingVehicles.length) {
                    if (matchingVehicles.length === 1 && !response.isOnlyArchivedVehicles) {
                        verifyVehicleForm.setField("selectedVehicleId", matchingVehicles[0].id);
                        verifyVehicleForm.setValue("matchOne", true);
                    } else if (response.isOnlyArchivedVehicles) {
                        verifyVehicleForm.setValue("archivedMatch", true);
                    } else {
                        verifyVehicleForm.setValue("matchOne", false);
                        verifyVehicleForm.setValue("isNewVehicle", (!matchingVehicles.length));
                        verifyVehicleForm.setValue("multipleMatch", (matchingVehicles.length > 1));
                    }

                    return $.ajax({
                        type: "POST",
                        contentType: "application/json",
                        url: Urls.matchingVehiclesHtmlModal,
                        data: JSON.stringify({matchingVehicles})
                    });
                } else {
                    verifyVehicleForm.setValue("isNewVehicle", true);
                }
            }).then(htmlString => {
                const $modalContent = $(htmlString).find(".modal-content");

                $("#verify-vehicle-modal").empty().html($modalContent);

                shipping.initVerifyVehicleForm();
            });
        }
    } else if (postData.context.indexOf("ymmo_vehicle_check") > -1) {
        postData.performSearch = response.performSearch;
        postData.isVerifyVehicleModal = true;

        $.ajax({
            type: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json",
            url: Urls.checkYmmo, // eslint-disable-line
            data: JSON.stringify(postData)
        }).done(function (responseCheckYmmo) {
            if (responseCheckYmmo.success == true) {
                window.location = Urls.cartShow; // eslint-disable-line
            } else {
                $.ajax({
                    type: "POST",
                    cache: false,
                    contentType: "application/json",
                    url: Urls.errorCheckYmmo, // eslint-disable-line
                    data: JSON.stringify(responseCheckYmmo)
                }).done(function (resp) {
                    var $modalPS = $("#psmodal");
                    $modalPS.find(".modal-content").html(resp);
                    $modalPS.find(".cart-psmodal-title").hide();
                    $modalPS.foundation("destroy");
                    var psmod = new Foundation.Reveal($modalPS); // eslint-disable-line
                    psmod.open();

                    $("#psmodal").off("click").on("click", ".checkout-with-error", function (e) {
                        e.preventDefault();
                        var currentRefData = JSON.stringify($(this).data("currentref"));
                        $.ajax({
                            type: "POST",
                            dataType: "json",
                            cache: false,
                            contentType: "application/json",
                            url: Urls.setContinueWithError + "?fitID=" + $(this).data("fitid"), // eslint-disable-line
                            data: currentRefData
                        }).done(function () {
                            window.location = Urls.cartShow; // eslint-disable-line
                        });
                    });
                });
            }
        });
    } else {
        window.location = response.RefUrl;
    }
}

/**
 * Handle selector value selection
 *
 * @param {String} stringToAppend String data to append to the URL
 * @param {Object} dataObject Data object with vehicle information
 */
function handleVehicleActions(stringToAppend, dataObject) {
    $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: Urls.gettireswheelsurl + (stringToAppend || ""), // eslint-disable-line
        data: JSON.stringify(dataObject.postData)
    }).done(function (response) {
        if (response.success) {
            if (dataObject.$initialVehicleData && dataObject.$initialVehicleData !== "undefined") {
                updateMyGarageVehicle(response, stringToAppend, dataObject);
            } else {
                handlePostDataContext(response, stringToAppend, dataObject);
            }
        }
    });
}

/**
 * Handle selector value selection
 *
 * @param {Object} $this element where all data is located
 */
function handleSelectorValueSelection($this) {
    var $selectorOption         = $this,
        $selector               = $(".selector"),
        $loader                 = $selector.find(".loader"),
        $currentOptions         = $selectorOption.parents(".selector_options"),
        currentKey              = $currentOptions.data("current-key"),
        $currentStep            = $selectorOption.parents(".productSelector").find(".selector_steps .step[data-current-key=\"" + currentKey + "\"]"),
        $currentStepChildren    = $currentStep.children("a"),
        nextKey                 = $currentStep.next().data("current-key"),
        $currentProdSel         = $selectorOption.parents(".productSelector"),
        $tireSizeTooltip        = $(".vehicleSelectorFindTire"),
        $loadRangeTrigger       = $tireSizeTooltip.find(".loadRangeTrigger"),
        $tireSizeTrigger        = $tireSizeTooltip.find(".tireSizeTrigger"),
        $productSelectorType    = $selectorOption.closest(".productSelector").data("selectortype"),
        $selectorSteps          = $(".selector_steps"),
        $PSSlotWrap             = $("#productSelectorSlotWrapper"),
        $serviceSelectorContent = $PSSlotWrap.find(".service-selector-content"),
        $isMyGarage             = $selector.data("ismygarage"),
        $initialVehicleData     = $selector.data("initialvehicledata"),
        serviceUrl              = $selector.data("service-url"),
        vehicleId               = $selector.data("vehicleid");

    $currentProdSel.find(".selector_steps .step").removeClass("current");

    if ($selectorOption.data("value") === "-") {
        $currentStepChildren.text("-");
    } else {
        if (currentKey !== "WHEEL_CATEGORY") {
            $currentStepChildren.text($selectorOption.text());
        } else {
            $currentStepChildren.text($selectorOption.find("h3").text());
        }

        $currentStepChildren.removeData("value").attr("data-value", $selectorOption.data("value"));
    }

    var postData = {};
    postData.nextRef = nextKey;
    postData.action = "refineBy";
    postData.categid = $currentProdSel.data("categoryid");
    postData.currentRef = [];
    postData.prevRef = [];
    postData.selectorType = $currentProdSel.data("selectortype");
    postData.serviceUrl = serviceUrl;
    postData.vehicleId = vehicleId || FormCtrl.selectedVehicleToAction;

    // add previous selections
    $currentProdSel.find(".selector_steps .step.chosen").each(function () {
        let $this = $(this); //NOSONAR
        let $previousStep = $this.children("a");
        let previousValue = "";

        if ($previousStep.length && $previousStep.data("value")) {
            previousValue = $previousStep.data("value");
        } else if ($previousStep.length && $previousStep[0].innerText !== undefined) {
            previousValue = $previousStep[0].innerText;
        }

        if ($previousStep.text().trim() !== "-") {
            postData.currentRef.push({
                "refName": $this.data("current-key"),
                "refValue": previousValue
            });
        }
    });

    // add current selection
    if ($selectorOption.data("value") !== "-") {
        postData.currentRef.push({
            "refName": currentKey,
            "refValue": $selectorOption.data("value")
        });
    }

    $loader.fadeIn();
    $selectorSteps.addClass("disabled");
    $currentStep.addClass("chosen").next().addClass("current");

    var $tireVehicleSizeStep = $(".productSelector[data-selectortype='tire_vehicle'] .step.current[data-label='Size']"),
        $wheelVehicleSizeStep = $(".productSelector[data-selectortype='wheel_vehicle'] .step.current[data-label='Size']");
    if ($tireVehicleSizeStep.length > 0 || $wheelVehicleSizeStep.length > 0) {
        $tireSizeTooltip.show();
    } else if (!$tireSizeTooltip.hasClass("trailer-tire-hint")) {
        $tireSizeTooltip.hide();
    }

    if ($tireSizeTooltip.hasClass("trailer-tire-hint")) {
        if ($currentStep.next().data("label") === "Load Range") {
            $loadRangeTrigger.removeClass("hide");
            $tireSizeTrigger.addClass("hide");
        } else {
            $loadRangeTrigger.addClass("hide");
            $tireSizeTrigger.removeClass("hide");
        }
    }

    if ($serviceSelectorContent.length) {
        _.debounce(function () {
            $currentOptions.hide();
        }, delay)();
    } else {
        $currentOptions.hide();
    }

    var dataObject = {
        $loader,
        nextKey,
        postData,
        $serviceSelectorContent,
        $selectorOption,
        $currentOptions,
        $currentProdSel,
        $productSelectorType,
        $currentStep,
        $selectorSteps,
        $isMyGarage,
        $initialVehicleData
    };

    const addNewVehicle = FormCtrl.search("addNewVehicle");
    if (addNewVehicle instanceof FormCtrl) {
        addNewVehicle.setValue("dataObjectSelector", dataObject);
    }

    handleGetSelectorValues(dataObject);
}

/**
 * Add new vehicle container
 *
 * @param {Object} params
 */
function addNewVehicleContainer(params) {
    $.ajax({
        type: "POST",
        dataType: "html",
        cache: false,
        contentType: "application/json",
        url: Urls.getNewCarContainer, // eslint-disable-line
        data: JSON.stringify(params)
    }).done(function (response) {
        // add the new car container
        $(".cars-container").prepend(response);
        const addNewVehicleForm = FormCtrl.search("addNewVehicle");
        const plateNo = addNewVehicleForm.getValue("plateNo");
        const vin = addNewVehicleForm.getValue("vin");
        const name = addNewVehicleForm.getValue("name");
        const $newCarAdded = $(".new-car-added");

        // RideStyler Visualizer integration
        visualizer.initMygarageVehicleImageUrl(".new-car-added");

        if (plateNo) {
            $newCarAdded.find(".car-plate-edit").show().removeClass("hide").find("span").text(plateNo);
            $newCarAdded.find(".car-plate-add").addClass("hide");
        } else {
            $newCarAdded.find(".car-plate-add").show().removeClass("hide");
            $newCarAdded.find(".car-plate-edit").hide();
        }

        if (vin) {
            $newCarAdded.find(".car-vin").removeClass("hide").find("span").text(vin);
        }

        if (name) {
            $newCarAdded.find(".car-name").removeClass("hide").find("span").text(name);
        }

        // remove the new car added class after the visualizer init
        const carfaxForm = FormCtrl.search("carfax");
        const editVehicleForm = FormCtrl.search("editVehicleData");

        // Updating new vehicle on the forms data
        carfaxForm.initTools(".new-car-added");
        editVehicleForm.initTools(".new-car-added");
        $newCarAdded.removeClass("new-car-added");

        // initialize service/product modal CTA buttons
        myGarage.init();
    });
}

/**
 * Scrolls the page to the specified existing vehicle element, highlights it,
 * and removes the highlight after a short delay.
 *
 * @param {jQuery} $existingVehicle - The jQuery object representing the existing vehicle element to navigate to.
 * @return {void}
 */
function takeMeToTheVehicleAction($existingVehicle) {
    if ($existingVehicle.length) {
        // check if vehicle is archived
        if ($existingVehicle.parents(".archived-cars").length > 0) {
            // open the Archived section if it's closed
            if ($(".archived-cars").hasClass("hide")) {
                $(".toggle-archived-cars").trigger("click");
            }
        }
    
        var $existingVehicleFirstElement = $($existingVehicle[0]),
            $headerMainNavHeight         = $(".header-main-nav").outerHeight();
    
        // get car container offset to the top
        var $carContainerTopOffset = $existingVehicleFirstElement.offset().top.toFixed() - $headerMainNavHeight;
    
        // scroll into view
        $("html, body").animate({
            scrollTop: $carContainerTopOffset
        }, 1000);
    
        // highlight the vehicle
        $existingVehicleFirstElement.addClass("highlight-existing-car");
    
        setTimeout(function () {
            // after 2 seconds transition the border to 0
            $existingVehicleFirstElement.addClass("transition-to-end");
    
            setTimeout(function () {
                // after another half of second reset the highlight
                $existingVehicleFirstElement.removeClass("highlight-existing-car").removeClass("transition-to-end");
            }, 500);
        }, 2000);
    } 
}

/**
 * Add new vehicle into My Garage
 *
 * @param {Object} selymmo
 */
function addMyGarageNewVehicle(selymmo) {
    $.ajax({
        type: "POST",
        dataType: "html",
        cache: false,
        contentType: "application/json",
        url: Urls.addVehicleAfterExistanceCheck, // eslint-disable-line
        data: JSON.stringify(selymmo)
    }).done(function (response) {
        // parse the response and search for vehicleExists
        var responseJson = response ? JSON.parse(response) : "";

        if (!responseJson || !responseJson.vehicleExists) {
            addNewVehicleContainer(responseJson.params);
        }
    });
}

/**
 * Update My Garage Vehicle that has missing data
 *
 * @param {Object} externalResponse
 * @param {String} stringToAppend
 * @param {Object} externalDataObject
 */
function updateMyGarageVehicle(externalResponse, stringToAppend, externalDataObject) {
    var currentVehicle = {},
        currentRef     = externalDataObject.postData.currentRef;

    // add data to current vehicle oject
    for (var i = 0; i < currentRef.length; i++) {
        if (currentRef[i].refName == "ft_CYEAR") {
            currentVehicle.year = currentRef[i].refValue;
        }

        if (currentRef[i].refName == "ft_MAKE2") {
            currentVehicle.make = currentRef[i].refValue;
        }

        if (currentRef[i].refName == "ft_MODEL") {
            currentVehicle.model = currentRef[i].refValue;
        }

        if (currentRef[i].refName == "ft_OPTION2") {
            currentVehicle.fitment = currentRef[i].refValue;
        }
    }

    var dataObject = {
        vehicleID: externalDataObject.postData.vehicleId,
        vehicleData: externalDataObject.$initialVehicleData,
        updatedVehicleData: currentRef
    };

    // Sent the vehicleID to compare and find the vehicle to update on the BE
    // update my garage vehicle
    $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: Urls.updateVehicleData,
        data: JSON.stringify(dataObject)
    }).done(function (response) {
        if (response.success) {
            handlePostDataContext(externalResponse, stringToAppend, externalDataObject);
        }
    });
}

/**
* Handles duplicated size options by updating their text descriptions to include additional details.
* This function checks for duplicate size options in both desktop and mobile views, and modifies their text
* to include speed ratings and load indexes to differentiate them.
*
* @returns {void}
*/
function handleDuplicatedSizes() {
    var $desktopSizesColumn = $(".selector_options.Size .resultsCol");
    var $mobileSizesColumns = $(".selector_options_mobile.Size .resultsCol");

    if ($desktopSizesColumn.is(":visible") || $mobileSizesColumns.is(":visible")) {
        var $sizeOptions    = $desktopSizesColumn.is(":visible") ? $desktopSizesColumn.find("li") : $mobileSizesColumns.find("li");
        var originalOptions = $sizeOptions.clone();

        $sizeOptions.each(function () {
            var $currentElement = $(this);
            var numberOfOcurrences = originalOptions.filter(function (i, element) {
                return element.dataset.originalText === $currentElement[0].dataset.originalText;
            });

            // escape on non duplicated options
            if (numberOfOcurrences.length < 2) {
                return true;
            }

            var frontSpeedRating = $currentElement.attr("data-front-speed-rating");
            var rearSpeedRating  = $currentElement.attr("data-rear-speed-rating");
            var frontLoadIndex   = $currentElement.attr("data-front-load-index");
            var rearLoadIndex    = $currentElement.attr("data-rear-load-index");
            var $elementAnchor   = $currentElement.find("a");
            var originalText     = $currentElement.attr("data-original-text");
            var speedRating      = $currentElement.attr("data-speed-rating");
            var loadIndex        = $currentElement.attr("data-load-index");

            if (speedRating !== undefined && loadIndex !== undefined) {
                if ($currentElement.length) {
                    $elementAnchor.text(originalText + frontLoadIndex + frontSpeedRating);
                }
            } else if (frontSpeedRating !== undefined && rearSpeedRating !== undefined && frontLoadIndex !== undefined && rearLoadIndex !== undefined) {
                if ($currentElement.length) {
                    var semicolonIndex = originalText.indexOf(";");
                    var rearTireEndIndex = originalText.lastIndexOf(" ");
                    var updatedText = originalText.substring(0, semicolonIndex) + 
                        " " + frontLoadIndex + frontSpeedRating + " ;" + 
                        originalText.substring(semicolonIndex + 1, rearTireEndIndex) + 
                        " " + rearLoadIndex + rearSpeedRating + 
                        originalText.substring(rearTireEndIndex);

                    $elementAnchor.text(updatedText);
                }
            }
        });
    }
}

/**
 * Handles the ajax calls that get the selector values
 *
 * @param {Object} dataObject
 */
function handleGetSelectorValues(dataObject) {
    $.ajax({
        type: "POST",
        dataType: "html",
        cache: false,
        contentType: "application/json",
        url: Urls.getselectorvalues, // eslint-disable-line
        data: JSON.stringify(dataObject.postData)
    }).done(function (response) {
        var $nextCurrentOptions = dataObject.$currentOptions.next();

        // treat case in which reply is empty
        if (dataObject.$serviceSelectorContent.length) {
            $nextCurrentOptions.html(response).delay(delay).fadeIn();
        } else {
            $nextCurrentOptions.html(response).fadeIn();
        }

        dataObject.$currentProdSel.find(".selector_options_mobile." + dataObject.nextKey).show().html(response);
        $(".selector_options_mobile .orbit").attr("id", "");

        // init slider
        new Foundation.Orbit($("#" + $nextCurrentOptions.find(".orbit").attr("id"))); // eslint-disable-line

        handleDuplicatedSizes();

        // init modal
        $("#findTireSizeModalContent").foundation();

        // in case of one option select it automatically
        var $selectorOption = $nextCurrentOptions.find("a.selectoroption");
        if ($selectorOption.length == 1) {
            // TODO: refactor this in the future and trigger the click after the Ajax call is done
            setTimeout(function () {
                $selectorOption.trigger("click");
            }, 500);

            // stop next steps because the click event fires the next Ajax call that's needed
            return;
        }

        var selectorOptionsMobileNextKey = dataObject.$currentOptions.parents(".selector_steps").find(".selector_options_mobile"+ dataObject.nextKey + " a");
        if (selectorOptionsMobileNextKey.length == 1) {
            // TODO: refactor this in the future and trigger the click after the Ajax call is done
            setTimeout(function () {
                selectorOptionsMobileNextKey.trigger("click");
            }, 500);

            // stop next steps because the click event fires the next Ajax call that's needed
            return;
        }

        dataObject.postData.context = dataObject.$currentProdSel.data("selectortype");

        if (dataObject.$selectorOption.hasClass("wheeltype-option") && dataObject.$productSelectorType.indexOf("package") < 0) {
            var assetid = dataObject.$selectorOption.data("assetid");

            $.ajax({
                type: "POST",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.gettireswheelsurl, // eslint-disable-line
                data: JSON.stringify(dataObject.postData)
            }).done(function (responseGetTireWheelssUrl) {
                var URLObject = new URL(responseGetTireWheelssUrl.RefUrl);

                for (var key of URLObject.searchParams.keys()) {
                    var currentValue = URLObject.searchParams.get(key);

                    if (currentValue === "WHEEL_CATEGORY") {
                        var relatedKey = "prefv" + key.charAt(key.length-1);
                        var catValue = URLObject.searchParams.get(relatedKey);

                        if (catValue === "Aluminium" && assetid === "wheeltype-steel-modal") {
                            URLObject.searchParams.set(relatedKey, "Steel");
                        }
                    }
                }

                if (!responseGetTireWheelssUrl.performSearch) {
                    window.location.href = URLObject.href + "&noHits=0&context=wheels";
                } else {
                    window.location.href = URLObject.href;
                }

                dataObject.$loader.fadeOut();
                dataObject.$selectorSteps.removeClass("disabled");
            });
        } else {
            dataObject.$loader.fadeOut();
            dataObject.$selectorSteps.removeClass("disabled");

            if (dataObject.$currentStep.next().length == 0) {
                if ($(".front-rear").hasClass("staggered-active")) {
                    if (dataObject.$currentProdSel.data("frontrear") === "rear") {
                        dataObject.$currentProdSel.prev().find(".selector_steps .step.chosen").each(function () {
                            if ($(this).children("a").text().trim() !== "-") {
                                dataObject.postData.prevRef.push({"refName": $(this).data("current-key"), "refValue": $(this).children("a").text().trim()});
                            }
                        });
                    } else {
                        $(".front-rear[data-categoryid=\""+ dataObject.$currentProdSel.data("categoryid") +"\"] .rear").click();
                        return;
                    }
                }

                var currentUrl        = window.location.href,
                    stringToAppend    = "",
                    queryStringParams = util.getQueryStringParams(currentUrl);

                if (queryStringParams && queryStringParams.hasOwnProperty("addrefvalue") && queryStringParams.hasOwnProperty("addrefname")) { // eslint-disable-line
                    stringToAppend = "?addrefname=" + queryStringParams.addrefname + "&addrefvalue=" +  queryStringParams.addrefvalue + "&ismygarage=" + dataObject.$isMyGarage;
                } else if (queryStringParams && queryStringParams.hasOwnProperty("pmid")) { // eslint-disable-line
                    stringToAppend = "?pmid=" + queryStringParams.pmid + "&ismygarage=" + dataObject.$isMyGarage;
                } else {
                    if (dataObject.$isMyGarage) {
                        stringToAppend = "?ismygarage=" + dataObject.$isMyGarage;
                    }
                }

                handleVehicleActions(stringToAppend, dataObject);
            }
        }
    });
}

/**
 * Get missing data popup for it's selection
 *
 * @param {String} vehicleYear
 * @param {String} vehicleMake
 * @param {String} vehicleModel
 * @param {String} vehicleSubModel
 * @param {String} vehicleOption
 * @param {String} vehicleAction
 * @param {String} dataFolder
 * @param {String} serviceUrl
 * @param {String} vehicleId
 */
function getMissingDataPopup(vehicleYear, vehicleMake, vehicleModel, vehicleSubModel, vehicleOption, vehicleAction, dataFolder, serviceUrl, vehicleId) {
    var currentRef = [],
        nextRef    = "";

    if (vehicleYear) {
        currentRef.push({"refName": "ft_CYEAR", "refValue": vehicleYear});
        nextRef = "ft_MAKE2";
    }

    if (vehicleMake) {
        currentRef.push({"refName": "ft_MAKE2", "refValue": vehicleMake});
        nextRef = "ft_MODEL";
    }

    if (vehicleModel) {
        currentRef.push({"refName": "ft_MODEL", "refValue": vehicleModel});
        nextRef = "ft_SUB-MODEL";
    }

    if (vehicleSubModel) {
        currentRef.push({"refName": "ft_SUB-MODEL", "refValue": vehicleSubModel});
        nextRef = "ft_OPTION2";
    }

    if (vehicleOption) {
        currentRef.push({"refName": "ft_OPTION2", "refValue": vehicleOption});
        nextRef = "Size";
    }

    var postData = {
        "action": "refineBy",
        "categid": "tgpSize",
        "currentRef": currentRef,
        "nextRef": nextRef,
        "prevRef": [],
        "dataFolder": dataFolder,
        "selectorType": vehicleAction,
        "context": vehicleAction,
        "serviceUrl": serviceUrl,
        "vehicleId": vehicleId
    };

    handleMissingFitment(postData);
}

/**
 * Handle missing fitment
 *
 * @param {Object} postData Data object with vehicle information
 */
function handleMissingFitment(postData) {
    $.ajax({
        type: "POST",
        dataType: "html",
        cache: false,
        contentType: "application/json",
        url: Urls.getMissingSelectorValues, // eslint-disable-line
        data: JSON.stringify(postData)
    }).done(function (response) {
        var $modal = $("#psmodal");

        $modal.find(".cart-psmodal-title").show();
        $modal.find(".modal-content").html(response);

        $modal.foundation("destroy");
        var psmod = new Foundation.Reveal($modal); // eslint-disable-line
        psmod.open();

        // reinit orbit slider in modal
        var $orbit = $(".selector_optionsSets .orbit");
        new Foundation.Orbit($orbit); // eslint-disable-line

        // get the current key
        var currentKey = $(".step.current").data("current-key");
        if (currentKey) {
            var $selectorOptionsContainer = $(".selector_options." + currentKey);

            // in case of one option select it automatically
            var $selectorOption = $selectorOptionsContainer.find("a.selectoroption");
            if ($selectorOption.length == 1) {
                // TODO: refactor this in the future and trigger the click after the Ajax call is done
                setTimeout(function () {
                    $selectorOption.trigger("click");
                }, 500);

                // stop next steps because the click event fires the next Ajax call that's needed
                return;
            }
        }
    });
}

/**
 * Perform shop action depending on the data received
 *
 * @param {String} vehicleYear
 * @param {String} vehicleMake
 * @param {String} vehicleModel
 * @param {String} vehicleSubModel
 * @param {String} vehicleOption
 * @param {String} vehicleSize
 * @param {String} vehicleAction
 * @param {String} serviceUrl
 * @param {String} vehicleId
 */
function performShopAction(vehicleYear, vehicleMake, vehicleModel, vehicleSubModel, vehicleOption, vehicleSize, vehicleAction, serviceUrl, vehicleId) {
    var dataObject = {},
        postData = {
            "action": "refineBy",
            "categid": "tgpSize",
            "prevRef": [],
            "selectorType": vehicleAction,
            "context": vehicleAction,
            "serviceUrl": serviceUrl,
            "vehicleId": vehicleId
        };

    dataObject.postData = postData;

    var stringToAppend = "";

    // Brakes only handles YMM, so only add necessary data
    if (vehicleAction === "brakes_selector") {
        dataObject.postData.currentRef = [
            {"refName": "ft_CYEAR", "refValue": vehicleYear},
            {"refName": "ft_MAKE2", "refValue": vehicleMake},
            {"refName": "ft_MODEL", "refValue": vehicleModel}
        ];
        // this is to redirect the user to the brakes PDP instead of the results page
        dataObject.postData.isActionsModal = true;
    } else {
        dataObject.postData.currentRef = [
            {"refName": "ft_CYEAR", "refValue": vehicleYear},
            {"refName": "ft_MAKE2", "refValue": vehicleMake},
            {"refName": "ft_MODEL", "refValue": vehicleModel},
            {"refName": "ft_SUB-MODEL", "refValue": vehicleSubModel},
            {"refName": "ft_OPTION2", "refValue": vehicleOption},
            {"refName": "Size", "refValue": vehicleSize}
        ];
    }

    handleVehicleActions(stringToAppend, dataObject);
}

/**
 * Handle step link on top of the selector
 * @param {Object} $this
 * @returns
 */
function handleStepLink($this) {
    if ($this.parents(".selector_steps").hasClass("disabled") || !$this.parent(".step.chosen").length) {
        return;
    }

    var $selector         = $(".selector"),
        $loader           = $selector.find(".loader"),
        $selectorSteps    = $(".selector_steps"),
        $currentStep      = $this.parent(),
        currentKey        = $currentStep.data("current-key"),
        $currentProdSel   = $this.parents(".productSelector"),
        $nextProdSel      = $currentProdSel.next(".productSelector"),
        $currentOptions   = $currentProdSel.find(".selector_options[data-current-key=\"" + currentKey + "\"]"),
        $tireSizeTooltip  = $(".vehicleSelectorFindTire"),
        addNewVehicleForm = FormCtrl.search("addNewVehicle"),
        blockSteps        = addNewVehicleForm instanceof FormCtrl && addNewVehicleForm.getValue("matchingVehiclesBlockSteps");

    if ($tireSizeTooltip.hasClass("trailer-tire-hint")) {
        if ($currentStep.data("label") === "Load Range") {
            $tireSizeTooltip.find(".loadRangeTrigger").removeClass("hide");
            $tireSizeTooltip.find(".tireSizeTrigger").addClass("hide");
        } else {
            $tireSizeTooltip.find(".loadRangeTrigger").addClass("hide");
            $tireSizeTooltip.find(".tireSizeTrigger").removeClass("hide");
        }
    }

    // prevent user from clicking on the - and from skipping tabs
    if (($this.text().trim() !== $this.parent().data("label")) && $this.text().trim() !== "-") {
        $currentStep.addClass("current").removeClass("chosen");
        $currentStep.nextAll().removeClass("current").removeClass("chosen");

        // staggered logic when switching from mobile to desktop in order to keep PS in sync
        $currentProdSel.prev($(".front-rear.staggered-active")).find("div[data-frontrear=\"" + $currentProdSel.data("frontrear")+"\"]").click();
        $nextProdSel.find(".step").removeClass("chosen").removeClass("current");
        $nextProdSel.find("a.steplink").each(function () {
            $(this).text($(this).parent().data("label"));
        });
        $nextProdSel.find(".selector_options").hide();
        $nextProdSel.find(".selector_options:first-child").show();

        $this.text($currentStep.data("label"));

        $currentStep.nextAll().each(function () {
            $(this).children("a").text($(this).data("label"));
        });

        if (Array.isArray(blockSteps) && !blockSteps.find(item => item === currentKey)) {
            addNewVehicleForm.goToStep("initial");
        }

        var postData = {};
        postData.nextRef = currentKey;
        postData.action = "refineBy";
        postData.categid = $currentProdSel.data("categoryid");
        postData.currentRef = [];
        postData.selectorType = $currentProdSel.data("selectortype");

        if (addNewVehicleForm instanceof FormCtrl) {
            addNewVehicleForm.setValue("newVehicle", {});
        }

        // add previous selections
        $currentProdSel.find(".selector_steps .step.chosen").each(function () {
            if ($(this).children("a").text().trim() !== "-") {
                var refData = $(this).children("a").text().trim();
                postData.currentRef.push({"refName": $(this).data("current-key"), "refValue": refData});
            }
        });

        $loader.fadeIn();
        $selectorSteps.addClass("disabled");
        $currentOptions.show().nextAll().hide().empty();
        $currentOptions.hide();

        $.ajax({
            type: "POST",
            dataType: "html",
            cache: false,
            contentType: "application/json",
            url: Urls.getselectorvalues, // eslint-disable-line
            data: JSON.stringify(postData)
        }).done(function (response) {
            $loader.fadeOut();
            $selectorSteps.removeClass("disabled");
            $currentProdSel.find(".selector_options_mobile."+ currentKey).show().html(response);
            $(".selector_options_mobile .orbit").attr("id", "");

            $currentOptions.html(response);
            $currentOptions.fadeIn();

            // setup slider
            new Foundation.Orbit($("#" + $currentOptions.find(".orbit").attr("id"))); // eslint-disable-line
        });
    }
}

/**
 * Close product selector action
 * @param {Object} $this
 * @returns
 */
function closeProductSelector($this) {
    var $productSelector = $(".productSelector"),
        $selectorStep    = $(".selector_steps .step"),
        $selectorOptions = $(".selector_optionsSets"),
        $selectorSteps   = $(".selector_steps"),
        $frontRear       = $(".front-rear"),
        $addrearsize     = $(".add-rear-size-cta"),
        $mainSelectorTab = $(".main-selector-tab"),
        $PSSlotWrap      = $("#productSelectorSlotWrapper");

    if ($this.parent().find(".selector_steps").hasClass("disabled")) {
        return;
    }

    $productSelector.hide();
    $addrearsize.hide();

    $this.parents(".selector").find(".selector-tabs").show();
    $mainSelectorTab.show();
    if ($PSSlotWrap.hasClass("expanded")) {
        $PSSlotWrap.removeClass("expanded");
    }

    resetSelector($selectorStep, $selectorSteps, $selectorOptions, $productSelector, $frontRear);
}

function initProdSel() {
    var $document               = $(document),
        $body                   = $("body"),
        $selector               = $(".selector"),
        $mainSelectorTab        = $(".main-selector-tab"),
        $productSelector        = $(".productSelector"),
        $selectorStep           = $(".selector_steps .step"),
        $selectorOptions        = $(".selector_optionsSets"),
        $selectorSteps          = $(".selector_steps"),
        $frontRear              = $(".front-rear"),
        $addrearsize            = $(".add-rear-size-cta"),
        $rearSizeWrap           = $(".add-rear-size"),
        $PSSlotWrap             = $("#productSelectorSlotWrapper"),
        $selectorTab            = $(".selector-tab"),
        $serviceSelectorContent = $PSSlotWrap.find(".service-selector-content");

    // click on main selector type tab (tire selector, wheel selector)
    $mainSelectorTab.on("click", function (e) {
        e.preventDefault();
        var $this = $(this);

        if ($this.parents("#productSelectorSlotWrapper").hasClass("expanded")) {
            return;
        }

        // add class active to tab and selector
        $selector.removeClass("active");
        $mainSelectorTab.removeClass("active");
        $this.addClass("active");
        $selector.filter("[data-selectorid=\"" + $this.data("selectorid") + "\"]").addClass("active").find(".selector-tabs").show();

        // reset selector data
        $productSelector.hide();
        resetSelector($selectorStep, $selectorSteps, $selectorOptions, $productSelector, $frontRear);
        $addrearsize.hide();
    });

    if ($("#psmodal .modal-content").length === 0) {
        resetSelector($selectorStep, $selectorSteps, $selectorOptions, $productSelector, $frontRear);
        var $prodSelShow = $productSelector.filter(".show");
        $prodSelShow.show();
        $("#productSelectorSlotWrapper.expanded .add-rear-size-cta.mobile-only").show();
    }

    // click on selector type (tires by ymmo, tires by size)
    $(".selector-tab").on("click touchstart", function (e) {
        e.preventDefault();

        if ($selectorTab.length && $selectorTab[0].dataset && $selectorTab[0].dataset.producturl) {
            window.location = $selectorTab[0].dataset.producturl;
            return;
        }

        if (insideChat.getData("answers")) {
            insideChat.deleteData("answers");
        }

        if (insideChat.getData("wheelSize")) {
            insideChat.deleteData("wheelSize");
        }

        var parentSelectorId = $(this).parents(".selector.active").data("selectorid");
        $mainSelectorTab.hide().filter("[data-selectorid=\"" + parentSelectorId + "\"]").show();
        $PSSlotWrap.addClass("expanded");

        $productSelector.hide();
        $selector.find(".selector-tabs").hide();

        // hide mobile tabs
        $productSelector.parents(".vertical.menu > li").hide();

        $productSelector.filter("[data-selectortype=\"" + $(this).data("tabid") + "\"]").first().show();

        // show specific tab
        $productSelector.filter("[data-selectortype=\"" + $(this).data("tabid") + "\"]").parents(".vertical.menu > li").show().addClass("insideSelector");

        if ($(this).data("tabid").indexOf("size") > -1) {
            $addrearsize.show();
        } else {
            $addrearsize.hide();
        }
    });

    // close btn function
    $(".selector_close").on("click", function (e) {
        e.preventDefault();

        closeProductSelector($(this));
    });

    // close btn function
    $(".selector_close").on("keydown", function (e) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();

            closeProductSelector($(this));
        }
    });

    // select front read size
    $rearSizeWrap.on("click", function (e) {
        e.preventDefault();
        var $link      = $(this).find("a");

        //reset selector in case back size is selected

        if ($link.data("selected") == true && $frontRear.find(".rear").hasClass("active")) {
            $selectorStep.removeClass("current").removeClass("chosen");
            $(".selector_steps").find(".step:first").addClass("current");

            $selectorStep.each(function () {
                $(this).children("a").text($(this).data("label"));
            });

            $selectorOptions.each(function () {
                $(this).find(".selector_options").not(":eq(0)").empty().hide();
                $(this).find(".selector_options").first().show();
            });
        }


        if ($link.data("selected") == false) {
            $frontRear.show();
            $(".front-rear").addClass("staggered-active");
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"rear\"]").addClass("staggered-active");
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"rear\"]").find(".step.current").removeClass("current");
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"front\"]").addClass("staggered-active");
            $rearSizeWrap.find("a").data("selected", true);
            $rearSizeWrap.find("a").text($link.data("selected-label"));
        } else {
            $frontRear.hide();
            $(".front-rear").removeClass("staggered-active");
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"rear\"]").removeClass("staggered-active");
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"front\"]").removeClass("staggered-active");
            $rearSizeWrap.find("a").data("selected", false);
            $rearSizeWrap.find("a").text($link.data("label"));

            $frontRear.each(function () {
                $(this).children("div").removeClass("active").eq(0).addClass("active");
            });

            $productSelector.hide();

            $(this).parents(".selector").find(".productSelector[data-frontrear=\"front\"]").show();
            $(this).parents(".selector").find(".productSelector[data-frontrear=\"front\"]").find(".selector_steps .step a").first().click();
        }

        // populate first selection in case of edit
        if ($(this).hasClass("edit")) {
            $selectorOptions.each(function () {
                var $firstChild = $(this).children(".selector_options").eq(0);

                if (!$.trim($firstChild.html())) {
                    var postData = {};
                    postData.nextRef = $firstChild.data("current-key");
                    postData.action  = "refineBy";
                    postData.categid = $(this).parents(".productSelector").data("categoryid");
                    postData.selectorType = $(this).parents(".productSelector").data("selectortype");

                    $.ajax({
                        type: "POST",
                        dataType: "html",
                        cache: false,
                        contentType: "application/json",
                        url: Urls.getselectorvalues, // eslint-disable-line
                        data: JSON.stringify(postData)
                    }).done(function (response) {
                        //treat case in which reply is empty
                        $firstChild.html(response);
                        $(".selector_options_mobile"+"."+postData.nextRef).html(response);
                    });
                }
            });
        }

    });

    // click on front rear div
    $frontRear.on("click", "div", function () {
        var $currentSel     = $(this).parents(".selector"),
            $currentProdSel = $currentSel.find(".productSelector[data-frontrear=\"" + $(this).data("frontrear") + "\"]");

        if ($(this).hasClass("rear") && $currentSel.find(".productSelector[data-frontrear=\"front\"]").find(".selector_steps .step:not(\".chosen\")").length == 0) {
            $currentSel.find(".productSelector[data-frontrear=\"rear\"] .step").first().addClass("current");
            $(this).siblings().removeClass("active");
            $(this).addClass("active");

            $productSelector.hide();
            $currentProdSel.show();
        } else if ($(this).hasClass("front")) {
            $(this).siblings().removeClass("active");
            $(this).addClass("active");

            $productSelector.hide();
            $currentProdSel.show();
        } else {
            return;
        }
    });

    $(".selector_options_mobile").on("click", "a", function (e) {
        e.preventDefault();

        var $this           = $(this),
            currentKey      = $this.parents(".selector_options_mobile").data("current-key"),
            $currentProdSel = $this.parents(".productSelector"),
            $selectorItem   = $this.parents("[data-original-text]"),
            text            = $this.text();

        // if the anchor's text is being modified, use the original text previous stored for the comparison
        if ($selectorItem.length && $selectorItem.data("original-text")) {
            text = $selectorItem.data("original-text");
        }

        $currentProdSel.find(".selector_optionsSets").find(".selector_options[data-current-key=\"" + currentKey + "\"] a[data-value=\""+ $this.data("value") +"\"]").filter(function () {
            return $(this).text().trim() == text.trim();
        }).click();

        // animate selection so it's easier to navigate through options
        $("html, body").animate({
            scrollTop: $currentProdSel.offset().top
        }, 200);
    });

    // actions for selecting a vehicle
    $(".selector_options").on("click", "a.selectoroption", function (e) {
        e.preventDefault();

        handleSelectorValueSelection($(this));
    });

    // actions for selecting a vehicle
    $(".selector_options").on("keydown", "a.selectoroption", function (e) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();

            handleSelectorValueSelection($(this));
        }
    });

    // actions for deselecting
    $(".selector_steps .step").on("click", "a.steplink", function (e) {
        e.preventDefault();

        handleStepLink($(this));
    });

    // actions for deselecting
    $(".selector_steps .step").on("keydown", "a.steplink", function (e) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleStepLink($(this));
        }
    });

    $document.on("click", ".selector .pagedirect", function (e) {
        e.stopImmediatePropagation();
        var $par = $("#"+$(this).parents(".orbit").attr("id"));
        var isNextPage = $(this).data("isnextpage") ? true : false;
        $par.foundation("changeSlide", isNextPage);
    });

    // actions product grid
    $(".result_btn").on("click", "button", function (e) {
        e.preventDefault();
        if ($(this).hasClass("submit-is-active")) {
            window.location = $(this).data("url") ? $(this).data("url") : $(".drivingConditions #productGridUrl").attr("href");
        }
    });

    if (!Foundation.MediaQuery.atLeast("medium")) { // eslint-disable-line
        syncTabletToMobile();

        if ($("#productSelectorSlotWrapper.subselector").length < 1) {
            $("#psMobile >li ul.menu").each(function () { $("#psMobile").foundation("up", $(this));});
        }
    }

    // update product selector on media query change
    $(window).on("changed.zf.mediaquery", function (event, newSize, oldSize) { // eslint-disable-line
        if (!Foundation.MediaQuery.atLeast("medium")) { // eslint-disable-line
            syncTabletToMobile();
        }
    });

    $("li.is-accordion-submenu-parent>a").on("click", function () {
        var selectorid = $(this).parent().find(".is-accordion-submenu").data("selectorid");
        $(".main-selector-tab[data-selectorid=\""+ selectorid +"\"]").click();
        $(".selector_close").click();
    });

    /**
    * Handles the keydown event for accordion submenu parent links
    * @param {Event} event - the keydown event
    * @returns {void}
    */
    $("li.is-accordion-submenu-parent > a").on("keydown", function (event) {
        if (event.key === "Enter") {
            event.preventDefault();

            $(this).trigger("click");
        }
    });


    $("#product-sel-info, #noresults-selector, .verify-vehicle").off("click").on("click", ".edit-selector", function (e) {
        e.preventDefault();
        var $this = $(this);
        var action = $this.data("action");

        var selInitUrl = util.appendParamToURL(Urls.productselectormodal, "action", action), // eslint-disable-line
            $modal     = $("#psmodal");

        $modal.find(".cart-psmodal-title").show();

        selInitUrl = util.appendParamToURL(selInitUrl, "type", $this.data("type"));

        $.ajax(selInitUrl)
            .done(function (resp) {
                $modal.find(".modal-content").html(resp);

                $modal.foundation("destroy");
                var psmod = new Foundation.Reveal($modal); // eslint-disable-line
                psmod.open();

                // Create a new instance of FormCtrl
                const formConfig = FormCtrl.getConfig("addNewVehicle");
                formConfig.$form = psmod.$element;
                formConfig.values.addNewVehicleContainer = addNewVehicleContainer;
                formConfig.values.takeMeToTheVehicleAction = takeMeToTheVehicleAction;
                const addNewVehicleForm = new FormCtrl(formConfig);

                // when editing a vehicle from CLP or PDP through size context, skip form validation for the selector to work properly
                var shouldSkipFormValidation = action === "edit";
                addNewVehicleForm.resetForm(shouldSkipFormValidation);

                initProdSel();

                if (selInitUrl.indexOf("verifyYmmo") > -1) {
                    $(".productSelector.active .selector_optionsSets .selector_options:not(:first-child)").hide();
                } else {
                    $(".productSelector.active .selector_optionsSets .selector_options:not(:last-child)").hide();
                }

                if ($(".productSelector.active[data-frontrear=\"rear\"]").length > 0) {
                    $(".productSelector[data-frontrear=\"front\"] .selector_options").hide();
                    $(".productSelector.active[data-frontrear=\"rear\"] .add-rear-size").click();
                }

                $(".productSelector.active[data-frontrear=\"rear\"] .selector_steps .step:last-child").addClass("current");
                handleDuplicatedSizes();
            });
    });

    var $vehicle = $(".vehicle-info").parent();
    // hide the informations and select vehicle text if a vehicle is selected
    if ($vehicle.length > 0 && !$vehicle.hasClass("hide")) {
        $(".informations").addClass("hide");
        $(".select-vehicle").addClass("hide");
    }

    $("#product-sel-info, #noresults-selector, .verify-vehicle").off("click", ".change-selector").on("click", ".change-selector", function (e) {
        e.preventDefault();

        var $this = $(this),
            $modal = $("#psmodal"),
            selInitUrl = util.appendParamToURL(Urls.productselectormodal, "action", $this.data("action")); // eslint-disable-line

        selInitUrl = util.appendParamToURL(selInitUrl, "ismygarage", $this.data("ismygarage"));

        $modal.find(".cart-psmodal-title").show();

        selInitUrl = util.appendParamToURL(selInitUrl, "type", $this.data("type"));

        $.ajax(selInitUrl)
            .done(function (resp) {
                $modal.find(".modal-content").html(resp);

                $modal.foundation("destroy");

                // Create a new instance of FormCtrl
                const formConfig = FormCtrl.getConfig("addNewVehicle");
                formConfig.$form = $modal;
                formConfig.values.addNewVehicleContainer = addNewVehicleContainer;
                formConfig.values.takeMeToTheVehicleAction = takeMeToTheVehicleAction;
                const addNewVehicleForm = new FormCtrl(formConfig);
                addNewVehicleForm.resetForm();

                var psmod = new Foundation.Reveal($modal);
                psmod.open();

                initProdSel();
            });
    });

    $(".verify-vehicle").off("click", ".change-vehicle").on("click", ".change-vehicle", function (e) {
        e.preventDefault();
        var $modal = $("#psmodal");
        var psmod = new Foundation.Reveal($modal); // eslint-disable-line
        psmod.open();
    });

    $document.on("click", ".keep-vehicle", function (e) {
        e.preventDefault();
        $("#psmodal").find(".close-button").click();
    });

    $document.on("click", ".exit-checkout", function (e) {
        e.preventDefault();
        const verifyVehicleForm = FormCtrl.search("verifyVehicle");

        $.ajax({
            type: "GET",
            cache: false,
            contentType: "application/json",
            url: Urls.editVehicleInCart,
        }).done(function (resp) {
            if (!verifyVehicleForm.isEmpty) {
                verifyVehicleForm.deleteFromSession("selectedVehicleId");
            }

            window.location = resp.redirCat;
        });
    });

    $(".selection-information, .refinements").on("click", ".oe-size a", function (e) {
        e.preventDefault();
        var url = Urls.setPackageID + "?fitID=" + $(this).parent().data("ftid"); // eslint-disable-line
        var redirUrl = $(this).attr("href");
        $.ajax(url)
            .done(function () {
                window.location = redirUrl;
            });

    });

    // Added selected icon to the option that you choose from service selector
    $serviceSelectorContent.on("click", ".choice", function () {
        $serviceSelectorContent.find(".check-icon").removeClass("selected");
        $(this).find(".check-icon").addClass("selected");
    });

    $body.on("click", ".incomplete-values .selector_options a.selectoroption", function (e) {
        e.preventDefault();

        handleSelectorValueSelection($(this));
    });

    $body.on("click", ".incomplete-values .selector_options_mobile a.selectoroption", function (e) {
        e.preventDefault();

        var $this           = $(this),
            currentKey      = $this.parents(".selector_options_mobile").data("current-key"),
            $currentProdSel = $this.parents(".productSelector"),
            text            = $this.text();

        $currentProdSel.find(".selector_optionsSets").find(".selector_options[data-current-key=\"" + currentKey + "\"] a[data-value=\""+ $this.data("value") +"\"]").filter(function () {
            return $(this).text().trim() == text.trim();
        }).trigger("click");

        // animate selection so it's easier to navigate through options
        $("html, body").animate({
            scrollTop: $currentProdSel.offset().top
        }, 200);
    });
}

function changeRefinementURL(initialUrl, prefnName, prefnValues) {
    var params = initialUrl.split("?")[1];
    params = params.split("&");
    var prefn = "";
    var currentVal = "";

    for (var i = 0; i < params.length; i++) {
        if (params[i].split("=")[1] === prefnName) {
            prefn = params[i].split("=")[0];
            break;
        }
    }

    if (prefn === "") {
        return initialUrl;
    }

    var prefv = prefn.replace("prefn", "prefv");

    for (var j = 0; j < params.length; j++) {
        if (params[j].split("=")[0] === prefv) {
            currentVal = params[j].split("=")[1];
            break;
        }
    }

    return initialUrl.replace(prefv + "=" + currentVal, prefv + "=" + prefnValues);
}

// Results page category selection events
$(".selector_checkbox").find("input:checkbox").each(function () {
    $(this).prop("checked", false);
});

$(".tire-types").on("click", ".selector_checkbox", function (e) {
    if ($(e.target).is("span")) {
        e.preventDefault();
        return;
    }

    var $checkbox     = $(this).find("input:checkbox"),
        $resultBtn    = $(".result_btn button"),
        newRef        = {};

    $(this).toggleClass("selected");

    if ($checkbox.is(":checked")) {
        $checkbox.prop("checked", false);
    } else {
        $checkbox.prop("checked", true);
    }

    var $checkBoxes   = $(".selector_checkbox .checkbox_option input:checkbox");
    var $checkedBoxes = $checkBoxes.filter(":checked");

    if ($checkedBoxes.length > 0) {
        $resultBtn.removeAttr("disabled").addClass("submit-is-active");
    } else {
        $resultBtn.attr("disabled", "true").removeClass("submit-is-active");
    }

    $checkBoxes.each(function () {
        if (newRef[$(this).data("refinement")]) {
            newRef[$(this).data("refinement")] += $(this).is(":checked") ? "|" + $(this).val() : "";
        } else {
            newRef[$(this).data("refinement")] = $(this).is(":checked") ? $(this).val() : "";
        }
    });

    var newUrl = $(".tire-types #productGridUrl").attr("href");

    for (var key in newRef) {
        newUrl = changeRefinementURL(newUrl, key, newRef[key]);
    }

    $resultBtn.data("url", newUrl);
});

exports.init = function () {
    initProdSel();

    var actionsModalParam = util.getURLParameterByName("actionsmodal", window.location.href);
    if (actionsModalParam && actionsModalParam === "true") {
        $(".selector-tab").trigger("click");
    }

    if (pageContext && pageContext.type == "psresults") { // eslint-disable-line
        guidedSellingPath.init();
    }

    // RideStyler Visualizer integration
    if ($("#v-visualizer-default-vehicle-small").length) {
        visualizer.getDefaultVehicleImageUrl();
    }
};

exports.getMissingDataPopup = getMissingDataPopup;
exports.performShopAction = performShopAction;
exports.addNewVehicleContainer = addNewVehicleContainer;
