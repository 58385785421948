"use strict";

/**
 * Gets the total price of the product based on the selected quantity
 * @returns {undefined}
 */
var getTotalPrice = function () {
    var $productTotalPrice = $("#totalPriceSection");
    var available = $productTotalPrice.data("available") ? $productTotalPrice.data("available") : false;
    var isProductSet = $productTotalPrice.data("product-set") ? true : false;
    var pid;
    var url;

    if (isProductSet) {
        pid = $(".add-all-to-cart").data("psid");

        var productSetData = [];
        var productSetProducts = $(".product-set-item");
        var productSetQuantities = $(".productQuantity");

        // retrieve the pid and corresponding quantity based on the order in which the products are displayed
        for (var i = 0; i < productSetProducts.length; i++) {
            productSetData.push({
                ID: productSetProducts[i].id && productSetProducts[i].id.split("-")[1] ? productSetProducts[i].id.split("-")[1] : null,
                quantity: productSetQuantities[i].value ? productSetQuantities[i].value : null
            });
        }

        url = new URL(location.origin + Urls.getSetTotalPrice);

        url.searchParams.set("productSetData", JSON.stringify(productSetData));
    } else {
        pid = $("#pid").val();
        var isMasterProduct = $productTotalPrice.data("master") ? true : false;
        var productQuantity = $(".productQuantity").val();
        url = new URL(location.origin + Urls.getTotalPrice);

        url.searchParams.set("quantity", productQuantity);
        url.searchParams.set("isMaster", isMasterProduct);
    }

    url.searchParams.set("pid", pid);
    url.searchParams.set("available", available);

    $.ajax({
        url: url.toString(),
        dataType: "html",
        type: "GET"
    }).done(function (response) {
        $productTotalPrice.html(response);
    });
};

module.exports = function () {
    // attach event listener to the quantity selector
    $(".productQuantity").on("change", getTotalPrice);

    // attach event listener to the pageshow event
    // this is needed to update the total price when the user navigates back to the page from the cart, for example
    window.addEventListener("pageshow", getTotalPrice);
};
