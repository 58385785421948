/**
 * Represents an event that can occur on a form of FormCtrl.
 *
 * @class
 */
class FormEvent {
    /**
     * Creates an instance of FormEvent.
     *
     * @constructor
     * @param {string} name - The name of the event.
     * @param {function} handler - The function to be executed when the event is triggered.
     * @param {FormEventsModel} [_events] - An optional reference to the form's events object and avoid recycle and infinite loops issues.
     */
    constructor(name, handler, _events) {
        this._events = () => _events;

        this.name = name;
        this.handler = typeof handler === "function" ? handler : () => {};
        this.handler = this.handler.bind(this.parentForm);
    }

    /**
     * Gets the parent FormCtrl object from the FormEventsModel object.
     *
     * @readonly
     * @returns {FormCtrl} - The parent FormCtrl object.
     */
    get parentForm() {
        return this._events() && this._events().parentForm;
    }

    /**
     * Gets the _events object.
     *
     * @readonly
     * @returns {FormEventsModel} - The parent FormEventsModel object.
     */
    get formEvents() {
        return this._events();
    }

    /**
     * Executes the handler function with the parentForm as the context and any additional parameters.
     *
     * @param {...*} params - Additional parameters to be passed to the handler function.
     * @returns {*} - The return value of the handler function.
     */
    trigger(...params) {
        return this.handler(this, ...params);
    }
}

/**
 * @module FormEvent
 */
module.exports = FormEvent;
