"use strict";

var address = require("./address"),
    billing = require("./billing"),
    multiship = require("./multiship"),
    shipping = require("./shipping"),
    calendar = require("./calendar"),
    util = require("./../../util");

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    calendar.init();
    if ($(".checkout-shipping").length > 0) {
        shipping.init();
    } else if ($(".checkout-multi-shipping").length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    if ($(".appointment-popup").length > 0) {
        var appointmentPopup = new Foundation.Reveal($(".appointment-popup")); // eslint-disable-line
        appointmentPopup.open();
        $(document).ready(function () {
            $(document).trigger("gtm_appointment_expired");
        });
        $(document).on("closed.zf.reveal", "[data-reveal]", function () {
            window.location = Urls.installationAppointment; // eslint-disable-line
        });
    }

    // if on the order review page and there are products that are not available diable the submit order button
    if ($(".order-summary-footer").length > 0) {
        if ($(".notavailable").length > 0) {
            $(".order-summary-footer .submit-order .button-fancy-large").attr("disabled", "disabled");
        }
    }

    $.each($(".order-review-page .location-box p"), function () {
        var $this = $(this),
            newCapitalizedContent = util.capitalizeWords($this.text());

        $this.text(newCapitalizedContent);
    });

    // Loader for appointment page
    var $listViewItemsLoader = $(".select-location-wrapper").find(".loader.list-view-items-loader");
    $listViewItemsLoader.fadeIn();
    $("body").one("DOMNodeInserted", ".list-view-items", function () {
        $listViewItemsLoader.fadeOut();
    });
};
