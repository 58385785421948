"use strict";

var progress = require("../progress");
var Promise  = require("promise");
var productTile   = require("../product-tile");

var status,  // eslint-disable-line
    wheelStatus;

function promisePostRequest(params, url) {
    return Promise.resolve(
        $.ajax({
            method: "POST",
            url   : url,
            data  : params
        })
    );
}

function getStatusRequest() {
    var status          = false, //NOSONAR
        $visualizerPdp  = $("#v-visualizer-pdp"),
        $visualizerGrid = $("#search-result-items");

    if (($visualizerPdp.length && $visualizerPdp.data("isvisualizer")) || ($visualizerGrid.length && $visualizerGrid.data("isvisualizer"))) {
        status = true;
    }
    return status;
}

/**
 * @private
 * @function getWheelVehicleImage
 * @description sends request to Visualizer-GetVehicleImageByParams controller
 * @param{Object || String} params that will be sent to controller
 */
function getWheelVehicleImageRequest(params) {
    return Promise.resolve(
        $.ajax({
            method     : "GET",
            url        : Urls.getWheelVehicleImage, // eslint-disable-line
            data       : params
        })
    );
}


/**
 * @private
 * @function getWheelDescription
 * @description sends request to Visualizer-showProductSearchVehicleByWheel controller
 * @param{Object || String} params that wll be sent to controller
 */
function getWheelDescription(params) {
    return Promise.resolve(
        $.ajax({
            method     : "GET",
            url        : Urls.showProductSearchVehicleByWheel, // eslint-disable-line
            data       : params
        })
    );
}

function getDefaultVehicleImageUrl() {
    // @todo #KALT-245 FD provide parameters for width, height, custom class if needed for img
    // for now test one used instead
    var data = {
        "Width" : "265",
        "Height": "110",
    };
    $(".v-view-on-vehicle").hide();

    promisePostRequest(data, Urls.getDefaultVehicleImageUrl).then(function (response) { // eslint-disable-line
        if (response) {
            var $defaultVehicleArea = $("#v-visualizer-default-vehicle-small");

            $defaultVehicleArea.html(response);

            if (response.indexOf("img") > -1) {
                // show loading
                $(".v-view-on-vehicle").show();
                progress.show($defaultVehicleArea);

                var $vehicleImage = $defaultVehicleArea.find("img");
                if ($vehicleImage.length > 0) {
                    // main image loaded?
                    $vehicleImage.on("load", function () {
                        progress.hide();
                    });
                } else {
                    progress.hide();
                }
            }
        }
    });
}

/**
 * Initialize Visualizer My Garage Vehicle Image URL
 */
function initMygarageVehicleImageUrl($newCarContainerSelector) {
    var $carContainers = {};

    if (!$newCarContainerSelector) {
        $carContainers = $(".car-container");
    } else {
        $carContainers = $($newCarContainerSelector); // eslint-disable-line
    }

    $.each($carContainers, function (key, carContainer) {
        var data = {
            "Width" : "265",
            "Height": "110",
        };
        var $carContainer = $(carContainer);
        var fitmentIds = $carContainer.data("fitmentids");
        var carYear = $carContainer.data("vehicle-year");
        var carMake = $carContainer.data("vehicle-make");
        var carModel = $carContainer.data("vehicle-model");

        if (fitmentIds) {
            data.fitmentIds = fitmentIds;
        } else {
            if (carYear) {
                data.carYear = carYear;
            }

            if (carMake) {
                data.carMake = carMake;
            }

            if (carModel) {
                data.carModel = carModel;
            }
        }

        getVehicleImage(data, $carContainer);
    });
}

/**
 * Get the vehicle image
 * @param {Object} data
 * @param {Object} $carContainer
 */
function getVehicleImage(data, $carContainer) {
    promisePostRequest(data, Urls.getMygarageVehicleImageUrl).then(function (response) { // eslint-disable-line
        $carContainer.find(".car-image").html(response);
    });
}

function verifyWheelAvailability() {
    var wheelId = $("#v-visualizer-pdp").find("input[name='wheelID']").val();

    if (typeof wheelStatus !== "undefined" && wheelStatus) {
        getProductVehicleVisualizer();
    }

    promisePostRequest({wheelID:wheelId}, Urls.verifyWheelAvailability).then(function (response) { // eslint-disable-line
        if (response.status) {
            wheelStatus = true;

            getProductVehicleVisualizer();
        }
        wheelStatus = false;
    });
}

function getProductVehicleVisualizer() {
    var wheelId = $("#v-visualizer-pdp").find("input[name='wheelID']").val();

    promisePostRequest({wheelID:wheelId}, Urls.getProductVehicleVisualizer).then(function (response) { // eslint-disable-line
        if (response) {
            var $defaultVehicleArea = $("#v-visualizer-pdp");

            $defaultVehicleArea.html(response);

            // show loading
            progress.show($defaultVehicleArea);
            // main image loaded ?
            $("#v-visualizer-pdp img").on("load", function () {
                // hide/remove the loading
                progress.hide();
            });

            initializeProductEvents();
        }
    });
}

function initializeProductEvents() {
    var $vMain  = $("#v-visualizer-pdp");

    $vMain.on("click", "#v-colors li", function (e) {
        e.preventDefault();

        var params = {},
            color = $(this).find("input[name='colorHex']").val(),
            wheelID = $vMain.find("input[name='wheelID']").val();

        // update color selection
        $("#v-colors li").each(function () {
            $(this).removeClass("active");
        });

        $(this).addClass("active");

        if (color) {
            params.Color = color;
        }

        if (wheelID) {
            params.Wheel = wheelID;
        }

        // @todo FD to add sizes for ajax calls;
        // @todo also fix loader

        getWheelVehicleImageRequest(params).then(function (response) {
            if (response) {
                $("#v-vehicle-image").html(response);

                // show loading
                progress.show($("#v-pdp-toplevelcomponent"));
                // main image loaded ?
                $("#v-vehicle-image img").on("load", function () {
                    // hide/remove the loading
                    progress.hide();
                });
            }
        });
    });

    // initialize foundation
    $vMain.foundation();
}

function productInit() {
    if (typeof status !== "undefined") {
        return status;
    }

    var status =  getStatusRequest(); //NOSONAR
    if (status) {
        getDefaultVehicleImageUrl();
        verifyWheelAvailability();
    } else {
        if ($("#v-visualizer-default-vehicle-small").length) {
            getDefaultVehicleImageUrl();
        }
    }
}

function getDomWheelIds() {
    var $wheels = $("#search-result-items>li"),
        wheels  = [];

    $.each($wheels, function () {
        var wheelId = $(this).find(".product-tile").first().attr("data-itemid");

        if (wheelId) {
            wheels.push(wheelId);
        }
    });

    return wheels;
}

function initializeSearchDom() {
    $(document).ready(function () {
        var wheels = getDomWheelIds();

        if (wheels.length > 0) {
            promisePostRequest({wheelItemNumbers:JSON.stringify(wheels)}, Urls.setVisualizerSession).then(function (response) { // eslint-disable-line
                if (response.status) {
                    status = true;

                    getSearchVehicleVisualizer(wheels[0], "small");
                }
            });
        }
    });
}

function getSearchVehicleVisualizer(wheelId, type, color) {
    if (getStatusRequest()) {
        var wheels   = getDomWheelIds(),
            packages = "";
        if (window.location.href.indexOf("packages=true") > -1) {
            packages = "?packages=true";
        }
        promisePostRequest({wheelID:wheelId, color:color, wheelItemNumbers:JSON.stringify(wheels)}, Urls.getSearchVehicleVisualizer + packages).then(function (response) { // eslint-disable-line
            // hide view on vehicle buttons when all wheels on the page could be rendered
            if (!response.replace(/\s/g, "").length) {
                $(".v-view-on-vehicle").hide();
            } else {
                var $defaultVehicleArea = $("#v-visualizer-"+type);
                $defaultVehicleArea.html(response);

                // initialize slick
                initializeCarousel();

                // show loading
                progress.show($defaultVehicleArea);
                // main image loaded ?
                $("#v-visualizer-search img").on("load", function () {
                    // hide/remove the loading
                    progress.hide();
                });

                updateViewOnVehicle(wheels);
                initializeSearchEvents();
            }
        });
    }
}

/**
 * Update "View on vehicle button"
 * @param wheels
 */
function updateViewOnVehicle(wheels) {
    promisePostRequest({wheelItemNumbers:JSON.stringify(wheels)}, Urls.verifyWheelsAvailability).then(function (response) { // eslint-disable-line
        if (response.status) {
            var $wheels = $("#search-result-items>li");

            $.each($wheels, function () {
                var wheelId = $(this).find(".product-tile").first().attr("data-itemid");

                if (!response.wheels[wheelId]) {
                    $(this).find(".v-view-on-vehicle").addClass("disabled");
                }
            });

            productTile.init();
        }
    });
}

function initializeSearchEvents() {
    var $vMain = $("#v-toplevelcomponent");

    $vMain.on("click", "#v-carousel .v-carousel-item", function (e) {
        e.preventDefault();

        var params  = {},
            wheelID = $(this).find("input[name='wheelID']").val(),
            color   = $("#v-colors").find(".active").find("input[name='colorHex']").val();

        params.wheelID = wheelID;

        if (color) {
            params.color = color;
        }

        // todo FD can pass height and width

        // update wheel selection
        $("#v-carousel .v-carousel-item").each(function () {
            $(this).removeClass("active");
        });
        // FD todo make orange undercore as described in FSD
        $(this).addClass("active");

        getWheelDescription(params).then(function (response) {
            if (response) {
                $("#v-wheeldescription").html(response);

                // show loading
                progress.show($("#v-wheeldescription"));
                // main image loaded ?
                $("#v-visualizer-search img").on("load", function () {
                    // hide/remove the loading
                    progress.hide();
                    // initialize foundation
                    $vMain.foundation();
                });
            }
        });
    });

    $vMain.on("click", "#v-colors li", function (e) {
        e.preventDefault();

        var params = {},
            color = $(this).find("input[name='colorHex']").val(),
            wheelID;

        $("#v-carousel .v-carousel-item").each(function () {
            if ($(this).hasClass("active")) {
                wheelID = $(this).find("input[name='wheelID']").val();
            }
        });

        // update color selection
        $("#v-colors li").each(function () {
            $(this).removeClass("active");
        });

        $(this).addClass("active");

        if (color) {
            params.color = color;
        }

        params.wheelID = wheelID;

        getWheelDescription(params).then(function (response) {
            if (response) {
                $("#v-wheeldescription").html(response);

                // show loading
                progress.show($("#v-wheeldescription"));
                // main image loaded ?
                $("#v-visualizer-search img").on("load", function () {
                    // hide/remove the loading
                    progress.hide();
                    // initialize foundation
                    $vMain.foundation();
                });
            }
        });
    });

    $("#main").on("click", ".v-view-on-vehicle", function (e) {
        e.preventDefault();

        if ($(this).hasClass("disabled")) {
            return;
        }

        var params = {},
            wheelID = $(this).find("input[name='wheelID']").val(),
            // todo FD change for proper trigger
            color   = $("#v-colors").find(".active").find("input[name='colorHex']").val();

        // update wheel selection
        $("#v-carousel .v-carousel-item").each(function () {
            $(this).removeClass("active");
            if ($(this).find("input[name='wheelID']").val() == wheelID) {
                // FD todo make orange undercore as described in FSD
                $(this).addClass("active");
            }
        });

        if (color) {
            params.color = color;
        }
        if (window.location.href.indexOf("packages=true") > -1) {
            params.packages  = true;
        }

        params.wheelID = wheelID;

        getWheelDescription(params).then(function (response) {
            if (response) {
                $("#v-wheeldescription").html(response);
                // show loading
                progress.show($("#v-wheeldescription"));

                // scroll
                $("html, body").animate({
                    scrollTop: $("#v-toplevelcomponent").offset().top
                }, 500);


                // main image loaded ?
                $("#v-visualizer-search img").on("load", function () {
                    // hide/remove the loading
                    progress.hide();
                });

                // initialize foundation
                $vMain.foundation();
            }
        });
    });

    $("#v-visualizer-large, #v-visualizer-small").on("click", "#v-visualizer-expand a", function () {
        var state = $(this).attr("data-expand"),
            visualizer; //NOSONAR
        var vLarge = $("#v-visualizer-large");
        var vSmall = $("#v-visualizer-small");

        if (state == "max") {
            visualizer = $(vSmall).find("#v-toplevelcomponent").detach();
            $(vSmall).empty();
            $(vLarge).append(visualizer);
        } else {
            visualizer = $(vLarge).find("#v-toplevelcomponent").detach();
            $(vLarge).empty();
            $(vSmall).append(visualizer);
        }

        //refresh slick
        $(".carousel-container")[0].slick.refresh();
    });


    // initialize foundation
    $vMain.foundation();
}

function searchInit() {
    var status = getStatusRequest(); //NOSONAR

    if (status && $("#v-visualizer-default-vehicle-small").length) {
        getDefaultVehicleImageUrl();
    }

    initializeSearchDom();
}

function initializeCarousel() {
    $(".carousel-container").slick({
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: ".carousel-prev",
        nextArrow: ".carousel-next",
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}

var visualizer = {
    getDefaultVehicleImageUrl   : getDefaultVehicleImageUrl,
    initMygarageVehicleImageUrl : initMygarageVehicleImageUrl,
    productInit                 : productInit,
    searchInit                  : searchInit
};

module.exports = visualizer;
