module.exports = {
    formName: "addNewVehicle",
    formSelector: "#psmodal",
    formSteps: ["initial", "matching-vehicles", "name-vin-plate", "loading", "duplicated-name"],
    openModalSelector: ".add-vehicle.change-selector",
    // The selectors for the form fields
    fieldSelectors: {
        name: "#psmodal .vehicle-name",
        plateNo: "#psmodal .licensePlate",
        vin: "#psmodal .vinNumber"
    },
    // The elements that need to be accessed in the form
    elements: {
        $takeMeToVehicleBtn: "#psmodal .take-me-to-vehicle",
        $addNewVehicleBtn: "#psmodal .add-new-vehicle",
        $matchingVehicleMessage: "#psmodal .matching-vehicles-fields .message",
        $saveButton: "#psmodal .save-vehicle"
    },
    // The default values for certain variables
    values: {
        isMatchingVehicleResolved: false,
        matchingVehiclesBlockSteps: ["Size", "Name"],
        addVehicleContainerToMyGarage: function (formCtrl, success, ajaxData) {
            const addNewVehicleContainer = formCtrl.getValue("addNewVehicleContainer");
            if (success) {
                addNewVehicleContainer(ajaxData);
                formCtrl.$form.find(".close-button").trigger("click");
            } else {
                formCtrl.$form.find(".close-button").trigger("click");
                formCtrl.goToStep(formCtrl.lastStep);
            }
        },
    },
    validateFields: function () {
        const nameRegex = /(?:^|\W)\s*(\W+)\s*(?:$|\W)/g;
        const plateRegex = /^[a-zA-Z0-9]+$/;
        const nameInput = this.getInputValue("name");
        let plateValue = this.getInputValue("plateNo");

        if (!nameInput) {
            this.elements.$saveButton.attr("disabled", "true");
            return;
        }

        const nameFieldValue = this.setField("name", nameInput.replace(nameRegex, ""));

        plateValue = this.setField("plateNo", plateValue.replace(nameRegex, ""));

        let isPlateValid = true;

        if (plateValue && !plateRegex.test(plateValue)) {
            isPlateValid = false;
        } else if (plateValue && (plateValue.length === 1)) { //NOSONAR
            isPlateValid = false;
        }

        if (!nameFieldValue || !isPlateValid) {
            this.elements.$saveButton.attr("disabled", "true");
        } else {
            this.elements.$saveButton.removeAttr("disabled");
        }

        return nameFieldValue;
    },
    events: {
        onSetValue: function (_, {key, value}) {
            if (key === "dataObjectSelector") {
                const match = this.handleNewVehicleSelector(value);
                var $vehicleName = this.getFields("name");
                // clear the form after moving to the previous step
                $vehicleName.val("");
                $vehicleName.removeClass("error");
                this.getFields("plateNo").val("");
                this.elements.$saveButton.attr("disabled", "true");

                if (match.length) {
                    const matchingVehicleMsg = this.elements.$matchingVehicleMessage;
                    const messageTemplate = matchingVehicleMsg.data("message-template");

                    var message;
                    if (messageTemplate) {
                        var vehicleData = value.postData.currentRef;
                        var vehicleYMMO = "";
                        for (var i = 0; i < vehicleData.length; i++) {
                            if (vehicleData[i].refName != "Size") {
                                vehicleYMMO += " " + vehicleData[i].refValue;
                            }
                        }
                        message = messageTemplate.replace(
                            "VEHICLE_PLACEHOLDER",
                            `${vehicleYMMO}`
                        );
                    }

                    matchingVehicleMsg.html(message || "");
                    this.goToStep("matching-vehicles");
                } else if (Array.isArray(match)) {
                    this.goToStep("initial");

                    if (value.nextKey === "VEHICLE_NAME") {
                        this.$form.trigger("addNewVehicle", true);
                    }
                } else if (value.nextKey === "VEHICLE_NAME") {
                    this.goToStep("name-vin-plate");
                }
            }
        }
    },
    listeners: (self, elm) => {
        const addVehicleContainerToMyGarage = self.getValue("addVehicleContainerToMyGarage");
        const isNewVehicleForm = (self && self.formName && self.formName === "addNewVehicle");

        if ((self && "name" in self && "plateNo" in self && self.$form) || isNewVehicleForm) {

            // When the form is submitted, prevent the default action and send an AJAX request to add the new vehicle
            self.$form.on("addNewVehicle submit", function (e, isVehicleUnique) {
                e.preventDefault();
                const ajaxData = self.getValue("newVehicle");

                self.goToStep("loading");

                if (!isNewVehicleForm || (isNewVehicleForm && !isVehicleUnique)) {

                    // Set the values for the name, VIN, and plate number fields
                    ajaxData.name = self.getValue("name");
                    ajaxData.vin = self.getValue("vin");
                    ajaxData.plateNo = self.getValue("plateNo");
                }

                // Send an AJAX request to add the vehicle
                (self.validateFields() || isVehicleUnique) && $.ajax({
                    type: "POST",
                    contentType: "application/json",
                    url: Urls.addVehicleAfterExistanceCheck,
                    data: JSON.stringify(ajaxData),
                    success: (response) => {
                        ajaxData.id = response.params.id;
                        ajaxData.action = "save";

                        // if the vehicle is unique no need to send another AJAX request
                        // to add or edit the plate number, VIN, and name fields
                        if (isVehicleUnique && ajaxData.id) {
                            addVehicleContainerToMyGarage(self, true, ajaxData);
                        } else {
                            // Send another AJAX request to add or edit the plate number, VIN, and name fields
                            $.ajax({
                                type: "POST",
                                contentType: "application/json",
                                url: Urls.setPlateNoVinName,
                                data: JSON.stringify(ajaxData),
                                success: (res) => {
                                    let success;

                                    if (typeof res !== "object" || Array.isArray(res)) {
                                        success = false;
                                    } else {
                                        // Validating ajax response
                                        success = [
                                            Object.keys(res).length,
                                            res.name && res.name.success,
                                            res.plateNo,
                                            res.vin
                                        ].every(item => item);
                                    }

                                    if (success) {
                                        addVehicleContainerToMyGarage(self, success, ajaxData);
                                    } else if (!res.name || !res.name.success) {
                                        var $vehicleName = self.getFields("name");

                                        // display an error message only when the vehicle name input is not empty
                                        if ($vehicleName.val().length > 0) {
                                            $vehicleName.addClass("error");
                                            self.goToStep("duplicated-name");
                                        } else {
                                            self.goToStep("name-vin-plate");
                                        }
                                    } 
                                }
                            });
                        }
                    }
                });
            });
        }

        // When the "Take Me to the Vehicle" button is clicked, find the existing vehicle that matches the new vehicle and scroll to the vehicle
        if (elm && elm.$takeMeToVehicleBtn && elm.$addNewVehicleBtn) {
            elm.$takeMeToVehicleBtn.on("click", function () {
                const newVehicle = self.getValue("newVehicle");
    
                var $existingVehicle = self.$carContainer.filter(function () {
                    const $this = $(this);
                    const isYearMatch = ($this.data("vehicle-year") === newVehicle.year);
                    const isMakeMatch = isYearMatch && ($this.data("vehicle-make") === newVehicle.make);
                    const isModelMatch = isMakeMatch && ($this.data("vehicle-model") === newVehicle.model);
                    const isOptionMatch = isModelMatch && ($this.data("vehicle-option") === newVehicle.fitment);
    
                    return isOptionMatch;
                });
    
                self.$form.find(".close-button").trigger("click");
                self.getValue("takeMeToTheVehicleAction")($existingVehicle);
            });

            // When the "Add New Vehicle" button is clicked, update the value for isMatchingVehicleResolved and go back to the initial step
            elm.$addNewVehicleBtn.on("click", function () {
                const newVehicle = self.getValue("newVehicle");

                if (newVehicle) {
                    self.setValue("isMatchingVehicleResolved", newVehicle);

                    if (newVehicle.Size) {
                        self.goToStep("name-vin-plate");
                    } else {
                        self.goToStep("initial");
                    }
                }
            });

            let $duplicateVehicleForm = $("form[modal-step='matching-vehicles, name-vin-plate, loading, duplicated-name']");

            elm.$saveButton.on("click", function (e) {
                if ($duplicateVehicleForm.is(":visible")) {
                    let $vehicleNameInput = $duplicateVehicleForm.find(".vehicle-name");

                    if ($vehicleNameInput.val()) {
                        return;
                    } else {
                        e.preventDefault();
                        $vehicleNameInput.addClass("error");
                        $(".vehicle-name-error").removeClass("hide");
                    }
                }
            });

            let $licensePlate = $duplicateVehicleForm.find(".licensePlate");

            $licensePlate.on("blur", function () {
                let $licensePlateError = $(".license-plate-error");

                if ($licensePlate.val() && $licensePlate.val().length === 1) {
                    $licensePlate.addClass("error");
                    $licensePlateError.removeClass("hide");
                } else {
                    $licensePlate.removeClass("error");
                    $licensePlateError.addClass("hide");
                }
            });
        }
    }
};
