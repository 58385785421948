"use strict";

var _ = require("lodash");

var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(":visible").map(function () {
        return $(this).val();
    });
    return _(requiredValues).includes("");
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }

    // check if edit account info on checkout
    if ($continue.attr("forced-disabled") == "true") {
        // skip validation
        return;
    }

    if (!hasEmptyRequired()) {
        if (validator.form()) {
            $continue.removeAttr("disabled");
        }
    } else {
        $continue.attr("disabled", "disabled");
    }
};

var verifyVehicleOnForm = function () {
    //check if vehicle has been selected
    var $form = $("form[id$=\"shippingAddress\"] "); //NOSONAR
    if ($form.validate().checkForm() && $(".vehicle-info")[0].innerHTML != "") {
        return false;
    }
    return true;
};

var validateEl = function () {
    if ($(this).hasClass("postal-code")) {
        var postalCode = $(this).val();
        $(this).val(postalCode.toUpperCase());
    }
    if ($(this).val() === "") {
        $continue.attr("disabled", "disabled");
    } else if (verifyVehicleOnForm()) {
        $continue.attr("disabled", "disabled");
    } else {
        // check if edit account info on checkout
        if ($continue.attr("forced-disabled") == "true") {
            // skip validation
            return;
        }

        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired()) {
            $continue.removeAttr("disabled");
        } else {
            $continue.attr("disabled", "disabled");
        }
    }
};

/**
 * @function
 * @description Adds the action key-value pair of the button element to the form as a hidden input element
 * It is used for SGJC forms
 * @param {jQuery} $button - button element that was clicked
 * @param {jQuery} $form - SGJC form
 * @return {Boolean} - true if the hidden input element was added or already present, otherwise false
 */
var addActionAttrToForm = function ($button, $form) { //NOSONAR
    if (!$button || !$form) {
        return false;
    }

    var $buttonName = $button.attr("name");
    var $buttonValue = $button.val();

    if (!$("input[name=" + $buttonName + "]").length) {
        $("<input />").attr("type", "hidden")
            .attr("name", $buttonName)
            .attr("value", $buttonValue)
            .appendTo($form);
    }

    return true;
};

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error("Missing form and continue action selectors.");
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $(".required", $form).find(":input");
    validateForm();
    // start listening
    $requiredInputs.on("change", validateEl);
    $requiredInputs.filter("input").on("keyup", _.debounce(validateEl, 200));
};

/**
 * Checks if the capture form trigger is enabled.
 * 
 * @returns {boolean} True if the capture form trigger is enabled, otherwise false.
 */
var isCaptureFormTriggerEnabled = function () {
    var $captureFormTriggerEl = $("input[name='captureFormTrigger']");
    return $captureFormTriggerEl.length && $captureFormTriggerEl.val() === "true";
};

/**
 * Initializes the capture form trigger.
 *
 * @param {string} formSelector - The CSS selector for the form element.
 */
var initializeCaptureFormTrigger = function (formSelector) {
    var $button = getSubmitButtonFromForm(formSelector);

    if (isCaptureFormTriggerEnabled() && $(formSelector).length
        && $button && $button.length) {
        $(document).on("submit", formSelector, function (e) {
            e.preventDefault();

            if (addActionAttrToForm($button, e.currentTarget)) {
                // send the form to D365 
                if (typeof MsCrmMkt === "object" && Object.prototype.hasOwnProperty.call(MsCrmMkt, "MsCrmFormLoader")) {
                    MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm($(formSelector)).finally(function () {
                        e.currentTarget.submit();
                    });
                } else {
                    e.currentTarget.submit();
                }
            }
        });
    }
};

/**
 * Retrieves the submit button from a given form element.
 * 
 * @param {HTMLFormElement} form - The form element or a jQuery object representing the form.
 * @returns {JQuery|null} - A jQuery object containing the submit button if found, otherwise null.
 */
var getSubmitButtonFromForm = function (form) {
    var $form = $(form);

    if ($form.length) {
        return $form.find("button[type=\"submit\"]");
    }

    return null;
};

/**
 * Prepares the form submission for Real-Time Marketing (RTM) by attaching an event listener 
 * to the document that listens for the "sentCaptureForm" event. When the event is triggered, 
 * it retrieves the form from the event details, finds the submit button, and if the button 
 * exists and the action attribute is successfully added to the form, it submits the form.
 * RTM is provided by the real-time capture script.
 * 
 * @function prepareFormSubmissionForRTM
 * @returns {void}
 */
var prepareFormSubmissionForRTM = function () {
    if (typeof d365mktformcapture === "function") {
        $(document).on("sentCaptureForm", function (e) {
            var form = e.detail;

            if (form) {
                var $button = getSubmitButtonFromForm(form);
                
                if ($button && $button.length && addActionAttrToForm($button, form)) {
                    form.submit();
                }
            }
        });
    }
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
exports.initializeCaptureFormTrigger = initializeCaptureFormTrigger;
exports.prepareFormSubmissionForRTM = prepareFormSubmissionForRTM;
