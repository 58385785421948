

exports.init = function () {
    fillInsideDataObject();

    var chatLoaded = false;
    var chatRequested = false;
    var chatAvailable = false; // eslint-disable-line
    var chatButton    = $(".openLiveChatButton");
    chatButton.addClass("disabled");

    function onChatAvailable(available) { // The function that will be bound to Inside “chatavailable” event
        chatLoaded = true;
        chatAvailable = available; //NOSONAR
        if (available) {
            $("#chatonlinenow").show();
            // Handle chat available
            // You can for example change the link text to "Chat Online"
            // Handle chat requested
            if (chatRequested) {
                insideFrontInterface.openChatPane(); // eslint-disable-line
            }
        }
        else
        {
            $("#chatonlinenow").hide();
            // Handle chat unavailable
            // You can for example change the link text to "Chat Offline"
        }
        chatRequested = false;
    }

    function initOpenChatButton() {

        if (typeof (_insideGraph) == "undefined") {
            // wait until inside is ready. Can perhaps add a function like showing loading popup
            setTimeout(initOpenChatButton, 100);
            return;
        }

        /* eslint-disable */
        _insideGraph.bind("chatavailable", onChatAvailable); // Binding the onChatAvailable function
        /* eslint-enable */

        chatButton.removeClass("disabled");
    }

    initOpenChatButton();
    $(document).on("click touchstart", ".openLiveChatButton, .orderSummaryLiveChat", function () {
        if (chatLoaded) {
            insideFrontInterface.openChatPane(); // eslint-disable-line
            chatRequested = false;
        }
        else {
            chatRequested = true; // chatRequested variable set to true so the
            // Chat Pane will open automatically when it's ready
        }


    });
};

function fillInsideDataObject() {
    var $selinfoHiddenInput      = $("#selinfo"),
        $customerDataHiddenInput = $("#customerdataobj"),
        $cartTotal               = $("#cartTotal"),
        $cartDetails             = $("#cartDetails");

    if ($customerDataHiddenInput && $customerDataHiddenInput.data("customerdataobj")) {
        window.insideDataCustomerObj = $customerDataHiddenInput.data("customerdataobj");
    }

    if ($selinfoHiddenInput && $selinfoHiddenInput.data("selinfo")) {
        window.insideDataSelInfoObj = $selinfoHiddenInput.data("selinfo");
    }

    if ($cartTotal && $cartTotal.data("cart-total")) {
        window.cartTotal = $cartTotal.data("cart-total");
    }

    if ($cartDetails && $cartDetails.data("cart-details")) {
        window.cartDetails = $cartDetails.data("cart-details");
    }

    if (getData("answers")) {
        window.answers = getData("answers");
    }

    if (getData("wheelSize")) {
        if (!window.insideDataSelInfoObj) {
            window.insideDataSelInfoObj = {};
        }
        window.insideDataSelInfoObj.wheelSize = getData("wheelSize");
    }
}

function saveData(name, value) {
    var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/; SameSite=Lax;";
}

function getData(name) {
    var cookieName = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return "";
}

function deleteData(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax;";

    if (window._insideData && window._insideData[name]) {
        delete window._insideData[name];
    }
}

exports.getData    = getData;
exports.saveData   = saveData;
exports.deleteData = deleteData;
