"use strict";

var dialog                  = require("./dialog"),
    validator               = require("./validator"),
    ics                     = require("./ics"),
    fileSaver               = require("./FileSaver"),
    orderConfirmation       = require("./pages/orderconfirmation"),
    { storeLocatorHelpers } = require("./store-locator/store-locator-helpers");


var selfserviceentrypoint = {
    init: function () {
        if (!SitePreferences.SELF_SERVICE_ENABLED) { // eslint-disable-line
            return;
        }

        //VALIDATOR
        var validateCheckoutCustomerNames = function (value) {
            var isValid = validator.regex.flName.test($.trim(value));
            return isValid;
        };

        $.validator.addMethod("survey_lastName", validateCheckoutCustomerNames, Resources.CHECKOUT_VALIDATE_FLNAME); // eslint-disable-line

        orderConfirmation.init();

        $(".print-link").on("click", function (e) {
            $.get($(e.target).data("url"), function (data, status) {
                if (status == "success") {
                    var originalContents = document.body.innerHTML;
                    document.body.innerHTML = data;
                    window.print();
                    document.body.innerHTML = originalContents;
                    selfserviceentrypoint.init();
                }
            });
        });

        checkLastNameOrderNumberInputs();
        $("#lastNameLandingInput").change(checkLastNameOrderNumberInputs);
        $("#orderNumberLandingInput").change(checkLastNameOrderNumberInputs);

        $("#lastNameLandingInput").blur(function () {
            var $lastNameError = $("#lastNameErrorMsg");
            var hasLastName = $(this).val().trim() !== "";
            var hasHide = $lastNameError.hasClass("hide");

            if (!hasLastName) {
                $lastNameError.removeClass("hide");
                $(this).addClass("error");
            } else if (!hasHide) {
                $lastNameError.addClass("hide");
                $(this).removeClass("error");
            }
        });

        $("#orderNumberLandingInput").blur(function () {
            var $orderNumberError = $("#orderNumberErrorMsg");
            var hasOrderNo = $(this).val().trim() !== "";
            var hasHide = $orderNumberError.hasClass("hide");

            if (!hasOrderNo) {
                $orderNumberError.removeClass("hide");
                $(this).addClass("error");
            } else if (!hasHide) {
                $orderNumberError.addClass("hide");
                $(this).removeClass("error");
            }
        });

        $("#findMyAppointmentButton").on("click", function (e) {
            e.preventDefault();

            try {
                if (typeof grecaptcha === "undefined") {
                    submitFindApptForm();
                } else {
                    if (!$(e.target).attr("disabled")) {
                        grecaptcha.execute(); // eslint-disable-line
                    }
                }
            } catch (error) {
                submitFindApptForm();
            }
        });

        $("#cancelAppointmentBtn").on("click", function () {
            var cancelPopupUrl = Urls.cancelAppointmentPopup; // eslint-disable-line

            openCancelPopup(cancelPopupUrl);
        });

        var $submitCancelationSurveyBtn = $("#submitCancelationSurveyBtn");

        // Enable survey submit button after choose a radio button
        $("input[name='cancelationSurveyRadio']").on("click", function () {
            $submitCancelationSurveyBtn.attr("disabled", false);
        });

        $submitCancelationSurveyBtn.on("click", function () {
            // TODO: track survey via Google Tag Manager
            window.location.replace(Urls.showFeedbackMessage); // eslint-disable-line
        });

        var $saveToCalendarBtn = $("#saveToCalendarBtn");
        $saveToCalendarBtn.on("click", addToCalendar);

        $("#printBtn").on("click", function () {
            $.get(Urls.printConfirmation, function (data, status) { // eslint-disable-line
                if (status == "success") {
                    var originalContents = document.body.innerHTML;
                    document.body.innerHTML = data;
                    window.print();
                    document.body.innerHTML = originalContents;
                    $saveToCalendarBtn.on("click", addToCalendar);
                }
            });
        });

        function submitFindApptForm() {
            var $findApptmentForm = $("#findMyAppointmentForm");
            $(".selfservice-form-error").addClass("hide");

            $.post(
                $findApptmentForm.prop("action"),
                $findApptmentForm.serialize(),
                function (response) {
                    if (!response.error && response.online) {
                        var appointment = response.appointment;
                        if (!appointment.tireLodge && appointment.serviceType == "tyre_change") {
                            window.dataLayer.push({
                                "event"         : "survey-1-0-FindMyAppointment",
                                "eventAction"   : "Find my appointment - click",
                                "eventLabel"    : "Winter tire change",
                                "eventCategory" : "Survey - Page 1.0 - Start page",
                            });

                            var urlPopupTemplate = $("#findMyAppointmentButton").attr("href");
                            openSeasonTirePopup(urlPopupTemplate);

                            if (typeof grecaptcha !== "undefined") {
                                grecaptcha.reset(); // eslint-disable-line
                            }
                        } else {
                            window.dataLayer.push({
                                "event"         : "survey-1-0-FindMyAppointment",
                                "eventAction"   : "Find my appointment - click",
                                "eventLabel"    : "New tire installation",
                                "eventCategory" : "Survey - Page 1.0 - Start page",
                            });

                            $.post(
                                Urls.fetchLocationWorkOrderData, // eslint-disable-line
                                null,
                                function (responseFetchLocationWorkOrderData) {
                                    if (responseFetchLocationWorkOrderData.success) {
                                        window.location.replace(Urls.showAppointmentDashboard); // eslint-disable-line
                                    } else {
                                        $("#appointmentUnavailable").removeClass("hide");
                                    }
                                }
                            );
                        }
                    } else {
                        if (typeof grecaptcha !== "undefined") {
                            grecaptcha.reset(); // eslint-disable-line
                        }

                        if (response.online == false) {
                            window.dataLayer.push({
                                "event": "survey-1-5-Landing",
                                "eventCategory": "Survey - Page 1.5 - We’re unable to modify your appointment online",
                                "eventAction": "Lands on Page 1.5",
                                "eventLabel": $findApptmentForm.prop("action")
                            });


                            $("#offRampBox").removeClass("hide");
                        } else {
                            switch (response.error) {
                                case 404:
                                    window.dataLayer.push({
                                        "event": "survey-1-3-Landing",
                                        "eventCategory": "Survey - Page 1.3 - Your appointment can’t be found",
                                        "eventAction": "Lands on Page 1.3",
                                        "eventLabel": $findApptmentForm.prop("action")
                                    });

                                    $("#appointmentNotFoundErrorBox").removeClass("hide");
                                    break;
                                case 400:
                                    window.dataLayer.push({
                                        "event": "survey-1-4-Landing",
                                        "eventCategory": "Survey - Page 1.4 - Something isn’t quite right",
                                        "eventAction": "Lands on Page 1.4",
                                        "eventLabel": $findApptmentForm.prop("action")
                                    });

                                    $("#orderNumberLastNameNotMatchErrorBox").removeClass("hide");
                                    break;
                                default:
                                    window.dataLayer.push({
                                        "event"         : "survey-1-0-FindMyAppointment",
                                        "eventAction"   : "Find my appointment - click",
                                        "eventLabel"    : "Error",
                                        "eventCategory" : "Survey - Page 1.0 - Start page",
                                    });

                                    openApiFailedPopup();
                            }
                        }
                    }
                });
        }

        function openSeasonTirePopup(url) {
            var dialogContent = {
                url: url,
                options: {
                    dialogClass: "season-tire-popup",
                    open: function () {
                        window.dataLayer.push({
                            "event": "survey-1-2-Landing",
                            "eventCategory": "Survey - Page 1.2 - Are your tires stored with us?",
                            "eventAction": "Lands on Page 1.2",
                            "eventLabel": url
                        });

                        var $continueBtn = $("#continueSeasonTirePopupButton");

                        // Enable continue button after choose a radio button
                        $("input[name='tiresStored']").on("click", function (e) {
                            var eventLabel = e.target.value === "true" ? "Yes, they are" : "No, I’m bringing them";

                            window.dataLayer.push({
                                "event": "survey-1-2-RadioSelect",
                                "eventCategory": "Survey - Page 1.2 - Are your tires stored with us?",
                                "eventAction": "Radio - click",
                                "eventLabel": eventLabel
                            });


                            $continueBtn.attr("disabled", false);
                        });

                        $continueBtn.on("click", function () {
                            var radioValue = $("input[name='tiresStored']:checked").val();

                            if (radioValue) {
                                var eventLabel = radioValue === "true" ? "Yes, they are" : "No, I’m bringing them";

                                window.dataLayer.push({
                                    "event": "survey-1-2-FormSubmit",
                                    "eventCategory": "Survey - Page 1.2 - Are your tires stored with us?",
                                    "eventAction": "Form submit",
                                    "eventLabel": eventLabel
                                });

                                $.post(
                                    Urls.fetchLocationWorkOrderData, // eslint-disable-line
                                    { tiresStored: radioValue },
                                    function (response) {
                                        if (response.success) {
                                            window.location.replace(Urls.showAppointmentDashboard); // eslint-disable-line
                                        } else {
                                            $("#dialog-container").dialog("close");
                                            $("#appointmentUnavailable").removeClass("hide");
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            };

            dialog.open(dialogContent);
        }

        function openApiFailedPopup() {
            dialog.open({
                url: Urls.showApiFailedPopup, // eslint-disable-line
                options: {
                    dialogClass: "season-tire-popup survey-api-failed-popup"
                },
                callback: function () {
                    window.dataLayer.push({
                        "event": "survey-1-6-Landing",
                        "eventCategory": "Survey - Page 1.6 - Oops something went wrong",
                        "eventAction": "Lands on Page 1.6",
                        "eventLabel": Urls.showApiFailedPopup // eslint-disable-line
                    });
                }
            });
        }

        window.submitFindApptForm = submitFindApptForm;
        window.openSeasonTirePopup = openSeasonTirePopup;
        window.openApiFailedPopup = openApiFailedPopup;

        storeLocatorHelpers.executeWithGoogleMaps(storeLocatorHelpers.getGoogleMapLibTypes().GEOMETRY, function () {
            calculateDistance();
        });

        if ($(".appointment-calendar").length > 0) {

            if (appointment_calendar.startDate) { // eslint-disable-line
                var refDate = moment(appointment_calendar.startDate, "YYYY-MM-DD"); // eslint-disable-line
                calendarData.extras.currentWeek = Math.floor((((refDate.date() + refDate.startOf("month").weekday()) - 1) / (weeksInMonth(refDate) * 7)) * weeksInMonth(refDate));
                calendarData.startWithMonth = moment(appointment_calendar.startDate); // eslint-disable-line
            }

            if (selectedAppointment.startDate) { // eslint-disable-line
                $("#SecureNewAppointmentBtn").removeAttr("disabled");
                $("#SecureNewAppointmentBtn").data("request", selectedAppointment); // eslint-disable-line
            }

            if ($("#iam-sl-appointments-template-update").length > 0) {
                appointmentsTemplate = Handlebars.compile($("#iam-sl-appointments-template-update").html()); // eslint-disable-line
            }

            $(".appointment-calendar").clndr(calendarData);
        }
    }
};

function openCancelPopup(url) {
    var dialogContent = {
        url: url,
        options: {
            dialogClass: "season-tire-popup popup-cancel-confirmation"
        },
        callback: function () {
            var eventLabel = appointment_dashboard.newInstall ? "New tire installation without a deposit" : "Winter tire change"; // eslint-disable-line

            if ($("#cancel-refund").length > 0) {
                window.dataLayer.push({
                    "event": "survey-2-2-Landing",
                    "eventCategory": "Survey - Page 2.2 - Cancel your appointment pop-up with a depost",
                    "eventAction": "Lands on Page 2.2",
                    "eventLabel": "New tire installation with a deposit"
                });
            } else {
                window.dataLayer.push({
                    "event"         : "survey-2-0-CancelYourAppointment",
                    "eventAction"   : "Cancel your appointment - click",
                    "eventLabel"    : eventLabel,
                    "eventCategory" : "Survey - Page 2.0 - Order summary",
                });
            }

            window.dataLayer.push({
                "event": "survey-2-1-Landing",
                "eventCategory": "Survey - Page 2.1 - Cancel your appointment pop-up",
                "eventAction": "Lands on Page 2.1",
                "eventLabel": url
            });
        }
    };

    dialog.open(dialogContent);
}

function checkLastNameOrderNumberInputs() {
    var $lastNameInput = $("#lastNameLandingInput");
    var $orderNumberInput = $("#orderNumberLandingInput");

    if ($lastNameInput.length > 0 && $orderNumberInput.length > 0) {
        var hasLastName = $lastNameInput.val().trim() !== "" && $lastNameInput.valid();
        var hasOrderNumber = $orderNumberInput.val().trim() !== "" && $lastNameInput.valid();
        var $findButton = $("#findMyAppointmentButton");

        if (hasLastName && hasOrderNumber) {
            $findButton.attr("disabled", false);
        } else {
            $findButton.attr("disabled", "disabled");
        }
    }
}

function addToCalendar() {
    var title       = Resources.ORDER_CALENDAR_TITLE, // eslint-disable-line
        description = $(".calendar-description").html().replace(/\n/g, ""),
        filename    = Resources.ORDER_CALENDAR_FILENAME, // eslint-disable-line
        place       = $(".calendar-location").html().replace(/\n/g, ""),
        date        = $(".calendar-date").html(),
        endDate     = moment(new Date(date)).add(90, "m").format("LLLL"); // eslint-disable-line

    var calEntry = ics();
    calEntry.addEvent(title, description, place, date, endDate);

    var calendarBlob = new Blob([calEntry.calendar()], {type : "text/calendar"});
    fileSaver.saveAs(calendarBlob, filename+".ics");
}

function calculateDistance() {
    var $storeLatLng = $("#storeLatLng");

    if ($storeLatLng.val()) {
        var $headerLocation = $("#headerNavLocationCTA");
        var userLat = $headerLocation.data("latitude");
        var userLng = $headerLocation.data("longitude");
        var userLatLng = new google.maps.LatLng(userLat, userLng); // eslint-disable-line

        var storeLatLng = JSON.parse($storeLatLng.val());
        var gStoreLatLng = new google.maps.LatLng(storeLatLng.lat, storeLatLng.lng); // eslint-disable-line

        var distance = google.maps.geometry.spherical.computeDistanceBetween(userLatLng, gStoreLatLng); // eslint-disable-line
        distance = parseFloat(distance/1000).toFixed(1);
        $("#distanceStoreUser").text(distance);
    }
}

var RESPONSIVE_DESKTOP_MINIMUM_WIDTH = 768;         // width in pixels that defines the minimum desktop size before switching to mobile

var clndrTemplate =
    "<div class='loader'></div>" +
    "<div class='clndr-controls month'>" +
        "<div class='clndr-control-button'>" +
            "<span class='clndr-previous-button'>previous</span>" +
        "</div>" +
        "<div class='month'><span><%= month %></span> <%= year %></div>" +
        "<div class='clndr-control-button rightalign'>" +
            "<span class='clndr-next-button'>next</span>" +
        "</div>" +
    "</div>" +
    "<div class='clndr-controls week'>" +
        "<div class='month'><span><%= month %></span> <%= year %></div>" +
    "</div>" +
    "<table class='clndr-table' border='0' cellspacing='0' cellpadding='0'>" +
        "<thead>" +
            "<tr class='header-days'>" +
            "<td class='empty-cell'></td>" +
            "<% for(var i = 0; i < daysOfTheWeek.length; i++) { %>" +
                "<td class='header-day'><%= daysOfTheWeek[i] %></td>" +
            "<% } %>" +
            "<td class='empty-cell'></td>" +
            "</tr>" +
        "</thead>" +
        "<tbody class='month-view'>" +
        "<% for(var i = 0; i < numberOfRows; i++){ %>" +
            "<tr class='<%= i %>'>" +
            "<% for(var j = 0; j < 7; j++){ %>" +
            "<% var d = j + i * 7; %>" +
                "<td class='<%= days[d].classes %> <%= days[d].day === 1 ? 'first-day' : 'normal-day'%>'>" +
                    "<div class='day-contents'>" +
                        "<p class='w-day'><%= days[d].day %></p>" +
                        "<% if (days[d].day === 1) { %>" +
                            "<p class='month-day'><%= moment(days[d].date).format('MMM') %></p>" +
                        "<% } %>" +
                    "</div>" +
                "</td>" +
            "<% } %>" +
            "</tr>" +
        "<% } %>" +
        "</tbody>" +
        "<tbody class='week-view'>" +
            "<tr>" +
                "<td class='controll-wrapper'><span class='clndr-previous-button previous-btn'>previous</span></td>" +
                "<% for(var i = extras.currentWeek * 7; i < extras.currentWeek * 7 + 7; i++) { %>" +
                    "<td class='<%= days[i].classes %> <%= days[i].day === 1 ? 'first-day' : 'normal-day'%>'>" +
                        "<div class='day-contents'>" +
                            "<p class='w-day'><%= days[i].day %></p>" +
                            "<% if (days[i].day === 1) { %>" +
                                    "<p class='month-day'><%= moment(days[i].date).format('MMM') %></p>" +
                            "<% } %>" +
                        "</div>" +
                    "</td>" +
                "<% } %>" +
                "<td class='controll-wrapper'><span class='clndr-next-button next-btn'>next</span></td>" +
            "</tr>" +
        "</tbody>" +
    "</table>";

function weeksInMonth(month) {
    return Math.floor((month.daysInMonth() + moment(month).startOf("month").weekday()) / 7); // eslint-disable-line
}

function storeSearchedMonthOnSession(startDate) {
    var tempSessionStorage = null,
        startDateWeekStart = null;

    try {
        tempSessionStorage = JSON.parse(window.sessionStorage.getItem("searchedWeeks"));
        startDateWeekStart = moment(startDate).startOf("month").format("YYYYMMDD"); // eslint-disable-line
    } catch (e) {
        // the variables are already set as null, so do nothing
    }

    if (tempSessionStorage == null) {
        tempSessionStorage = [];
    } else if (tempSessionStorage.includes(startDateWeekStart)) {
        return false;
    }

    tempSessionStorage.push(startDateWeekStart);
    window.sessionStorage.setItem("searchedWeeks", JSON.stringify(tempSessionStorage));

    return true;
}

function storeSearchedWeeksOnSession(startDate) { // eslint-disable-line
    var tempSessionStorage = JSON.parse(window.sessionStorage.getItem("searchedWeeks"));
    var startDateWeekStart = moment(startDate).day(0).format("YYYYMMDD"); // eslint-disable-line
    if (tempSessionStorage == null) {
        tempSessionStorage = [];
    } else if (tempSessionStorage.includes(startDateWeekStart)) {
        return false;
    }
    tempSessionStorage.push(startDateWeekStart);
    window.sessionStorage.setItem("searchedWeeks", JSON.stringify(tempSessionStorage));
    return true;
}

/**
* Updates the available slots based on the provided data, start date, and number of days
* @param {Object} data - the data containing events and slots information
* @param {string} newStartDate - the new start date for the slots
* @param {number} numDays - the number of days for which slots need to be updated
*/
function updateSlotsByData(data, newStartDate, numDays) { // eslint-disable-line
    var hasSlot       = false;
    var targetEvent   = false;
    var selectedEvent = {
        slots: []
    };

    for (var eI in data) {
        var event   = data[eI];

        if (event.date == appointment_calendar.startDate) { // eslint-disable-line
            targetEvent = eI;
            for (var sI in event.slots) {
                var slot = event.slots[sI];

                // just consider if the slot evaluated is from the some date from the original appointment
                // otherwise, the time slot may show in incorrect date
                if (slot.date == getStartDate() && slot.time == appointment_calendar.startTime) { // eslint-disable-line
                    slot.active   = true;
                    hasSlot       = true;
                    selectedEvent = event;
                }
            }
        }
    }

    if (!hasSlot) {
        selectedEvent = targetEvent !== false ? data[targetEvent] : selectedEvent;

        // in first render of the calendar, show the available slot
        selectedEvent.slots = addBookedSlotToEventSlots(selectedEvent.date, selectedEvent.slots, false);
    }

    // only show the slots if it's the first render of the calendar
    // to avoid them from showing when there's navigation between the weeks
    if (selectedEvent.slots.length > 0 && appointmentIsInDisplayedWeek) {
        $(".calendar-day-" + appointment_calendar.startDate).addClass("active-event"); // eslint-disable-line
        $(".appointment-date").html(appointmentsTemplate({"appointments": selectedEvent.slots})).show();
        $(".appointment-date .appointments").show();
    }
}

/**
* Retrieves the date that should be used as the start of the calendar
* @returns {string} the start date for the appointment
*/
function getStartDate() {
    return appointment_calendar.isDateUpdated === "true" ? appointment_calendar.originalDate : appointment_calendar.startDate; // eslint-disable-line
}

/**
* Checks if the given appointment data matches the booked one
* @param {Object} data - appointment data containing time and date
* @returns {boolean} true if the appointment date matches the booked one, otherwise false
*/
function isAppointmentDateTheBookedOne(data) {
    return data["time"] === appointment_calendar.startTime && data["date"] === getStartDate(); // eslint-disable-line
}

/**
* Checks if the event date matches the start date, or if it includes the start date in case of another date click
* If it doesn't match, it means that the user clicked on another date from the calendar
* And the original appointment slot should not be added to the slots array
* @param {string} eventDate - the date of the event
* @param {string} startDate - the start date to compare with the event date
* @param {boolean} isAnotherDateClick - indicates if it's another date click
* @returns {boolean} true if the event date matches the start date, or includes the start date in case of another date click; otherwise, false
*/
function isSameDate(eventDate, startDate, isAnotherDateClick) {
    if (isAnotherDateClick) {
        return eventDate.includes(startDate);
    }

    return eventDate === startDate;
}

/**
* Returns the first slot that is after the time of the original appointment
* @param {Object} event - the event object to compare
* @param {string} startDate - the start date of the appointment in "YYYY-MM-DD" format
* @returns {boolean} true if the event is later than the appointment start date and time, otherwise false
*/
function isEventLaterThanAppointment(event, startDate) {
    if (event.time !== appointment_calendar.startTime) { // eslint-disable-line
        return event.date === startDate && new Date(event.date + " " + event.time) > new Date(startDate + " " + appointment_calendar.startTime); // eslint-disable-line
    }

    return false;
}

/**
* Adds the original appointment slot to the event slots array if not already present
* @param {Date} eventDate - the date of the event
* @param {Array} slots - array of event slots
* @param {boolean} isAnotherDateClick - flag indicating if another date was clicked
* @returns {Array} - the updated event slots array with the booked slot added if necessary
*/
function addBookedSlotToEventSlots(eventDate, slots, isAnotherDateClick) {
    var startDate = getStartDate();
    var hasSlot = slots.some(function(slot) { return slot.date === startDate && slot.time === appointment_calendar.startTime}); // eslint-disable-line

    // if the original appointment slot is not in the slots array, add it
    if (!hasSlot) {
        var firstLaterSlot = slots.find(function (e) { return isEventLaterThanAppointment(e, startDate)}); // eslint-disable-line
        var firstLaterSlotPosition   = firstLaterSlot ? slots.indexOf(firstLaterSlot) : false;
        var eventData       = {
            date   : startDate,
            time   : appointment_calendar.startTime, // eslint-disable-line
            active : true
        };

        // if the first later slot is found, add the original appointment slot before it
        // otherwise, if the event date is the same as the start date, add the original appointment slot at the end
        if (firstLaterSlotPosition !== false) {
            slots.splice(firstLaterSlotPosition, 0, eventData);
        } else if (isSameDate(eventDate, startDate, isAnotherDateClick)) {
            slots.push(eventData);
        }
    }

    return slots;
}

function updateWeekDaysEvents(calendar) {
    var $dateAppointments = $(".appointment-date .appointments");
    var $calendarLoader = $(".appointment-calendar .loader");
    $dateAppointments.hide();
    $calendarLoader.show();

    var extraDays          = 0;
    var weekStartDate      = moment(calendar.month).day(7*calendar.options.extras.currentWeek).format("YYYY-MM-DD"); // eslint-disable-line
    var weekFinalDate      = moment(calendar.month).day((7*calendar.options.extras.currentWeek) + 6).format("YYYY-MM-DD"); // eslint-disable-line
    var nextMonthStartDate = moment(weekFinalDate).startOf("month").format("YYYY-MM-DD"); // eslint-disable-line
    var originalStartDate  = moment(calendar.month).startOf("month").format("YYYY-MM-DD"); // eslint-disable-line

    if (moment(weekStartDate).month() !== moment(weekFinalDate).month() && moment(originalStartDate).month() !==  moment(weekFinalDate).month()) { // eslint-disable-line
        originalStartDate = weekStartDate;
        extraDays         = Math.abs(moment(weekStartDate).diff(moment(nextMonthStartDate), "days")); // eslint-disable-line
    }

    var newStartDate      = originalStartDate;
    var numDays           = moment(nextMonthStartDate).daysInMonth() + extraDays; // eslint-disable-line
    var storeStartDate    = moment(appointment_calendar.storeStartDate).format("YYYY-MM-DD"); // eslint-disable-line

    if (moment(storeStartDate).isBetween(newStartDate, moment(newStartDate).add(numDays, "days"), null, "[]")) { // eslint-disable-line
        var storeStartGap = extraDays ? weekStartDate : originalStartDate;
        newStartDate      = storeStartDate;
        numDays           = numDays - Math.abs(moment(newStartDate).diff(moment(storeStartGap), "days")); // eslint-disable-line
    }

    if (moment(newStartDate).isBefore(storeStartDate, null, "(]")) { // eslint-disable-line
        $calendarLoader.hide();
        updateSlotsByData(calendar.eventsThisInterval, originalStartDate);
        return;
    }

    //check if can schedule to a day before the current one
    if (appointment_calendar.tireLodge) { // eslint-disable-line
        if (moment(newStartDate).isBefore(appointment_calendar.startDate, null, "(]")) { // eslint-disable-line
            //check if between same week
            if (moment(appointment_calendar.startDate).isBetween(newStartDate, moment(newStartDate).add(numDays, "days"), null, "[]")) { // eslint-disable-line
                numDays = numDays - Math.abs(moment(newStartDate).diff(moment(appointment_calendar.startDate), "days")); // eslint-disable-line
                newStartDate = appointment_calendar.startDate; // eslint-disable-line
            } else {
                $calendarLoader.hide();
                updateSlotsByData(calendar.eventsThisInterval, originalStartDate);
                return;
            }
        }
    }

    var isNewMonth = extraDays ? storeSearchedMonthOnSession(nextMonthStartDate) : storeSearchedMonthOnSession(newStartDate);
    if (!isNewMonth) {
        $calendarLoader.hide();
        return;
    }

    $.getJSON(
        appointment_calendar.storeOpenDates, // eslint-disable-line
        {
            startDate : newStartDate,
            storeID   : appointment_calendar.storeID, // eslint-disable-line
            numDays   : numDays,
            serviceId : appointment_calendar.serviceID // eslint-disable-line
        }
    ).done(function (response) {
        if (response.success === true && response.data) {
            var events = response.data;
            var event = events.some((currentValue) => currentValue.date == appointment_calendar.startDate); // eslint-disable-line

            // if the original start date is not within the response and the service didn't have the request date updated
            // add the original start date to the response
            if (!event && appointment_calendar.isDateUpdated !== "true") { // eslint-disable-line
                events.push({
                    date  : appointment_calendar.startDate, // eslint-disable-line
                    slots : [{
                        date : appointment_calendar.startDate, // eslint-disable-line
                        time : appointment_calendar.startTime, // eslint-disable-line
                    }]
                });

            // if the original start date is not within the response and the service should show the next available date
            // get the first date available and reassign it as the startDate
            } else if (!event && appointment_calendar.isDateUpdated === "true" && events.length) { // eslint-disable-line
                // get the week from the start date
                var weekInMonth = moment(appointment_calendar.startDate).week(); // eslint-disable-line

                // set the start date to be the first available date
                appointment_calendar.startDate = events[0] && events[0].date; // eslint-disable-line
                calendar.intervalStart = moment(appointment_calendar.startDate); // eslint-disable-line

                // trigger the click in the next button the amount of difference between the weeks
                var firstAvailableDateWeekInMonth = moment(appointment_calendar.startDate).week(); // eslint-disable-line
                var differenceInWeeks = firstAvailableDateWeekInMonth - weekInMonth;

                // if it's a saturday, move the calendar to the next week
                if (differenceInWeeks) {
                    for (var i = 0; i < differenceInWeeks; i++) {
                        $(".next-btn").trigger("click");
                    }
                }
            }

            calendar.addEvents(events);

            updateSlotsByData(response.data, originalStartDate);
            appointmentIsInDisplayedWeek = false;
        }
    }).always(function () {
        $calendarLoader.hide();
    });
}

var enableOneWeekSearch      = true;
var appointmentIsInDisplayedWeek = true;
// count the number of requests to the service so that only in the first time the slots are displayed
// and in the further times, the customer needs to click on the date to see the slots
// this is to prevent the slots from showing when the weeks are changed
var WEEK_SEARCH_NUM_OF_DAYS  = 7; // eslint-disable-line
var appointmentsTemplate,
    appointmentModal,
    newAppointmentModal;

window.addEventListener("beforeunload", function () {
    window.sessionStorage.removeItem("searchedWeeks");
});

$(document).on("click", "#SecureNewAppointmentBtn", function (event) {
    event.preventDefault();

    var serialize = function (obj) {
        var str = [];

        for (var p in obj) {
            if (obj.hasOwnProperty(p)) { // eslint-disable-line
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }

        return str.join("&");
    };

    var data = $(event.target).data("request");

    window.dataLayer.push({
        "event": "survey-2-6-SecureNewAppointment",
        "eventCategory": "Survey - Page 2.6 - Modify appointment calendar",
        "eventAction": "Secure new appointment - click",
        "eventLabel": data.startDate + ", " + data.startTime + " to " + data.endTime
    });


    $.get(appointment_calendar.appointmentConfirmPopup + "?" + serialize(data), function (html) { // eslint-disable-line
        if (data) {
            window.sessionStorage.setItem("selectedAppointment", JSON.stringify(data));
            window.sessionStorage.setItem("securedTime", Date.now());
        }

        $("#appointmentSecure").remove();
        var $appointmentSecure = $(html);

        if ($appointmentSecure.length > 0) {
            window.dataLayer.push({
                "event": "survey-2-10-Landing",
                "eventCategory": "Survey - Page 2.10 - Confirm new appointment pop-up",
                "eventAction": "Lands on Page 2.10",
                "eventLabel": appointment_calendar.appointmentConfirmPopup // eslint-disable-line
            });

            appointmentModal = new Foundation.Reveal($appointmentSecure); // eslint-disable-line

            $("#appointmentExitModal").on("click", function () {
                window.dataLayer.push({
                    "event": "survey-2-10-Close",
                    "eventCategory": "Survey - Page 2.10 - Confirm new appointment pop-up",
                    "eventAction": "Close - click",
                    "eventLabel": $(this).text()
                });

                appointmentModal.close();
                appointmentModal.destroy();
            });

            appointmentModal.open();
        }
    }).fail(function () {
        //The secured appointment its not available
        showUnavaiableAppointmentPopup();
    });
});

function clearAppointment() {
    window.sessionStorage.removeItem("securedTime");
    window.sessionStorage.removeItem("selectedAppointment");
}

$(document).on("click", "#keepOriginalAppointment", function () {
    window.dataLayer.push({
        "event": "survey-2-10-KeepOriginalAppointment",
        "eventCategory": "Survey - Page 2.10 - Confirm new appointment pop-up",
        "eventAction": "Keep original appointment - click",
        "eventLabel": $(this).text()
    });

    window.location.replace(Urls.rescheduleKeep); // eslint-disable-line
});

function showUnavaiableAppointmentPopup() {
    //Open popup new to select new appointment
    var $selecteNewAppointmente = $("#selecteNewAppointment");

    if ($selecteNewAppointmente.length > 0) {
        window.dataLayer.push({
            "event": "survey-2-9-Landing",
            "eventCategory": "Survey - Page 2.9 - New appointment not available pop-up",
            "eventAction": "Lands on Page 2.9",
            "eventLabel": appointment_calendar.appointmentConfirmPopup // eslint-disable-line
        });

        newAppointmentModal = new Foundation.Reveal($selecteNewAppointmente); // eslint-disable-line
        newAppointmentModal.open();
    }
}

$(document).on("click", "#selecteNewAppointmentExitModal, #continueNewAppointment", function (e) {
    var isNewAppointment = e.target.id === "continueNewAppointment";
    var event            = isNewAppointment ? "survey-2-9-ChooseNewAppointment" : "survey-2-9-Close";
    var eventAction      = isNewAppointment ? "Choose new appointment - click" : "Close - click";

    window.dataLayer.push({
        "event": event,
        "eventCategory": "Survey - Page 2.9 - New appointment not available pop-up",
        "eventAction": eventAction,
        "eventLabel": $(this).text()
    });

    newAppointmentModal.close();
    newAppointmentModal.destroy();
});

$(document).on("click", "#confirmNewAppointment", function () {
    $(this).attr("disabled", true);
    var request = checkAppointment(); // eslint-disable-line

    if (request.slot && typeof request.slot === "object") {
        window.dataLayer.push({
            "event": "survey-2-10-ConfirmNewAppointment",
            "eventCategory": "Survey - Page 2.10 - Confirm new appointment pop-up",
            "eventAction": "Confirm new appointment - click",
            "eventLabel": request.startDate + ", " + request.startTime + " to " + request.endTime
        });

        $.post(
            appointment_calendar.rescheduleAppointment, // eslint-disable-line
            request.slot,
            function (response) {
                if (response.success) {
                    clearAppointment();

                    window.location.replace(response.ContinueURL);
                } else {
                    $(this).removeAttr("disabled");
                    appointmentModal.close();
                    appointmentModal.destroy();

                    showUnavaiableAppointmentPopup();
                }
            }
        );
    } else {
        $(this).removeAttr("disabled");
    }
});

$(document).on("click", "#survey-calendar .appointment-date .appointments span", function (event) {
    event.preventDefault();
    var data = $(this).data(),
        $appointmentDate = $(".appointment-date"),
        $appointmentDateTime = $(".appointment-date-time"),
        $appointmentButtonsBox = $(".appointment-buttons-box");

    $appointmentDate.find(".appointments span").removeClass("appointment-active");
    $(this).addClass("appointment-active");
    $appointmentDateTime.find(".appointment-details").text($(".active-event").data("datatime"));
    $appointmentDateTime.find(".appointment-time").text($(this).text());
    $appointmentDateTime.show();
    $appointmentButtonsBox.addClass("with-appointment-date-time");
    $(".appointment-error-message").addClass("hide");

    if ($(".active-event span").length > 0) {
        $(".active-event span").remove();
    }

    window.dataLayer.push({
        "event": "survey-2-6-AppointmentTimeSelect",
        "eventCategory": "Survey - Page 2.6 - Modify appointment calendar",
        "eventAction": "Appointment time select",
        "eventLabel": data["time"]
    });

    if (isAppointmentDateTheBookedOne(data)) {
        $("#SecureNewAppointmentBtn").attr("disabled", true);
    } else {
        $("#SecureNewAppointmentBtn").data("request", {
            locationId : data["locationid"],
            startDate  : data["date"],
            startTime  : data["time"],
            endTime    : data["etime"],
            slot       : data["slot"],
            employeeId : data["employeeid"],
            serviceId  : appointment_calendar.serviceID, // eslint-disable-line
            creation_emp_id : appointment_calendar.creationEmployeeID // eslint-disable-line
        });

        $("#SecureNewAppointmentBtn").attr("disabled", false);
    }
});

var calendarData = {
    daysOfTheWeek: $(window).width() >= RESPONSIVE_DESKTOP_MINIMUM_WIDTH ? ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] : ["S", "M", "T", "W", "T", "F", "S"],
    events: [],
    template: clndrTemplate,
    weekOffset: 0,
    extras: {
        currentWeek: 1,
        activeTab: "weekTab"
    },
    showAdjacentMonths: true,
    clickEvents: {
        click: function (target) {
            if ($(target.element).hasClass("event") && !$(target.element).hasClass("active-event")) {
                window.dataLayer.push({
                    "event": "survey-2-6-AppointmentDateSelect",
                    "eventCategory": "Survey - Page 2.6 - Modify appointment calendar",
                    "eventAction": "Appointment date select",
                    "eventLabel": target.date.toString()
                });

                $(".appointment-calendar .loader").show();
                $(".appointment-calendar .event span").remove();

                $.each($(".appointment-calendar .event"), function () {
                    $(this).removeClass("active-event");
                });

                $(target.element).addClass("active-event");
                var targetClass = "."+target.element.classList.toString().split(" ").filter(function (r) { return r.indexOf("calendar-day-") == 0; })[0];
                $(targetClass).addClass("active-event");


                var slots = [];
                var targetDates = [];
                for (var eI in target.events) {
                    slots = slots.concat(target.events[eI].slots.filter(currentItem => !slots.some(currentSlot => currentSlot.date == currentItem.date && currentSlot.time == currentItem.time)));
                    targetDates.push(target.events[eI].date);
                }

                if (slots && slots.length > 0) {
                    // whenever a date is clicked, check if it's the date from the original booking date and time to show such slot as selected
                    slots = addBookedSlotToEventSlots(targetDates, slots, true);

                    $(".appointment-date").html(appointmentsTemplate({"appointments": slots})).show();
                } else {
                    $(".appointment-date").hide();

                    if (data.failed === true && appointment_calendar.appointmentEnhancementActive === true) { // eslint-disable-line
                        $(".no-available-appointments").html("<p>" + appointment_calendar.appointmentEnhancementInfoNotAvailable + "</p>").removeClass("hide").show(); // eslint-disable-line
                    } else {
                        $(".no-available-appointments").removeClass("hide").show();
                    }
                }

                $(".appointment-calendar .loader").hide();
                $("#SecureNewAppointmentBtn").attr("disabled", true);
                $(".appointment-error-message").addClass("hide");
            }
        }
    },
    doneRendering : function () {
        var calendar = this;

        function showTemplate(templateToShow) {
            var $monthView      = $(".month-view"),
                $weekView       = $(".week-view"),
                $monthControl   = $(".clndr-controls.month"),
                $weekControl    = $(".clndr-controls.week"),
                $monthTab       = $(".month-tab"),
                $weekTab        = $(".week-tab"),
                $emptyCell      = $(".empty-cell");

            if (templateToShow === "week") {
                $monthTab.removeClass("active");
                $weekTab.addClass("active");
                $monthView.addClass("hide");
                $weekView.removeClass("hide");
                $monthControl.addClass("hide");
                $weekControl.removeClass("hide");
                $emptyCell.removeClass("hide");
            } else {
                $monthTab.addClass("active");
                $weekTab.removeClass("active");
                $monthView.removeClass("hide");
                $weekView.addClass("hide");
                $monthControl.removeClass("hide");
                $weekControl.addClass("hide");
                $emptyCell.addClass("hide");
            }
        }

        $(document).on("click", ".clndr-tabs .tab", function (e) {
            e.preventDefault();
            var template        = $(this).data("template");
            $(this).addClass("active").siblings().removeClass("active");

            if (template === "week") {
                showTemplate(template);
                calendar.options.extras.activeTab = "weekTab";
            } else {
                showTemplate(template);
                calendar.options.extras.activeTab = "monthTab";
            }

        });

        if (calendar.options.extras.activeTab === "weekTab") {
            showTemplate("week");
        } else {
            showTemplate("month");
        }

        if (enableOneWeekSearch) {
            updateWeekDaysEvents(calendar);
        }

        $(".next-btn").on("click", function () {
            var weeks_in_month = weeksInMonth(calendar.month) - 1;

            if (calendar.options.extras.currentWeek < weeks_in_month) {
                calendar.options.extras.currentWeek += 1;
                calendar.render();
            } else if (calendar.options.extras.currentWeek > weeks_in_month) {
                calendar.options.extras.currentWeek = 1;
                calendar.next();
            } else {
                calendar.options.extras.currentWeek = 0;
                calendar.next();
            }
        });

        $(".previous-btn").on("click", function () {
            if (calendar.options.extras.currentWeek > 0) {
                calendar.options.extras.currentWeek -= 1;
                calendar.render();
            }
            else {
                var weeks_in_month = weeksInMonth(moment(calendar.month).subtract("month", 1)) - 1; // eslint-disable-line

                calendar.options.extras.currentWeek = weeks_in_month;
                calendar.back();
            }
        });
    }
};

module.exports = selfserviceentrypoint;
