"use strict";

var ics         = require("../ics"),
    fileSaver   = require("../FileSaver"),
    util        = require("./../util");

function addToCalendar() {
    var title       = Resources.ORDER_CALENDAR_TITLE, // eslint-disable-line
        description = $(".calendar-description").html().replace(/\n/g, ""),
        filename    = Resources.ORDER_CALENDAR_FILENAME, // eslint-disable-line
        place       = $(".calendar-location").html().replace(/\n/g, ""),
        date        = $(".calendar-date").html(),
        endDate     = moment(new Date(date)).add(90, "m").format("LLLL"); // eslint-disable-line

    var calEntry = ics();
    calEntry.addEvent(title, description, place, date, endDate);

    var calendarBlob = new Blob([calEntry.calendar()], {type : "text/calendar"});
    fileSaver.saveAs(calendarBlob, filename+".ics");
}

exports.init = function () {
    $(".add-to-calendar").click(addToCalendar);

    if (window) {
        sessionStorage.clear();
    }

    $.each($(".confirmation-page .location-box p"), function () {
        var $this = $(this),
            newCapitalizedContent = util.capitalizeWords($this.text());

        $this.text(newCapitalizedContent);
    });
};
