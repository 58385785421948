
"use strict";

var util = require("../util");
var dialog = require("../dialog");

/**
 * @private
 * @function
 * @description Send thumbs up or down
 */
function sendThumbsUpOrDown(url, data, id, thumbsType) {
    // make the server call
    $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: url,
        data: JSON.stringify(data)
    })
        .done(function () {
        // success
        // sets the cookie for the thumbs up or down
            setCookie("thumbs_" + id, id + thumbsType, "360");
        })
        .fail(function () {
        // failed, do nothing
        // TODO error handling
        });
}

/**
 * @private
 * @function
 * @description Add refinements filter toggle
 */
function addRefinementsFilterToggle() {
    var $refinementFilterToggle = $(".refinement-filter-toggle");

    // check refinements filter toggle
    if ($refinementFilterToggle.length > 0) {
        var $topicFilters = $(".topic-filters");

        $refinementFilterToggle.on("click", function (event) {
            event.preventDefault();

            if ($refinementFilterToggle.hasClass("active")) {
                $refinementFilterToggle.removeClass("active");
            } else {
                $refinementFilterToggle.addClass("active");
            }

            // show or hide the filters
            $topicFilters.toggle();
        });
    }
}

/**
 * @private
 * @function
 * @description Add infinite scroll
 */
function addInfiniteScroll() {
    var $document = $(document);
    var $window = $(window);
    var $articlesLayout = $(".articles-layout");

    // check articles and add infinite scroll
    if ($articlesLayout.length > 1) {
        var enableInfiniteScroll = false;

        $.each($articlesLayout, function (index, element) {
            if (index === 0) {
                $(element).removeClass("hide");
            } else {
                enableInfiniteScroll = true;
            }
        });

        // check if infinite scroll is enabled
        if (enableInfiniteScroll) {
            $window.on("scroll", _.debounce(function () { // eslint-disable-line
                var windowScrololTop = $window.scrollTop();
                var documentHeight = $document.height();
                var windowHeight = $window.height();

                // get scroll percent on page
                var scrollPercent = (windowScrololTop / (documentHeight - windowHeight)) * 100;

                // show more articles if scroll is more than 70 percent
                if (scrollPercent > 70) {
                    $.each($articlesLayout, function (index, element) {
                        // check if we have any more articles hidden
                        if ($(element).is(":hidden")) {
                            $(element).removeClass("hide");
                            return false;
                        }
                    });
                }
            }, 50));
        }
    } else if ($articlesLayout.length === 1) {
        $articlesLayout.removeClass("hide");
    }
}

/**
 * @private
 * @function
 * @description Add the article video popup dialog
 */
function addVideoPopup() {
    var $videoArticleLink = $(".video-article-link");

    // check if article has video
    if ($videoArticleLink.length > 0) {
        $videoArticleLink.on("click", function () {
            // get popup html
            var $videoArticlePopup = $(this).closest(".article-main-image").find(".video-article-container").html();

            dialog.open({
                html: $videoArticlePopup,
                options: {
                    width: 750,
                    dialogClass: "video-article-popup",
                    closeOnEscape: true,
                    close: function () {
                        $(".video-article-popup .youtube-video").remove();
                    }
                }
            });

            $(".ui-widget-overlay").on("click", function () {
                dialog.close();
            });
        });
    }
}

/**
 * @private
 * @function
 * @param {String} cookieName
 * @param {String} cookieValue
 * @param {String} expireDays
 * @description Set the cookie and its expiration in days
 */
function setCookie(cookieName, cookieValue, expireDays) {
    var d = new Date();
    d.setTime(d.getTime() + (expireDays*24*60*60*1000));

    var expires = "expires="+ d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

/**
 * @private
 * @function
 * @param {String} cookieName
 * @description Get the cookie by name
 */
function getCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");

    for (var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}

/**
 * @private
 * @function
 * @param {String} id
 * @param {Object} $thumbsUpTrigger
 * @param {Object} $thumbsDownTrigger
 * @description Check the article id and set active class for thumbs
 */
function checkThumbsCookie(id, thumbsCookie, $thumbsUpTrigger, $thumbsDownTrigger) {
    // check if thumbs cookie is set for this article
    if (id === thumbsCookie.split("|")[0]) {
        // cookie already set, must add active class to thumb
        if (thumbsCookie.split("|")[1] === "up") {
            $thumbsUpTrigger.find("i").addClass("active");
        } else {
            $thumbsDownTrigger.find("i").addClass("active");
        }
    }
}

/**
 * Initialize products events and allow adding to cart
 */
function initializeProductTiles($pageDesigner) {
    require("./search").init();

    var $addToCartButtons = $pageDesigner.find(".add-to-cart, .add-all-to-cart");

    // enable add to cart buttons
    $addToCartButtons.removeAttr("disabled");

    // Product Tile add to cart button mouse hover border change functionality
    $addToCartButtons.off("mouseenter mouseleave").on("mouseenter mouseleave", function () {
        $(this).parents(".product-tile").toggleClass("product-tile--cart-button-hover");
    });
}

/**
 * @private
 * @function
 * @description Initializes events for Page Designer page
 */
function initializePageDesignerEvents() {
    var $pageDesigner = $(".page-designer-kaltire");

    // check if on Page Designer page
    if ($pageDesigner.length > 0) {
        var $window = $(window);
        var $thumbsUpTrigger = $(".thumbs-up-trigger");
        var $thumbsDownTrigger = $(".thumbs-down-trigger");
        var $topicLinks = $(".topic-link");
        var url = util.appendParamsToUrl(Urls.pageDesignerThumbsUrl); // eslint-disable-line
        var id = $pageDesigner.attr("id");
        var $refinementFilterToggle = $(".refinement-filter-toggle");
        var $topicFilters = $(".topic-filters");

        // get cookie for thumbs up section
        var thumbsCookie = getCookie("thumbs_" + id);

        // check thumbs cookie for current page
        if (thumbsCookie) {
            checkThumbsCookie(id, thumbsCookie, $thumbsUpTrigger, $thumbsDownTrigger);
        }

        $thumbsUpTrigger.on("click", function (event) {
            event.preventDefault();

            var $thumbIcon = $(this).find("i");

            if (!$thumbIcon.hasClass("active")) {
                $thumbsDownTrigger.find("i").removeClass("active");
                $thumbIcon.addClass("active");

                var data = {
                    type: "UP",
                    id: id,
                    currentCookie: getCookie("thumbs_" + id)
                };

                sendThumbsUpOrDown(url, data, id, "|up");
            }
        });

        $thumbsDownTrigger.on("click", function (event) {
            event.preventDefault();

            var $thumbIcon = $(this).find("i");

            if (!$thumbIcon.hasClass("active")) {
                $thumbsUpTrigger.find("i").removeClass("active");
                $thumbIcon.addClass("active");

                var data = {
                    type: "DOWN",
                    id: id,
                    currentCookie: getCookie("thumbs_" + id)
                };

                sendThumbsUpOrDown(url, data, id, "|down");
            }
        });

        // add refinements filter toggle
        addRefinementsFilterToggle();

        // add simulated infinite scroll
        addInfiniteScroll();

        // add the article video popup dialog
        addVideoPopup();

        $window.resize(function () {
            // show or hide .topic-filters on window resize
            if ($window.width() >= 768 && $topicFilters.is(":hidden")) {
                $topicFilters.show();
            } else if ($window.width() < 768 && !$refinementFilterToggle.hasClass("active")) {
                $topicFilters.hide();
            }

            // close dialog on resize
            dialog.close();
        });

        $topicLinks.on("mouseover", function () {
            var $this = $(this);
            var $hoverColor = $this.data("hovercolor");

            if ($hoverColor) {
                $this.css("color", $hoverColor);
            }
        });

        $topicLinks.on("mouseout", function () {
            $(this).css("color", "");
        });

        if ($(".product-tile").length) {
            initializeProductTiles($pageDesigner);
        }
    }
}

exports.init = function () {
    initializePageDesignerEvents();
};
