"use strict";

var page = require("./page"),
    util = require("./util"),
    TPromise = require("promise");

var _currentCategory = "",
    MAX_ACTIVE = 3;

/**
 * @private
 * @function
 * @description Verifies the number of elements in the compare container and updates it with sequential classes for ui targeting
 */
function refreshContainer() {
    var $compareContainer = $(".compare-items");

    if ($compareContainer.length > 0) {
        var $compareItems = $compareContainer.find(".compare-item");
        var numActive = $compareItems.filter(".active").length;
        var titleText = $(".compare-title").data("title").replace("0", (MAX_ACTIVE - numActive));

        if (numActive < 2) {
            $("#compare-items-button").attr("disabled", "disabled").text($("#compare-items-button").data("compare"));
        } else {
            $("#compare-items-button").removeAttr("disabled");
        }

        $(".compare-title").text(titleText);

        $compareContainer.toggle(numActive > 0);
    }
}
/**
 * @private
 * @function
 * @description Adds an item to the compare container and refreshes it
 */
function addToList(data) {
    // get the first compare-item not currently active
    var $item = $(".compare-items .compare-item").not(".active").first(),
        $productTile = $("#" + data.uuid),
        $compareNumber = $item.find(".compare-item-number"),
        $compareRemove = $item.find(".compare-item-remove");

    // hide the compare number and show the remove button
    $compareNumber.addClass("hide");
    $compareRemove.removeClass("hide");

    if ($item.length === 0) {
        if ($productTile.length > 0) {
            $productTile.find(".compare-check")[0].checked = false;
        }
        window.alert(Resources.COMPARE_ADD_FAIL); // eslint-disable-line
        return;
    }

    // if already added somehow, return
    if ($("[data-uuid=\"" + data.uuid + "\"]").length > 0) {
        return;
    }

    // set as active item
    $item.addClass("active")
        .attr("data-uuid", data.uuid)
        .attr("data-itemid", data.itemid)
        .data("uuid", data.uuid)
        .data("itemid", data.itemid)
        .append($(data.img).clone().addClass("compare-item-image"))
        .append("<p class=\"compare-item-name\">" + data.title + "</p>");
}

/**
 * @private
 * @function
 * description Removes an item from the compare container and refreshes it
 */
function removeFromList($item) {
    if ($item.length === 0) {
        return;
    }

    // remove class, data and id from item
    $item.removeClass("active")
        .removeAttr("data-uuid")
        .removeAttr("data-itemid")
        .data("uuid", "")
        .data("itemid", "");

    // hide the remove item X
    $item.find(".compare-item-remove").addClass("hide");

    // remove the image
    $item.find(".compare-item-image").remove();

    // remove compare item name
    $item.find(".compare-item-name").remove();

    // show the compare item number
    $item.find(".compare-item-number").removeClass("hide");
}

/**
 * @private
 * @function
 * description Reorders the list items when one has been deleted
 */
function reorderListItems() {
    // check if it has items and reorder them if necessary
    return new TPromise(function (resolve) {
        var $items = $(".compare-items .compare-item");

        if ($items.length <= 1) {
            return;
        }

        for (var i = 0; i <= $items.length-1; i++) {
            var $currentItem = $($items[i]);
            var itemId = $currentItem.attr("data-itemid");

            // continue if we have a compare item at this location
            if (itemId) {
                continue;
            }

            // check compare item location
            if (i <= 1) {
                // item does not exist, look for next item
                var nextItemId = $($items[i+1]).attr("data-itemid");

                if (nextItemId) {
                    switchCompareItem($currentItem, $($items[i+1]));
                }
            }
        }
        resolve();
    });
}

/**
 * @private
 * @function
 * description Switch compare item in place of empty slot
 */
function switchCompareItem($currentItem, $nextItem) {
    if ($nextItem.hasClass("active")) {
        var $nextItemClose = $nextItem.find(".compare-item-remove");

        // add class, data and id from next item
        $currentItem.addClass("active")
            .attr("data-uuid", $nextItem.data("uuid"))
            .attr("data-itemid", $nextItem.data("itemid"))
            .data("uuid", $nextItem.data("uuid"))
            .data("itemid", $nextItem.data("itemid"));

        // hide the compare item number
        $currentItem.find(".compare-item-number").addClass("hide");

        // detach and move next item image and title
        $nextItem.find(".compare-item-image").detach().appendTo($currentItem);
        $nextItem.find(".compare-item-name").detach().appendTo($currentItem);

        // remove class, data and id from next item
        $nextItem.removeClass("active")
            .removeAttr("data-uuid")
            .removeAttr("data-itemid")
            .data("uuid", "")
            .data("itemid", "");

        // copy the remove item button
        $nextItemClose.clone().appendTo($currentItem);

        // hide the remove item button
        $nextItemClose.addClass("hide");

        // show the compare item number
        $nextItem.find(".compare-item-number").removeClass("hide");
    }
}

function addProductAjax(args) {
    var promise = new TPromise(function (resolve, reject) {
        $.ajax({
            url: Urls.compareAdd, // eslint-disable-line
            data: {
                pid: args.itemid,
                category: _currentCategory
            },
            dataType: "json"
        }).done(function (response) {
            if (!response || !response.success) {
                reject(new Error(Resources.COMPARE_ADD_FAIL)); // eslint-disable-line
            } else {
                resolve(response);
            }
        }).fail(function (jqxhr, status, err) {
            reject(new Error(err));
        });
    });
    return promise;
}

function removeProductAjax(args) {
    var promise = new TPromise(function (resolve, reject) {
        $.ajax({
            url: Urls.compareRemove, // eslint-disable-line
            data: {
                pid: args.itemid,
                category: _currentCategory
            },
            dataType: "json"
        }).done(function (response) {
            if (!response || !response.success) {
                reject(new Error(Resources.COMPARE_REMOVE_FAIL)); // eslint-disable-line
            } else {
                resolve(response);
            }
        }).fail(function (jqxhr, status, err) {
            reject(new Error(err));
        });
    });
    return promise;
}

function shiftImages() {
    return new TPromise(function (resolve) {
        var $items = $(".compare-items .compare-item");
        $items.each(function (i, item) {
            // remove last item
            if (i === $items.length - 1) {
                return removeFromList($(item));
            }
        });
        resolve();
    });
}

/**
 * @function
 * @description Adds product to the compare table
 */
function addProduct(args) {
    var promise;
    var $items = $(".compare-items .compare-item");
    var $checkBox = $(args.checkBox);
    var numActive = $items.filter(".active").length;

    if (numActive === MAX_ACTIVE) {
        if (!window.confirm(Resources.COMPARE_CONFIRMATION)) { // eslint-disable-line
            $checkBox[0].checked = false;
            $checkBox.closest(".product-tile").removeClass("product-tile-active");
            return;
        }

        // remove product using id
        var $firstItem = $items.first();
        promise = removeItem($firstItem).then(function () {
            return shiftImages();
        });
    } else {
        promise = TPromise.resolve(0);
    }

    return promise.then(function () {
        return addProductAjax(args).then(function () {
            addToList(args);
            if ($checkBox && $checkBox.length > 0) { $checkBox[0].checked = true; }
            refreshContainer();
        });
    }).then(null, function () {
        if ($checkBox && $checkBox.length > 0) { $checkBox[0].checked = false; }
    });
}

/**
 * @function
 * @description Removes product from the compare table
 * @param {object} args - the arguments object should have the following properties: itemid, uuid and checkBox (checkbox)
 */
function removeProduct(args) {
    var $checkBox = args.checkBox ? $(args.checkBox) : null;
    return removeProductAjax(args).then(function () {
        var $item = $("[data-uuid=\"" + args.uuid + "\"]");
        removeFromList($item);
        reorderListItems();
        if ($checkBox && $checkBox.length > 0) { $checkBox[0].checked = false; }
        refreshContainer();
    }, function () {
        if ($checkBox && $checkBox.length > 0) { $checkBox[0].checked = true; }
    });
}

function removeItem($item) {
    var uuid = $item.data("uuid"),
        $productTile = $("#" + uuid);
    $productTile.removeClass("product-tile-active");
    return removeProduct({
        itemid: $item.data("itemid"),
        uuid: uuid,
        checkBox: ($productTile.length === 0) ? null : $productTile.find(".compare-check")
    });
}

/**
 * @private
 * @function
 * @description Initializes the DOM-Object of the compare container
 */
function initializeDom() {

    var $productPrice = $(".product-tile").eq(0).find(".product-price"),
        $searchResultItems = $("#search-result-items");

    if ($productPrice.length === 2) {
        MAX_ACTIVE = $searchResultItems.data("comparesetproduct");
    }
    else {
        MAX_ACTIVE = $searchResultItems.data("compareproduct");
    }

    var $compareContainer = $(".compare-items");
    _currentCategory = $compareContainer.data("category") || "";
    var $active = $compareContainer.find(".compare-item").filter(".active");
    $active.each(function () {
        var $productTile = $("#" +  $(this).data("uuid"));
        if ($productTile.length === 0) {return;}
        $productTile.find(".compare-check")[0].checked = true;
    });
    // set container state
    refreshContainer();
}

/**
 * @private
 * @function
 * @description Initializes the events on the compare container
 */
function initializeEvents() {
    // add event to buttons to remove products
    $(".compare-item").on("click", ".compare-item-remove", function () {
        removeItem($(this).closest(".compare-item"));
    });

    refreshContainer();

    // Button to go to compare page
    $(document).on("click", "#compare-items-button", function () {
        var url = util.appendParamToURL(Urls.compareShow, "category", _currentCategory); // eslint-disable-line
        if (window.location.href.indexOf("packages=true") > -1) {
            url = util.appendParamToURL(url, "packages", true);
        }
        page.redirect(url);
    });

    // Button to clear all compared items
    // rely on refreshContainer to take care of hiding the container
    $("#clear-compared-items").on("click", function () {
        $(".compare-items .active").each(function () {
            removeItem($(this));
        });
    });
}

exports.init = function () {
    initializeDom();
    initializeEvents();
};

exports.addProduct = addProduct;
exports.removeProduct = removeProduct;
