/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

"use strict";

var countries = require("./countries"),
    minicart = require("./minicart"),
    page = require("./page"),
    rating = require("./rating"),
    searchplaceholder = require("./searchplaceholder"),
    searchsuggest = require("./searchsuggest"),
    tooltip = require("./tooltip"),
    util = require("./util"),
    validator = require("./validator"),
    tls = require("./tls"),
    kaltire = require("./kaltire"),
    insidechat   = require("./insidechat"),
    visualizer   = require("./pages/visualizer.js"),
    flexiti      = require("./flexiti"),
    _            = require("lodash"),
    imagesLoaded = require("imagesloaded"),
    newsletter = require("./newsletter"),
    geolocation = require("./geolocation");

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement("script");
    s.setAttribute("src", "https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js");
    s.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(s);
}

require("./jquery-ext")();
require("./cookieprivacy")();
require("./captcha")();

function initializeEvents() {
    var controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];

    $("body")
        .on("keydown", "textarea[data-character-limit]", function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on("change keyup mouseup", "textarea[data-character-limit]", function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next("div.char-count").find(".char-remain-count").html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    $(".header-search").each(function () {
        searchsuggest.init($(this), Resources.SIMPLE_SEARCH); // eslint-disable-line
    });


    // add show/hide navigation elements
    $(".secondary-navigation .toggle").click(function () {
        $(this).toggleClass("expanded").next("ul").toggle();
    });

    // From GSP redirect
    $(".selection-information").on("click", "#change-responses-cta", function (e) {
        e.preventDefault();
        window.location.href = $("#results-info-from-gsp").data("changeresponse");
    });

    // add generic toggle functionality
    $(".toggle").next(".toggle-content").hide();
    $(".toggle").click(function () {
        $(this).toggleClass("expanded").next(".toggle-content").toggle();
    });

    // subscribe email box
    var $subscribeEmail = $(".subscribe-email");
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) { // eslint-disable-line
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: "#999999"}, 500, "linear", function () {
                $(this).val("").css("color", "#333333");
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            /* eslint-disable */
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css("color", "#999999")
                .animate({color: "#333333"}, 500, "linear");
            /* eslint-enable */
        });
    }

    // main menu toggle
    $(".menu-toggle").on("click", function () {
        $("#wrapper").toggleClass("menu-active");
    });
    $(".menu-category li .menu-item-toggle").on("click", function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest("li");
        $parentLi.siblings("li").removeClass("active").find(".menu-item-toggle").removeClass("fa-chevron-up active").addClass("fa-chevron-right");
        $parentLi.toggleClass("active");
        $(e.target).toggleClass("fa-chevron-right fa-chevron-up active");
    });
    $(".user-account").on("click", function (e) {
        e.preventDefault();
        $(this).parent(".user-info").toggleClass("active");
    });

    if (!sessionStorage.getItem("helloBarClosed")) {
        $("#helloBarContainer").removeClass("hide");
    }

    //hollow button toggle
    $(".close-hollow-btn").on("click", function () {
        $(this).toggleClass("is-active");
    });

    //populateproduct selector info
    var $productselinfo = $("#product-sel-info");
    if ($productselinfo.length > 0) {
        var guidedPath = "",
            packages  = "",
            cgid      = "",
            pid       = "",
            appendSign= "?";

        if (window.location.href.indexOf("guidedSellingPath") > 0) { //NOSONAR
            guidedPath = "?guidedSellingPath=" + window.location.href.split("guidedSellingPath=")[1];
            appendSign = "&";
        }

        if (window.location.href.indexOf("packages") > 0) { //NOSONAR
            packages = "?packages=true";
            appendSign = "&";
        }

        if (window.location.href.indexOf("tires") > 0) { //NOSONAR
            cgid = appendSign + "cgid=tires";
        }
        if (window.location.href.indexOf("wheels") > 0) { //NOSONAR
            cgid = appendSign + "cgid=wheels";
        }
        if (window.location.href.indexOf(".html") > 0) { //NOSONAR
            pid = appendSign + "pid=true";
        }

        // check if minimized vehicle vizualizer must be present
        var minimizedVehicleVizualizer = $(".minimized-vehicle-vizualizer");

        if (minimizedVehicleVizualizer.length > 0) {
            $.ajax({
                url: Urls.getproductselinfominimized + guidedPath + packages + pid + cgid, // eslint-disable-line
                dataType: "html",
                type: "GET",
            }).done(function (data) {
                $productselinfo.html(data);

                // Reinit Foundation on needed elements
                $("#psmodal").foundation();
                $("#product-sel-info").foundation();
            });
        } else {
            $.ajax({
                url: Urls.getproductselinfo + guidedPath + packages + pid + cgid, // eslint-disable-line
                dataType: "html",
                type: "GET",
            }).done(function (data) {
                $productselinfo.html(data);

                // Reinit Foundation on needed elements
                $("#psmodal").foundation();
                $("#product-sel-info").foundation();

                // RideStyler Visualizer integration
                if ($("#v-visualizer-default-vehicle-small").length) {
                    visualizer.getDefaultVehicleImageUrl();
                }
            });
        }
    }

    // Order Confirmation page - print page functionality
    $(".confirmation-page .print-link").on("click", function (e) {
        e.preventDefault();

        // get the footer email wrapper and hide it from printing
        var $footerEmaiWrapper = $(".footer-email-wrapper");
        if ($footerEmaiWrapper.length > 0) {
            $footerEmaiWrapper.addClass("hide-for-print");
        }

        window.print();
    });

    // Content Blocks Secondary Page Nav
    var $cbcols = $(".cb-secondary-page-nav-wrapper .cb-secondary-page-nav-child");
    imagesLoaded(".cb-secondary-page-nav-wrapper").on("done", function () {
        $cbcols.syncHeight();
    });

    $(window).on("resize", _.debounce(function () {
        $cbcols.removeAttr("style").syncHeight();
    }, 300));

    // Content Blocks Product Tiles Sync Height
    var $cbtiles = $(".cb-featured-products-cards .tiles-container .product-tile");
    if ($cbtiles.length === 0) { return; }
    imagesLoaded(".cb-featured-products-cards .tiles-container").on("done", function () {
        $cbtiles.syncHeight()
            .each(function (idx) {
                $(this).data("idx", idx);
            });
    });

    $(window).on("resize", _.debounce(function () {
        $cbtiles.removeAttr("style").syncHeight()
            .each(function (idx) {
                $(this).data("idx", idx);
            });
    }, 300));
}

/**
 * @private
 * @function
 * @description Show or hide the GDPR info bar
 */
function showHideGdprInfo() {
    // check GDPR info bar cookie
    var gdprCookie = getCookie("gdpr_cookie");

    if (!gdprCookie) {
        var $changeLocationBox = $(".change-location-box");
        var $gdprInfoBar = $(".gdpr-info-bar");

        if ($gdprInfoBar.length > 0) {
            // hide change location box if it exists
            if ($changeLocationBox.length > 0) {
                $changeLocationBox.addClass("hide");
            }

            // init event on close GDPR info bar
            $(".gdpr-button").on("click", function (event) {
                event.preventDefault();

                // set GDPR cookie with 30 days expiration
                setCookie("gdpr_cookie", "true", 30);

                // hide the GDPR info bar
                $gdprInfoBar.addClass("hide");

                // show change location box if it exists
                if ($changeLocationBox.length > 0) {
                    $changeLocationBox.removeClass("hide");
                }
            });

            // show the GDPR info bar
            $gdprInfoBar.removeClass("hide");
        }
    }
}

/**
 * @private
 * @function
 * @description Set cookie
 * @param {String} name
 * @param {String} value
 * @param {String} days
 */
function setCookie(name, value, days) {
    var expires = "";

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; samesite=lax; path=/";
}

/**
 * @private
 * @function
 * @description Get cookie by name
 * @param {String} name
 */
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==" ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $("html").addClass("js");
    if (SitePreferences.LISTING_INFINITE_SCROLL) { // eslint-disable-line
        $("html").addClass("infinite-scroll");
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require("./pages/account"),
    signin: require("./pages/account"),
    carfaxhistory: require("./pages/carfaxhistory"),
    cart: require("./pages/cart"),
    checkout: require("./pages/checkout"),
    compare: require("./pages/compare"),
    content: require("./pages/content"),
    kaltirepages: require("./pages/search"),
    mygarage: require("./pages/mygarage"),
    orderconfirmation: require("./pages/orderconfirmation"),
    product: require("./pages/product"),
    registry: require("./pages/registry"),
    search: require("./pages/search"),
    selfserviceentrypoint: require("./selfserviceentrypoint"),
    storefront: require("./pages/storefront"),
    wishlist: require("./pages/wishlist"),
};

var app = {
    init: function () {
        // check the geolocation if the session variable is not set
        if (window.SessionAttributes.IS_QUEBEC_REQUEST) {
            geolocation.getGeolocation();
        }

        if (document.cookie.length === 0) {
            $("<div/>").addClass("browser-compatibility-alert").append($("<p/>").addClass("browser-error").html(Resources.COOKIES_DISABLED)).appendTo("#browser-check"); // eslint-disable-line
        }

        // Custom Foundation Interchange mediaqueries
        Foundation.Interchange.SPECIAL_QUERIES["retina"] = "only screen and (min-width: 0em) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 0em) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 0em) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 0em) and (min-device-pixel-ratio: 2), only screen and (min-width: 0em) and (min-resolution: 192dpi), only screen and (min-width: 0em) and (min-resolution: 2dppx)"; // eslint-disable-line
        Foundation.Interchange.SPECIAL_QUERIES["mediumretina"] = "only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 768px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx)"; // eslint-disable-line
        Foundation.Interchange.SPECIAL_QUERIES["largeretina"] = "only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1024px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1024px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1024px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1024px) and (min-resolution: 192dpi), only screen and (min-width: 1024px) and (min-resolution: 2dppx)"; // eslint-disable-line

        $(document).foundation();

        initializeDom();
        initializeEvents();

        // show / hide the GDPR info bar
        showHideGdprInfo();

        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        util.pdpMain.initSlider();
        kaltire.init();
        insidechat.init();
        flexiti.init();
        newsletter.init();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) { // eslint-disable-line
            tls.getUserAgent();
        }
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0]; //NOSONAR
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
