const validator = require("../../validator");

module.exports = {
    formName: "editVehicleData",
    formSteps: ["initial", "add", "edit", "loading"],
    formSelector: ".vehicledata-edit-form",
    stepsWrapperSelector: "#plate-number-modal",
    closeButtonSelector: ".plate-number-modal .modal-close",
    fieldSelectors: {
        name: ".vehicle-name",
        plate: ".licensePlate",
        vin: ".vinNumber"
    },
    fieldValidators: {
        name: function (value) {
            const nameRegex = /(?:^|\W)\s*(\W+)\s*(?:$|\W)/g;
            const nameFieldValue = this.setField("name", value ? value.replace(nameRegex, "") : "");

            if (nameFieldValue.length > 25) {
                return false;
            }

            return Boolean(nameFieldValue);
        },
        plate: function (value, $field) {
            const plateValidation = this.getValue("handlePlateNumberInputErrors");

            if ($field && value.length === 1) {
                return false;
            }

            // Returning true if the value is undefined because it's not a mandatory field.
            return value ? plateValidation($field) : true;
        },
        vin: function (value, $field) {
            const isValid = value ? validator.regex.vinNumber.test(value) : true;

            if (isValid) {
                $field.siblings(".error").hide();
                $field.removeClass("error");
            }

            // Returning true if the value is undefined because it's not a mandatory field.
            return isValid;
        }
    },
    openModalSelector: ".car-plate-edit, .car-plate-add, .car-name, .car-vin",
    elements: {
        $confirmButton: ".vehicledata-edit-form .action-set-plate-no",
        $saveButton: ".vehicledata-edit-form .save-vehicle"
    },
    values: {},
    listeners: (self) => {    
        self.$form.on("submit", function (e) {
            e.preventDefault();
    
            // Get the current car container, name, VIN, and plate values from the form
            const $carContainer = self.getValue("currentCarContainer");
            const name = self.getValue("name");
            const vin = self.getValue("vin");
            const plate = self.getValue("plate");
    
            // Get the current vehicle object from the class instance
            const currentVehicle = self.getCurrentVehicle();
    
            // If there is a current vehicle, create a data object with the updated vehicle data
            if (currentVehicle) {
                const dataObject = {
                    id: currentVehicle.id,
                    name: name,
                    plateNo: plate ? plate.trim() : "",
                    vin: vin ? vin.trim() : null
                };
    
                // Go to the 'loading' step
                self.goToStep("loading");
    
                // Make an AJAX POST request to update the vehicle data on the server
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    cache: false,
                    contentType: "application/json",
                    url: Urls.setPlateNoVinName,
                    data: JSON.stringify(dataObject),
                    success: (res) => {
                        if (!res) self.goToStep("initial");
                        // If "success" variable below return "true", than all fields are success
                        // If the server successfully updates the vehicle data, update the HTML elements on the page
                        const success = [
                            res.name && res.name.success,
                            res.plateNo,
                            res.vin
                        ].every(item => item);

                        if (success) {
                            const $carPlateAdd = $carContainer.find(".car-plate-add");
                            const $carPlateEdit = $carContainer.find(".car-plate-edit");
                            const $carNameEdit = $carContainer.find(".car-name");
                            const $carVinEdit = $carContainer.find(".car-vin");
    
                            // Hide the "Add plate no." button
                            $carPlateAdd.hide();
    
                            // Add the updated vehicle data to the HTML elements on the page
                            $carNameEdit.removeClass("hide").show().find("span").text(name);
                            if (dataObject.plateNo) {
                                $carPlateEdit.removeClass("hide").show().find("span").text(plate);
                            } else {
                                $carPlateAdd.show();
                                $carPlateEdit.hide();
                            }
                            if (dataObject.vin) {
                                $carVinEdit.removeClass("hide").show().find("span").text(vin);
                            } else {
                                $carVinEdit.hide();
                            }
    
                            // Update the vehicle data on the class instance
                            currentVehicle.name = self.getValue("name");
                            currentVehicle.plate = self.getValue("plate");
                            currentVehicle.vin = self.getValue("vin");
                            self.setVehicle(currentVehicle);
    
                            // Update the vehicle data on the class instance using the data object
                            Object.entries(dataObject).map((entry) => currentVehicle[entry[0]] = entry[1]);
                            self.setVehicle(currentVehicle);
    
                            // Close the modal
                            self.resetForm();
                            self.$closeButton.trigger("click");
                        } else {
                            // If the server fails to update the vehicle data, go back to the 'initial' step
                            self.goToStep("initial");
                        }
                    },
                    error: () => {
                        // If there is an error with the AJAX request, go back to the last step
                        self.goToStep(this.lastStep);
                    }
                });
            }
        });
    
        // Attaches a click event listener to the open modal button
        $(".cars-container").on("click", self.openModalSelector, function () {
            // Store references to the clicked car container, its ID and the action type
            const $this = $(this);
            const $carContainer = $this.closest(".car-container");
            const vehicleId = $carContainer.data("vehicle-id");
            const actionType = $this.data("action-type");

            // Go to the appropriate step in the form and set the current vehicle
            self.goToStep(actionType);
            self.setCurrentVehicle(vehicleId);
            self.setValue("currentCarContainer", $carContainer);
    
            // Set the field values in the form to match the current vehicle's data
            const currentVehicle = self.getCurrentVehicle();
            self.setField("name", currentVehicle.name);
            self.setField("vin", currentVehicle.vin);
            self.setField("plate", currentVehicle.plate);
            self.validateFields();
        });

        $("#plate-number-modal").on("open.zf.reveal", function () {
            var $vehicleNameInput = $(".vehicle-name-container .vehicle-name");
            var $modalInputs = $(".name-your-vehicle-fields input");
            var $errorMessages = $(".licensePlate.plate-no-input + span.error, .vehicle-name + span.error");

            // Hides error messages from previous modal if it still visible
            if ($errorMessages.css("display") === "block") {
                $modalInputs.removeClass("error");
                $errorMessages.css("display", "none");
            }

            if ($vehicleNameInput && $vehicleNameInput.val() && $vehicleNameInput.val().length > 25) {
                $vehicleNameInput.blur();
            }
        });
    },
    validateFields: function () {
        this.triggerValidation("name");
        this.triggerValidation("plate");
        this.triggerValidation("vin");

        const isInvalid = Object.keys(this.invalidFields).length;
        if (isInvalid) {
            this.elements.$saveButton.attr("disabled", "true");
        } else {
            this.elements.$saveButton.removeAttr("disabled");
        }

        return !isInvalid;
    },
};
