"use strict";

/**
 * @function
 * @description Initializes PowerReviews and renders the Category Snippet component for each product listed on the PLP
 */
module.exports = function () {
    window.pwr = window.pwr || function () {
        (pwr.q = pwr.q || []).push(arguments);
    };

    var products = [];
    var searchResults = $(".search-result-content, .page-designer-kaltire");
    var apiKey = searchResults.data("api-key");
    var locale = searchResults.data("locale");
    var merchantId = searchResults.data("merchant-id");
    var merchantGroupId = searchResults.data("merchant-group-id");
    var styleSheet = searchResults.data("style");

    $(".powerreviews-container").each(function (index) {
        var $parent = $(this).closest(".product-tile");
        var pid = $parent.data("pr-page-id");
        $(this).append("<div id=\"category-snippet-" + index + "\"></div>");

        var product = {
            ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
            api_key: apiKey,
            locale: locale,
            merchant_group_id: merchantGroupId,
            merchant_id: merchantId,
            page_id: pid,
            style_sheet: styleSheet,
            components: {
                CategorySnippet: "category-snippet-" + index,
            }
        };

        products.push(product);
    });

    pwr("render", products);
};
