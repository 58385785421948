const formsConfigs = require("./configs");
const FormEvent = require("./models/FormEvent"); // eslint-disable-line
const FormEventsModel = require("./models/FormEventsModel");
const VehicleTools = require("./models/VehicleTools");
const crypto = require("crypto");
const transformLicensePlateNumber = require("../licenseplate");

/**
 * @class
 * @description A class to control the forms, here you can find a bunch of useful methods to get values from the form, navigate through the form steps, reset the fields and store data to the session storage, etc.
 */
class FormCtrl extends VehicleTools {
    /**
     * @param {object} param0 - The options to setup the FormCtrl instance.
     * @param {string} param0.formName - The name of the form.
     * @param {string} param0.stepsWrapperSelector - The selector of the most external wrapper that involves all modal steps.
     * @param {string} param0.formSelector - The selector of the form that will collect data to do a Carfax query.
     * @param {string} param0.openModalSelector - The selector of the button that will open the modal.
     * @param {string} param0.closeButtonSelector - The selector of the close button of the modal.
     * @param {object} param0.elements - CSS selectors to catch the fields inside the form provided in the formSelector parameter.
     * @param {array} param0.formSteps - An array of strings with the form steps.
     * @param {object} param0.fieldSelectors - CSS selectors to catch the fields inside of the form provided in the formSelector parameter.
     * @param {object} param0.fieldsValidators - You can set individuals validator functions for each field, the property name to set the function should have the same name as the name set on the fieldSelectors 
     * @param {function} param0.listeners - A function with the custom listeners needed to handle the form.
     * @param {function} param0.validateFields - A function to validate the form fields.
     * @param {object} param0.values - An object containing values to be set in the form fields.
     * @param {FormEventsModel} param0.events - An FormEventsModel instance containing events to be triggered.
    */
    constructor({
        formName,
        stepsWrapperSelector,
        $form,
        formSelector,
        openModalSelector,
        closeButtonSelector,
        elements,
        formSteps,
        fieldSelectors,
        fieldValidators,
        listeners,
        validateFields,
        values,
        events
    }) {
        super(Object(arguments[0]), () => this);
        const self = this;
        this.events = new FormEventsModel(events || {}, self);
        
        // Triggering the onConstructStart event
        this.triggerEvent("onConstructStart");

        this.formUID = crypto.randomBytes(4).toString("HEX");
        this.formName = formName || ("formCtrl_" + this.formUID);
        this.formSteps = formSteps;
        // Set the first form step as the current step.
        this.currentStep = formSteps && formSteps.length && formSteps[0];
        this.elements = {};
        this.values = {};
        this.invalidFields = {};
        this.fieldSelectors = Object(fieldSelectors);
        this.fieldValidators = Object(fieldValidators);
        this.initTools();

        // If `formSelector` is not provided or it results in an empty jQuery object, the constructor don't finish the work
        if ($form && $form.length) {
            this.$form = $form;
        } else if (formSelector && $(formSelector).length) {
            this.$form = $(formSelector);
        } else {
            return $("body");
        }
        
        this.$form.attr("form-ctrl", this.formName);
        // If `stepsWrapperSelector` is not provided or it results in an empty jQuery object, set it to `$form`.
        if (stepsWrapperSelector && $(stepsWrapperSelector).length) {
            this.$stepsWrapper = $(stepsWrapperSelector);
        } else {
            this.$stepsWrapper = this.$form;
        }

        // Get all steps in the form.
        this.$allSteps = this.$stepsWrapper.find("[modal-step]");

        // Add all form elements referenced in the `elements` object to the `elements` property of the constructed object.
        Object.keys(elements || {}).map(el => { //NOSONAR
            const selector = elements[el];
            if (selector && typeof selector === "string") {
                self.elements[el] = $(selector).length && $(selector);
            }
        });

        // If `closeButtonSelector` is not provided or it results in an empty jQuery object, set it to the modal's default close button.
        if (closeButtonSelector && $(closeButtonSelector).length) {
            this.$closeButton = $(closeButtonSelector);
        } else {
            this.$closeButton = this.$form.find(".modal-close");
        }

        // If `openModalSelector` is provided, add a click event listener to it that will open the modal and display the first step.
        if (openModalSelector && $(openModalSelector).length) {
            this.openModalSelector = openModalSelector;
            this.$openModal = $(openModalSelector);
            this.$openModal.on("click", () => {
                this.goToStep("initial");
            });
        }

        // If `validateFields` is provided, bind it to the constructed object.
        if (validateFields) this.validateFields = validateFields.bind(this);

        // For each key in the `fieldSelectors` object, add an event listener to the corresponding form field that will set the field's value in the `values` property of the constructed object.
        Object.keys(this.fieldSelectors).map(key => { //NOSONAR
            const $field = self.getFields(key);

            if (!$field) {
                return;
            }

            if (self.formName === "addNewVehicle" && $field[0].classList.value.indexOf("plate-no-input") >= 0) {
                let $licensePlateInput = $($field[0]);

                transformLicensePlateNumber($licensePlateInput);
            }

            $field.on("change keyup blur", function () {
                self.setValue(key, $field.val());
                self.validateFields && self.validateFields.call(self, $field);
            });
        });

        Object.keys(values || {}).map(key => { //NOSONAR
            const value = values[key];

            if (typeof value === "function") {
                this.setValue(key, value.bind(this));
            } else {
                this.setValue(key, value);
            }
        });

        if (listeners) {
            // Triggering the onListenersLoadStart event
            this.triggerEvent("onListenersLoadStart");
            // Initializing listeners
            listeners(this, this.elements);
            // Triggering the onListenersLoadEnd event
            this.triggerEvent("onListenersLoadEnd");
        }

        // Triggering the onConstructEnd event
        this.triggerEvent("onConstructEnd");

        FormCtrl.running[this.formName] = this;
    }

    /**
     * Triggers a custom event by its name and passes any additional parameters to its callback function.
     * @param {string} evName - The name of the custom event to trigger.
     * @param {...*} params - Any additional parameters to pass to the callback function of the custom event.
     * @returns {FormEvent} - The custom event object that was triggered or undefined if there was an error.
    */
    triggerEvent(evName, ...params) {
        try {
            const event = this.events[evName];
    
            if (event) {
                event.trigger(...params);
                return event;
            }
        } catch (err) {
            return;
        }
    }

    /**
     * Triggers the field validator that was set on the class instantiation at param "fieldValidators".
     * @param {string} fieldName - The name of the field, same as set on param "fieldValidators".
     * @returns {boolean} - True if passed on validation and false if it's invalid.
    */
    triggerValidation(fieldName) {
        const validator = this.fieldValidators[fieldName];
        const $field = this.getFields(fieldName);
        const inputValue = this.getInputValue(fieldName);

        // If the validator is not a function or the field doesn't exist. Will return true to avoid errors, but it's not validating.
        if (typeof validator !== "function" || !$field) {
            return true;
        }

        if (!validator.call(this, inputValue, $field)) {
            this.invalidFields[fieldName] = true;
            return false;
        } else {
            delete this.invalidFields[fieldName];
            return true;
        }
    }

    /**
     *  Reset the form by going back to the first step, resetting all form fields,
     *  and validating the fields if validateFields function is provided.
     *  @param {boolean} noValidate - If true, skip validating the fields.
     *  @returns {void}
    */
    resetForm(noValidate) {
        this.formSteps && this.formSteps.length && this.goToStep(this.formSteps[0]);
        this.$form[0].reset && this.$form[0].reset();

        !noValidate && this.validateFields && this.validateFields();
        Object.keys(this.fieldSelectors).map(field => this.setField(field, null));
    }

    /**
     * To use when you need to display some step configured in the formSteps param of this class constructor.
     * @param {string} stepName Step name configured on the class instantiation
     * @returns {void}
     */
    goToStep(stepName) {
        const $steps = this.getStep(stepName);

        if ($steps) {
            this.$allSteps.hide();
            this.prevStep = this.currentStep;
            this.currentStep = stepName;
            $steps.show();

            // Triggering the onStepChange event
            this.triggerEvent("onStepChange");
        }
    }

    /**
     * Used to return the Jquery node of the some step.
     * @param {string} stepName Step name configured on the class instantiation
     * @returns {jquery} Jquery object with the fields tagged with the provided stepName
     */
    getStep(stepName) {
        let $steps = $([]);

        // Check if the step name is valid
        if (!this.formSteps || !this.formSteps.find(name => name === stepName)) {
            // The form of the modal wasn't found! If the formSteps or the stepName isn't correct, it will return undefined.
            return;
        }

        this.$allSteps.map(function (_, node) {
            const steps = $(node).attr("modal-step").replace(/\s+/g, "").split(",");
            const findStep = steps.find(item => item === stepName);

            if (findStep) {
                $steps = $steps.add(node);
            }
        });

        return $steps;
    }

    /**
     * To get the jquery of the requested field.
     * @param {string} fieldName Field name configured on the class instantiation
     * @returns {jquery} Jquery object with the input of the provided fieldName. Or boolean false if the field wasn't found. If any was provided on "fieldName" param, it will return all fields.
     */
    getFields(fieldName) {
        if (fieldName) {
            if (!this.fieldSelectors[fieldName]) {
                // The field's name on the parameter "fieldName" doesn't exist!
                return;
            }

            const $field = $(this.fieldSelectors[fieldName]);
            return $field.length ? $field : null;
        } else {
            let result = $([]);

            Object.keys(this.fieldSelectors).map(key => { //NOSONAR
                const field = $(this.fieldSelectors[key]);
                if (field.length) result = result.add(field[0]);
            });

            return result.length ? result : null;
        }
    }

    /**
     * To GET the value provided by the user on the field
     * @param {string} fieldName Field name that was setup on the param "fieldSelectors" object key related to the field
     * @returns {string|boolean} Return a string with the field value, or a boolean if the field is a checkbox. If the field wasn't fould it will return undefined.
     */
    getInputValue(fieldName) {
        if (!this.fieldSelectors[fieldName]) {
            // The field's name on the parameter "fieldName" doesn't exist!
            return;
        }

        const $field = $(this.fieldSelectors[fieldName]);

        if ($field.length) {
            if ($field.attr("type") === "checkbox") {
                return $field[0].checked;
            } else {
                return $field.val();
            }
        } else {
            return undefined;
        }
    }

    /**
     * To SET the value provided by the user on the field
     * @param {string} fieldName Field name that was setup on the param "fieldSelectors" object key related to the field
     * @param {string|number|boolean} value The value to set the field.
     * @returns {string|boolean} Return a string with the current field value, or a boolean if the field is a checkbox.
     */
    setField(fieldName, value) {
        const $field = this.getFields(fieldName);

        if (!$field) {
            // The field's name on the parameter "fieldName" doesn't exist!
            return;
        }

        this.setValue(fieldName, value);

        if ($field.attr("type") === "checkbox") {
            $field[0].checked = value;
            return this.getInputValue(fieldName);
        } else {
            $field.val(value);
            return this.getInputValue(fieldName);
        }
    }

    /**
     * Get the values save used the method setValue
     * @param {string} name Key of the value
     * @returns {string|number|array|object} The value stored
     */
    getValue(name) {
        if (!this.values[name]) {
            return null;
        }

        return this.values[name];
    }

    /**
     * You can save values to use and access through the page. To get the values you can use the method getValue
     * @param {string} name Key identification for the value to be stored
     * @param {string|number|object|array} value The value to be stored
     * @returns {string|number|object|array} The value stored by the method.
     */
    setValue(key, value) {
        if (key && typeof value !== "undefined") {
            this.values[key] = value;
            this.triggerEvent("onSetValue", { key, value });

            return value;
        }
    }

    /**
     * Edit the value of an object property.
     * @param {string} name - The name of the property to edit.
     * @param {(string|number|object)} value - The new value to set on the property.
    */
    editValue(name, value) {
        if (!this.getValue(name)) return;
        const valueType = typeof value;

        switch (valueType) {
            case "string":
            case "number": {
                this.values[name] = value;
                return this.values[name];
            }
            case "object": {
                if (!Array.isArray(value)) {
                    for (const key in value) {
                        if (Object.prototype.hasOwnProperty.call(value, key)) {
                            this.values[name][key] = value[key];
                        }
                    }
                }
                return this.values[name];
            }
            default: {
                this.setValue(name, value);
            }
        }
    }

    /**
     * To save data on the browser sessionStorage.
     * @param {string} key Key for the value to be save on sessionStorage.
     * @param {object} data Data to save.
     * @returns {string} Stringified data added to session.
     */
    setToSession(key, data) {
        let stringifiedData;

        if (!data) {
            return;
        }

        if (typeof data === "string" || typeof data === "boolean" || typeof data === "number") {
            stringifiedData = data;
        } else if (typeof data === "object") {
            try {
                stringifiedData = JSON.stringify(data);
            } catch (err) {
                return;
            }
        } else {
            try {
                const date = new Date(data);
                stringifiedData = date.toJSON();
            } catch (err) {
                return;
            }
        }

        window.sessionStorage.setItem(this.formName + "_" + key, stringifiedData);
        return stringifiedData;
    }

    /**
     * To get data on the browser sessionStorage.
     * @param {string} key Unique identification of the session value
     * @returns {object} Data stored
     */
    getFromSession(key) {
        const result = window.sessionStorage.getItem(this.formName + "_" + key);

        try {
            return JSON.parse(result);
        } catch (err) {
            return result;
        }
    }

    /**
     * To delete data on the browser sessionStorage.
     * @param {string} key Unique identification of the session value
     * @returns {object} Data stored
     */
    deleteFromSession(key, formName) {
        try {
            window.sessionStorage.removeItem((formName || this.formName) + "_" + key);
            return { success: true };
        } catch (err) {
            return err;
        }
    }
}

FormCtrl.configs = formsConfigs;
FormCtrl.running = {};

/**
 * @returns {FormCtrl}
 */
FormCtrl.search = function (formName) {
    return formName && FormCtrl.running && FormCtrl.running[formName] || {};
};
FormCtrl.getConfig = function (formName) {
    return formName && FormCtrl.configs && FormCtrl.configs[formName] || {};
};


/**
 * @module FormCtrl
 */
module.exports = FormCtrl;
