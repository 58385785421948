module.exports = {
    formName: "addVehicleInfo",
    formSelector: "#vehicle-info-modal",
    closeButtonSelector: "#vehicle-info-modal .modal-close",
    fieldSelectors: {
        name: ".vehicle-name",
        plate: ".licensePlate"
    },
    elements: {
        $saveButton: ".save-vehicle",
        $nextButton: "#next-button",
        $verifyVehicleButton: "#verify-vehicle-btn",
        $matchingVehiclesMessage: "#verify-vehicle-alert",
        $verifyBeforeContinueMsg: ".verify-vehicle-msg",
        $vehicleNameError: ".vehicle-name-error"
    },
    values: {
        /**
         * Hide elements that are shown when there are matching vehicles
         */
        hideMatchingElts: function () {
            this.elements.$nextButton.removeAttr("disabled");
            this.elements.$verifyVehicleButton.addClass("hide");
            this.elements.$matchingVehiclesMessage.addClass("hide");
            this.elements.$verifyBeforeContinueMsg.addClass("hide");
        }
    },
    fieldValidators: {
        /**
         * Set the name field value and validate it against a regular expression
         * @param {string} value - The value to set for the name field
         * @returns {boolean} - Returns true if the name field value is set and passes the regular expression validation, otherwise returns false
         */
        name: function (value) {
            const nameRegex = /(?:^|\W)\s*(\W+)\s*(?:$|\W)/g;
            const nameFieldValue = this.setField("name", value ? value.replace(nameRegex, "") : "");

            return Boolean(nameFieldValue);
        },
    },
    /**
     * Validates the fields in the form and enables/disables the save button accordingly
     * @returns {boolean} - Returns true if all fields are valid, false otherwise
     */
    validateFields: function () {
        this.triggerValidation("name");

        const isInvalid = Object.keys(this.invalidFields).length;
        if (isInvalid) {
            this.elements.$saveButton.attr("disabled", "true");
        } else {
            this.elements.$saveButton.removeAttr("disabled");
        }

        return !isInvalid;
    },
    /**
     * Attach event listeners to elements and perform an AJAX request to save vehicle information
     * @param {Object} self - The context of the function
     * @param {Object} elements - The elements to attach event listeners to
     */
    listeners: (self, elements) => {
        const hideMatchingElts = self.getValue("hideMatchingElts");
        self.validateFields();

        if (elements.$saveButton) {
            elements.$saveButton.on("click", function () {
                var name = self.getValue("name");
                var plateNo = self.getValue("plate");
                var isActiveModal = self.$form.hasClass("isActiveModal");

                $.ajax({
                    type: "POST",
                    contentType: "application/json",
                    url: Urls.setNameFromVerifyModal,
                    data: JSON.stringify({ name: name, plateNo: plateNo, isActiveModal: isActiveModal }),
                    success: function (response) {
                        if (response.success) {
                            if (hideMatchingElts) {
                                hideMatchingElts();
                            }

                            self.$closeButton.trigger("click");
                        } else {
                            self.getFields("name").addClass("error");
                            elements.$vehicleNameError.text(response.message).removeClass("hide");
                        }
                    }
                });
            });
        }

        self.$form.on("closed.zf.reveal", function () {
            self.getFields("name").val("").removeClass("error");
            self.getFields("plate").val("");
            elements.$vehicleNameError.text("").addClass("hide");
            self.validateFields();
        });
    },
};
