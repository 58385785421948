"use strict";

/**
* Handles the geolocation response and makes an AJAX request to the geolocation URL
* @param {number} latitude - the latitude coordinate, or null if not available
* @param {number} longitude - the longitude coordinate, or null if not available
* @returns {void}
*/
function handleGeolocationResponse(latitude, longitude) {
    latitude = latitude || null;
    longitude = longitude || null;

    $.ajax({
        url: Urls.geolocation,
        data: {
            latitude: latitude,
            longitude: longitude
        }
    }).done(function (response) {
        if (response.shouldRedirect) {
            window.location.reload();
        }
    });

    return;
}

/**
* Handles geolocation functionality to retrieve latitude and longitude coordinates from the user's browser
*/
function getGeolocation() {
    var latitude = null;
    var longitude = null;

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            // the customer accepts and the browser provides the coordinates
            (position) => {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;

                handleGeolocationResponse(latitude, longitude);
            },
            // the customer denies, then there's no coordinates
            () => {
                handleGeolocationResponse();
            }
        );

        // if the customer didn't answer to the browser location prompt, in a next click on navigation elements
        // we confirm the customer didn't answer, and assume the customer denied
        $(document).on("click", "a, button, .selector-tab, .main-selector-tab", () => {
            navigator.permissions.query({ name: "geolocation" })
                .then(permissionStatus => {
                    if (permissionStatus.state === "prompt") {
                        handleGeolocationResponse();
                    }
                });
        });
    } else {
        // the browser doesn't support geolocation
        handleGeolocationResponse();
    }
}

exports.getGeolocation = getGeolocation;
