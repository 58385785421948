"use strict";

/**
 * Transforms the input text of Vehicle License Plate Number into uppercase and removes any non-alphanumeric characters.
 * @param {jQuery} $licensePlateInput - The jQuery object representing the input element(s).
 */
function transformLicensePlateNumber($licensePlateInput) {
    $licensePlateInput.on("input", function () {
        var $this = $(this);

        // Transforms input text of Vehicle License Plate Number into uppercase
        var inputValueCapitalised = $this.val().toUpperCase();

        // Removes any non-alphanumeric characters from the input value
        var inputValueFiltered = inputValueCapitalised.replace(/[^a-zA-Z0-9-\s]/g, "");

        inputValueFiltered = inputValueFiltered.replace(/\s/g, "");
        inputValueFiltered = inputValueFiltered.replace(/-/g, "");
        $this.val(inputValueFiltered);
    });
}

module.exports = transformLicensePlateNumber;
