/* ----------------------------------------------------------------------------------------------------------------------------
    All these configs below can be accessed using the static method of FormCtrl class (FormCtrl.getConfig(formName))
---------------------------------------------------------------------------------------------------------------------------- */
const verifyVehicle = require("./verifyVehicle");
const addVehicleInfo = require("./addVehicleInfo");
const addNewVehicle = require("./addNewVehicle");
const editVehicleData = require("./editVehicleData");
const carfax = require("./carfax");

module.exports = {
    carfax,
    addVehicleInfo,
    verifyVehicle,
    editVehicleData,
    addNewVehicle
};
