"use strict";

var account = require("./account"),
    bonusProductsView = require("../bonus-products-view"),
    quickview = require("../quickview"),
    cartStoreInventory = require("../storeinventory/cart"),
    Promise  = require("promise");

var orderSummaryPosition;

// Gets the position of the order-summary block
function calculateOrderSummaryPosition() {
    if ($(".order-summary-wrapper").length > 0) {
        orderSummaryPosition = $(".order-summary").offset().top;
    }
}

// Toggles the visibility of the sticky button
function toggleStickyButton() {
    var $window = $(window);
    var $stickyButton = $(".totals-sticky-button");

    if ($window.scrollTop() + $window.height() >= orderSummaryPosition) {
        $stickyButton.hide();
    } else {
        $stickyButton.show();
    }
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    checkFitmentMismatch();

    $("#cart-table").on("click", ".item-edit-details a", function (e) {
        var $self = $(this);
        e.preventDefault();
        gaECGlobals.cartUpdateItemID = $self.parent().parent().find(".sku").find(".value").text(); // eslint-disable-line
        gaECGlobals.cartUpdateItemQty = $self.parent().parent().parent().find("[type=\"number\"]").val(); // eslint-disable-line
        quickview.show({
            url: e.target.href,
            source: "cart"
        });
    })
        .on("click", ".bonus-item-actions a, .item-details .bonusproducts a", function (e) {
            e.preventDefault();
            bonusProductsView.show(this.href);
        });

    // override enter key for coupon code entry
    $("form input[name$=\"_couponCode\"]").on("keydown", function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $("button[name$=\"deleteProduct\"]").on("click", function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    // Scrolls to the order summary when the sticky button is clicked
    $(".totals-sticky-button").on("click", function () {
        $("html, body").animate({
            scrollTop: orderSummaryPosition
        }, 500);
    });

    $(".service-update").on("change", function (e) {
        e.preventDefault();
        var params = {serviceProductID : $(this).parent().find("input[name='serviceProduct']").val()};
        if ($(this).is(":checked")) {
            params.action = "add";
        } else {
            params.action = "remove";
        }

        if ($(this).hasClass("service-recommended") && params.action == "remove") {
            $(this).prop("checked", true);
            $.ajax({
                type: "POST",
                cache: false,
                contentType: "application/json",
                url: Urls.showRecommendedServiceInfo, // eslint-disable-line
                data: JSON.stringify(params)
            }).done(function (resp) {
                var $modal = $("#serviceinfomodal");
                $modal.find(".modal-content").html(resp);
                $modal.foundation("destroy");
                var psmod = new Foundation.Reveal($modal); // eslint-disable-line
                psmod.open();

                $("#serviceinfomodal").off("click").on("click", ".replace-service", function (e) { //NOSONAR
                    e.preventDefault();
                    Promise.resolve($.ajax({
                        method: "POST",
                        url   : Urls.updateServiceProduct, // eslint-disable-line
                        data  : params
                    })
                    ).then(function (response) {
                        if (response.success) {
                            window.location.reload();
                        } else {
                            // @todo if response.success != true show some error?..
                        }
                    });
                });

            });
        } else {
            Promise.resolve($.ajax({
                method: "POST",
                url   : Urls.updateServiceProduct, // eslint-disable-line
                data  : params
            })
            ).then(function (response) {
                if (response.success) {
                    window.location.reload();
                } else {
                    // @todo if response.success != true show some error?..
                }
            });
        }
    });

    $(".editVehicleCart").on("click", function (e) {
        e.preventDefault();

        $.ajax({
            type: "GET",
            cache: false,
            contentType: "application/json",
            url: Urls.editVehicleInCart, // eslint-disable-line
        }).done(function (resp) {
            window.location = resp.redirCat;
        });
    });

    /**
     * @description Checks if the product quantity is lower than 4 items and if the modal for
     * displaying the cartQuantityMessage is added on the template.
     * @param {input} The input element that contains the item quantity.
     * @returns {boolean} true if the cartQuantityModal should be displayed.
     */
    var checkAddToCartQuantity = function (input) {
        var $qty      = $(input),
            $modal    = $("#cartQuantityModal"),
            qty       = ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) ? 1 : $qty.val(),
            showModal = (qty < 4 && $modal.length > 0) ? true : false;

        return showModal;
    };

    $(".productQuantity").on("change", function (e) {
        e.preventDefault();

        var $this = $(this);

        if (checkAddToCartQuantity($this)) {
            // Displays the modal window with the cart quantity message.
            var $modal = $("#cartQuantityModal");

            $modal.foundation("destroy");

            var psmod  = new Foundation.Reveal($modal); // eslint-disable-line

            psmod.open();
            $modal.on("click", ".continueButton", function (e) { //NOSONAR
                e.preventDefault();
                $this.parent().next(".input-text").val($this.val()).next(".button-text").click();
            });
        } else {
            $this.parent().next(".input-text").val($this.val()).next(".button-text").click();
        }
    });

    function checkFitmentMismatch() {
        // Get current selection info to check for mismatch
        $.ajax({
            type: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json",
            url: Urls.checkFitmentMismatch // eslint-disable-line
        }).done(function (resp) {
            if (resp.continuedWithError === true) {
                return false;
            }

            // If modal not showed yet, get information to display detailed mismatch reason if mismatch
            $.ajax({
                type: "POST",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.checkYmmo, // eslint-disable-line
                data: JSON.stringify(resp.currentSelection)
            }).done(function (response) {
                if (response.success === false) {
                    // Get the content of the modal to display detailed mismatch information
                    $.ajax({
                        type: "POST",
                        cache: false,
                        contentType: "application/json",
                        url: Urls.errorCheckYmmo, // eslint-disable-line
                        data: JSON.stringify(response)
                    }).done(function (resp) { //NOSONAR
                        var $modal = $("#psmodalFitment");

                        $modal.find(".modal-content").html(resp);
                        $modal.find(".cart-psmodal-title").hide();
                        $modal.foundation("destroy");

                        var psmod = new Foundation.Reveal($modal); // eslint-disable-line

                        psmod.open();

                        // If customer agrees with mismatch, save this information in the session
                        $("#psmodalFitment").off("click").on("click", ".checkout-with-error", function (e) {
                            e.preventDefault();

                            var currentRefData = JSON.stringify($(this).data("currentref"));

                            $.ajax({
                                type: "POST",
                                dataType: "json",
                                cache: false,
                                contentType: "application/json",
                                url: Urls.setContinueWithError + "?fitID=" + $(this).data("fitid"), // eslint-disable-line
                                data: currentRefData
                            }).done(function () {
                                window.location = Urls.cartShow; // eslint-disable-line
                            });
                        });
                    });
                }
            });
        });
    }
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) { // eslint-disable-line
        cartStoreInventory.init();
    }
    calculateOrderSummaryPosition();
    account.initCartLogin();

    $(window).on("resize", function () {
        calculateOrderSummaryPosition();
    });

    if ($(".order-summary-wrapper").length > 0) {
        $(window).on("scroll", function () {
            toggleStickyButton();
        });
    }
};
