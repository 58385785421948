"use strict";

var ajax = require("../../ajax"),
    formPrepare = require("./formPrepare"),
    progress = require("../../progress"),
    tooltip = require("../../tooltip"),
    util = require("../../util"),
    FormCtrl = require("../../FormCtrl"),
    visualizer = require("../visualizer");

var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $(".gift-message-text").toggleClass("hidden", $("input[name$=\"_shippingAddress_isGift\"]:checked").val() !== "true");
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $("#secondary.summary");
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () { // eslint-disable-line
        // hide edit shipping method link
        $summary.fadeIn("fast");
        $summary.find(".checkout-mini-cart .minishipment .header a").hide();
        $summary.find(".order-totals-table .order-shipping .label a").hide();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $(".address");
    var params = {
        address1: $form.find("input[name$=\"_address1\"]").val(),
        address2: $form.find("input[name$=\"_address2\"]").val(),
        countryCode: $form.find("select[id$=\"_country\"]").val(),
        stateCode: $form.find("select[id$=\"_state\"]").val(),
        postalCode: $form.find("input[name$=\"_postal\"]").val(),
        city: $form.find("input[name$=\"_city\"]").val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID}); // eslint-disable-line
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert("Couldn't select shipping method.");
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $(".shippingpromotions").empty();


            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $("#shipping-method-list");
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON); // eslint-disable-line

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert("Couldn't get list of applicable shipping methods.");
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList); // eslint-disable-line
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn("fast");
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find("[name$=\"_shippingMethodID\"]").click(function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find(".input-radio:checked").length === 0) {
                    $shippingMethodList.find(".input-radio:first").prop("checked", "checked");
                }
            });
        }
    });
}

/**
 * Loads images of vehicles by initializing MyGarageVehicleImageUrl function from Visualizer.
 * @function
 * @returns {void}
*/
function loadVehiclesImage() {
    const $carSpinners = $(".car-image .loader");
    const $carContainers = $(".car-container");
    $carSpinners.show();

    visualizer.initMygarageVehicleImageUrl($carContainers);
    $carSpinners.hide();
}

/**
 * Initializes a FormCtrl object for verifying vehicles and handles various listeners for the "Verify Vehicle" form elements.
 * @function
 * @returns {void}
*/
function initVerifyVehicleForm() {
    const verifyVehicleForm = new FormCtrl(FormCtrl.getConfig("verifyVehicle"));

    loadVehiclesImage();
    return verifyVehicleForm;
}

/**
 * Initializes a FormCtrl object for verifying vehicles and handles various listeners for the "Verify Vehicle" form elements.
 * @function
 * @returns {void}
*/
function initAddVehicleInfoForm() {
    const addVehicleInfoForm  = new FormCtrl(FormCtrl.getConfig("addVehicleInfo"));

    return addVehicleInfoForm;
}

exports.init = function () {
    formPrepare.init({
        continueSelector: "[name$=\"shippingAddress_save\"]",
        formSelector:"[id$=\"singleshipping_shippingAddress\"]"
    });
    $("input[name$=\"_shippingAddress_isGift\"]").on("click", giftMessageBox);

    $(".address").on("change",
        "input[name$=\"_addressFields_address1\"], input[name$=\"_addressFields_address2\"], select[name$=\"_addressFields_states_state\"], input[name$=\"_addressFields_city\"], input[name$=\"_addressFields_zip\"]",
        updateShippingMethodList
    );

    var $nextButton = $("#next-button");
    var isMatchingVehiclesCount = $nextButton.data("matching-vehicles-count");

    const verifyVehicleForm = initVerifyVehicleForm();

    var verifyVehicleOnForm = function (e) {
        // check if edit account info on checkout
        if ($nextButton.attr("forced-disabled") == "true") {
            // skip validation
            return;
        }

        //check if vehicle has been selected
        var $form = $("form[id$=\"shippingAddress\"]");
        var $vehicleInfo = $(".vehicle-info");
        // check if the verify vehicle alert is showing
        var vehicleShouldBeVerified = $("#verify-vehicle-alert").is(":visible");
        var hasVehicleInfo = $vehicleInfo.length > 0 && $vehicleInfo[0].textContent.length > 0;

        if ($form.validate().checkForm() && isMatchingVehiclesCount <= 1 && hasVehicleInfo && !vehicleShouldBeVerified && $(".name-length-error").length === 0) {
            $form.find("button#next-button").removeAttr("disabled");
        } else {
            const selectedVehicleId = verifyVehicleForm.getValue("selectedVehicleId");

            if ($form.validate().checkForm() && (isMatchingVehiclesCount > 1 && selectedVehicleId) && hasVehicleInfo && !vehicleShouldBeVerified  && $(".name-length-error").length === 0) {
                $form.find("button#next-button").removeAttr("disabled");
            } else {
                $form.find("button#next-button").attr("disabled", "disabled");
            }
        }

        if (e != null) {
            e.stopPropagation();
        }
    };

    var $vehicleCheckbox = $(".commercial-fleet-wrapper input[type=\"checkbox\"]");
    var $vehicleForm = $(".commercial-fleet-form");
    var $checked = $(".commercial-fleet-wrapper input[type=\"checkbox\"]:checked");

    function checkboxSelected() {
        $(".commercial-fleet-form .form-row:nth-child(odd) input").prop("required", true);
        $(".commercial-fleet-form .form-row:nth-child(odd) label span").addClass("required-field");
        $vehicleForm.removeClass("hide");
    }
    //Check if 'Yes' checkbox is selected after page reload
    if ($checked.data("validation-label") === "Yes") {
        checkboxSelected();
    }

    //Check which checkbox is selected and shows/hides input fields
    $vehicleCheckbox.on("change", function (event) {
        $vehicleCheckbox.not(this).prop("checked", false);
        var $target = $(event.target);

        if ($target.data("validation-label") === "No") {
            $(".commercial-fleet-form .form-row:nth-child(odd) input").prop("required", false);
            $(".commercial-fleet-form .input-text").val("");
            $vehicleForm.addClass("hide");
        } else {
            checkboxSelected();
        }
        verifyVehicleOnForm(null);
    });

    $(document).on("keyup vehiclechanged", "form[id$=\"shippingAddress\"]", verifyVehicleOnForm);

    $(document).on("click", "#edit-info-checkout", function () {
        if ($("#isUserAuthenticated").val() === "false") {
            $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_email\"]").prop("disabled", true);
        }
        $nextButton.prop("disabled", true).attr("forced-disabled", "true");
        // Trigger validation on the first and last name inputs within the register form
        $(".account-info-form").find(".first-name, .last-name").each(function () {
            $(this).valid();
        });
    });

    $(document).on("click", "#cancel-edit-checkout", function () {
        $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_email\"]").prop("disabled", false);
        $nextButton.prop("disabled", false).attr("forced-disabled", "false");
        verifyVehicleOnForm(null);
    });

    $(document).on("click", "#save-personal-info", function () {
        var $form = $("form[id$=\"shippingAddress\"]");
        if ($form.validate().checkForm()) {
            $(this).removeAttr("disabled");
            $("#next-button").prop("disabled", true);
            $.ajax({
                url: Urls.editPersonalInfo, // eslint-disable-line
                type: "POST",
                data: {
                    format: "ajax",
                    firstName: $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_firstName\"]").val(),
                    lastName: $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_lastName\"]").val(),
                    mobilePhone: $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_phone\"]").val(),
                    postalCode: $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_postal\"]").val(),
                    email: $("input[name=\"dwfrm_singleshipping_shippingAddress_addressFields_email\"]").val()
                }
            }).done(function () {
                window.location.reload();
            });
        } else {
            $form.validate().form();
            $(this).attr("disabled", "disabled");
        }
    });

    formPrepare.prepareFormSubmissionForRTM();
    formPrepare.initializeCaptureFormTrigger("#dwfrm_singleshipping_shippingAddress");

    // If customer is authenticated and form is available
    if ($("div#edit-info-form").length) {
        $(document).on("change", "div#edit-info-form .input-text", function () {
            $("div#edit-info-form button#save-personal-info").removeAttr("disabled");
        });
        // If there are empty fields for the authenticated customer, open form and hide cancel button
        var emptyFields = $("form[id$=\"shippingAddress\"]").find(".account-info-form .input-text").filter("[value=\"\"]");
        if (emptyFields.length) {
            $("#edit-info-checkout").trigger("click");
            $("#cancel-edit-checkout").addClass("hide");
        }
    }
    initAddVehicleInfoForm();
    giftMessageBox();
    updateShippingMethodList();
    verifyVehicleOnForm(null);
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.initVerifyVehicleForm = initVerifyVehicleForm;
