"use strict";

var dialog = require("../../dialog"),
    page = require("../../page"),
    util = require("../../util"),
    TPromise = require("promise");

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form, emptyCart) {
    var $form = $(form),
        $qty = $form.find("input[name=\"Quantity\"]");
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val("1");
    }

    var packages = "",
        isPackage = "";

    if (window.location.href.indexOf("packages=true") > -1) {
        packages = "&packages=true";
    }

    if (window.location.href.indexOf("packages=true") > -1) {
        isPackage = "&isPackage=true";
    }

    if ($form.serialize() != "") {
        $("#addtocartpopup").data("form", $form.serialize());
    }

    return TPromise.resolve($.ajax({
        type: "POST",
        url: util.ajaxUrl(Urls.addProduct) + ($form.serialize() == "" ? "&replaceCart=true" : "") + packages + "&emptyCart=" + emptyCart + isPackage, // eslint-disable-line
        data: $("#addtocartpopup").data("form") ? $("#addtocartpopup").data("form") : $form.serialize()
    }));
};

/**
 * @description Checks if the car quantity is lower than 4 items and
 *              if the modal for displaying the cartQuantityMessage is added on the template.
 * @param {form} The form element that contains the item quantity and ID data.
 * @returns {boolean} true is the cartQuantityModal should be displayed.
 */
var checkAddToCartQuantity = function (form) {
    var $form  = $(form),
        $qty   = $form.find("input[name=\"Quantity\"]"),
        $modal = $("#cartQuantityModal"),
        qty    = 0;

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        qty = 1;
    } else {
        qty = $qty.val();
    }

    return qty < 4 && $modal.length > 0 ? true : false;
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();

    var $form     = $(this).closest("form"),
        emptyCart = $(this).hasClass("empty-cart") ? true : false,
        product   = $(this).data("product"),
        currencyCode = $(this).data("currencycode");

    var quantity = $form.children(".product-quantity") &&  $form.children(".product-quantity").length > 0 ? $form.children(".product-quantity")[0].value : 4;    

    if ($form.length > 0 && checkAddToCartQuantity($form)) {
        // Displays the modal window with the cart quantity message.
        var $modal = $("#cartQuantityModal");
        $modal.foundation("destroy");
        var psmod  = new Foundation.Reveal($modal); // eslint-disable-line
        psmod.open();

        $modal.on("click", ".continueButton", function (e) { //NOSONAR
            e.preventDefault();
            addItemToCart($form, emptyCart).then(function (response, $form) { //NOSONAR
                if (product && currencyCode && quantity) {
                    addDataLayerAddToCart(product, currencyCode, quantity);
                }
                addItemToCartHandleResponse(response, $form);
            }.bind(this));
        });
    } else {
        addItemToCart($form, emptyCart).then(function (response, $form) { //NOSONAR
            if (product && currencyCode && quantity) {
                addDataLayerAddToCart(product, currencyCode, quantity);
            }
            addItemToCartHandleResponse(response, $form);
        }.bind(this));
    }
};

function addDataLayerAddToCart(product, currencyCode, quantity) {
    window.dataLayer.push({
        "event": "addToCartQV",
        "ecommerce": {
            "currencyCode": currencyCode,
            "add": {
                "products": [{
                    "name": product.name,
                    "id": product.id,
                    "price": product.price,
                    "brand": product.brand,
                    "category": product.category,
                    "quantity": quantity
                }]
            }
        }
    });
}

/**
 * @description handles the response of the addItemToCart AJAX call
 * @param {response} Call's response
 * @param {form} The form element that contains the item quantity and ID data
 */
function addItemToCartHandleResponse(response, form) {
    var $form = $(form),
        $uuid = $form.find("input[name=\"uuid\"]");

    if ($uuid.length > 0 && $uuid.val().length > 0) {
        page.refresh();
    } else {
        // do not close quickview if adding individual item that is part of product set
        if (!$(this).hasClass("sub-product-item")) {
            dialog.close();
        }

        var $modal = $("#addtocartpopup");

        if (response.indexOf("cart-popup-container") > -1) {
            $modal.find(".modal-content").html(response);
            $modal.foundation("open");
            $modal.find(".product-tile").foundation(); // init images in the modal
        } else if (response.indexOf("cart-popup-update") > -1) {
            window.location = Urls.cartShow; // eslint-disable-line
        } else {
            window.location = Urls.cartShow; // eslint-disable-line
        }
    }
}

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();

    var psID        = $(this).data("psid"),
        $form       = $(".addToCartSetForm").length > 0 ? $(".addToCartSetForm") : $(".product-tile[data-itemid="+ psID +"]"),
        $childQtys  = $form.find("select.productQuantity"),
        $childPids  = $form.find("input[name=\"pid\"]"),
        childQtys   = "",
        childPids   = "",
        emptyCart   = "",
        replaceCart = "",
        hasMinQty   = true,
        $modal      = $("#cartQuantityModal");

    if ($(this).hasClass("empty-cart")) {
        emptyCart = "&emptyCart=true";
    }
    if ($(this).hasClass("add-all-to-cart-popup")) {
        replaceCart = "&replaceCart=true";
    }

    $childQtys.each(function (index) {
        childQtys+= (index == 0 ? $(this).val() : "," + $(this).val());
        if (hasMinQty && $(this).val() < 2) {
            hasMinQty = false;
        }
    });

    $childPids.each(function (index) {
        childPids+= (index == 0 ? $(this).val() : "," + $(this).val());
    });

    var dataObj = {};

    dataObj.childPids = childPids;
    dataObj.childQtys = childQtys;
    dataObj.pid = psID;

    if (!hasMinQty && $modal.length > 0) {
        // Displays the modal window with the cart quantity message.
        $modal = $("#cartQuantityModal");

        $modal.foundation("destroy");

        var psmod = new Foundation.Reveal($modal); // eslint-disable-line

        psmod.open();

        $modal.on("click", ".continueButton", function (e) { //NOSONAR
            e.preventDefault();
            addAllToCartAjaxCall(emptyCart, replaceCart, dataObj);
        });
    } else {
        addAllToCartAjaxCall(emptyCart, replaceCart, dataObj);
    }
};

/**
 * @description Ajax call to the addAllToCart method.
 * @param emptyCart string with the emptyCart querystring parameter.
 * @param replaceCart string with the replaceCart querystring parameter.
 * @param dataObj object to be sent with the needed parameters.
 */
function addAllToCartAjaxCall(emptyCart, replaceCart, dataObj) {
    $.ajax({
        url: util.ajaxUrl(Urls.addProduct) + emptyCart + replaceCart, // eslint-disable-line
        method: "POST",
        data: dataObj
    }).done(function (response) {
        var $modal = $("#addtocartpopup");

        if (response.indexOf("cart-popup-container") > -1) {
            $modal.find(".modal-content").html(response);
            $modal.foundation("open");
            $modal.find(".product-tile").foundation(); // init images in the modal
        } else if (response.indexOf("cart-popup-update") > -1) {
            window.location = Urls.cartShow; // eslint-disable-line
        } else {
            window.location = Urls.cartShow; // eslint-disable-line
        }
    });
}

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $(".product-detail, .compare-page, .product-tile").on("click", ".add-to-cart", addToCart);
    $(".product-tile").on("click", ".add-all-to-cart", addAllToCart);
    $(".product-detail, .compare-page").on("click", "#add-all-to-cart", addAllToCart);
    $("#addtocartpopup").on("click", ".add-to-cart-popup", addToCart);
    $("#addtocartpopup").on("click", ".add-all-to-cart-popup", addAllToCart);
    $(".productQuantity").on("change", function () {
        var $this = $(this);
        var $productQuantity = $this.closest("form").find("input.product-quantity");
        if ($productQuantity.length > 0) {
            $productQuantity.val($this.val());
        } else {
            $(".quantity input.product-quantity").val($this.val());
        }
    });
};
