module.exports = {
    formName: "verifyVehicle",
    formSelector: "#verify-vehicle-modal",
    closeButtonSelector: "#verify-vehicle-modal .modal-close",
    fieldSelectors: {
        selectedVehicleId: "[name=selectedVehicleId]"
    },
    elements: {
        $confirmButton: "#verify-vehicle-modal .confirm-button",
        $createNewVehicle: "#verify-vehicle-modal .create-new",
        $vehicleInfoModal: "#vehicle-info-modal",
        $nextButton: "#next-button",
        $matchingVehiclesMessage: "#verify-vehicle-alert",
        $matchingArchivedVehiclesMessage: "#verify-vehicle-alert",
        $verifyVehicleButton: "#verify-vehicle-btn",
        $verifyBeforeContinueMsg: ".verify-vehicle-msg",
        $vehicleInformation: ".vehicle-info"
    },
    values: {
        /**
         * Show matching elements based on the isArchived flag
         * @param {boolean} isArchived - Flag indicating if the elements are archived
         */
        showMatchingElts: function (isArchived) {
            isArchived = isArchived || false;

            this.elements.$nextButton.attr("disabled", "disabled");
            this.elements.$verifyVehicleButton.removeClass("hide");
            this.elements.$verifyBeforeContinueMsg.removeClass("hide");
            if (isArchived) {
                this.elements.$matchingVehiclesMessage.removeClass("hide");
            } else {
                this.elements.$matchingArchivedVehiclesMessage.removeClass("hide");
            }
        },
        hideMatchingElts: function () {
            if ($(".name-length-error").length === 0) {
                this.elements.$nextButton.removeAttr("disabled");
            }
            this.elements.$verifyVehicleButton.addClass("hide");
            this.elements.$matchingVehiclesMessage.addClass("hide");
            this.elements.$verifyBeforeContinueMsg.addClass("hide");
        },
        resetSelectedVehicle: function () {
            this.deleteFromSession("selectedVehicleId");
            this.deleteFromSession("isNewVehicle");
            this.deleteFromSession("matchOne");
            this.deleteFromSession("multipleMatch");
            this.setField("selectedVehicleId", "");
        }
    },
    events: {
        onConstructEnd: function () {
            const selectedVehicleId = this.getFromSession("selectedVehicleId");
            const isMatchingTheSame = this.getVehicle(selectedVehicleId);
            const isNewVehicle = this.getFromSession("isNewVehicle");
            const vehicles = this.getAllVehicles();
            const matchingCount = this.getActiveVehiclesCount();
            const resetSelectedVehicle = this.getValue("resetSelectedVehicle");
            const showMatchingElts = this.getValue("showMatchingElts");
            const isOnlyArchivedVehicles = this.isOnlyArchivedVehicles();

            if ((selectedVehicleId && !isMatchingTheSame) || (isNewVehicle && matchingCount)) {
                resetSelectedVehicle();
                showMatchingElts();
                this.triggerEvent("onConstructEnd");
            } else {
                if (selectedVehicleId && isMatchingTheSame) {
                    this.setField("selectedVehicleId", selectedVehicleId);
                } else if (isOnlyArchivedVehicles) {
                    this.setValue("archivedMatch", true);
                } else if (isNewVehicle || !matchingCount) {
                    this.setValue("isNewVehicle", true);
                } else if (matchingCount > 1) {
                    this.setValue("multipleMatch", true);
                } else if (matchingCount === 1) {
                    this.getFields("selectedVehicleId").val(vehicles[0].id);
                    this.setValue("matchOne", true);
                }
            }
        },
        onSetValue: function (_, toSet) {
            const hideMatchingElts = this.getValue("hideMatchingElts");
            const showMatchingElts = this.getValue("showMatchingElts");

            if (!hideMatchingElts || !showMatchingElts) {
                return;
            }

            if (["isNewVehicle", "matchOne", "multipleMatch", "selectedVehicleId", "archivedMatch", "showVehicleInfoModal"].find(key => key === toSet.key)) {
                this.setToSession(toSet.key, toSet.value);
            }

            switch (toSet.key) {
                case "isNewVehicle":
                case "matchOne": {
                    const showVehicleInfoModal = this.getFromSession("showVehicleInfoModal");
                    if (showVehicleInfoModal) {
                        this.deleteFromSession("showVehicleInfoModal");
                    } else {
                        toSet.value && hideMatchingElts();
                    }

                    break;
                }
                case "archivedMatch": {
                    toSet.value && showMatchingElts(true);
                    break;
                }
                case "multipleMatch": {
                    toSet.value && showMatchingElts();
                    break;
                }
                case "selectedVehicleId": {
                    if (toSet.value) {
                        hideMatchingElts();
                    } else {
                        this.deleteFromSession("selectedVehicleId");
                    }
                }
            }
        }
    },
    listeners: (self, elements) => {
        const hideMatchingElts = self.getValue("hideMatchingElts");

        // Select a vehicle
        self.$carContainer.on("click", function () {
            const vehicleId = $(this).data("vehicle-id");
            const isArchivedVehicle = $(this).hasClass("js-archived-car");

            if (vehicleId) {
                self.setValue("focusedMatchingVehicle", vehicleId);
                self.setValue("isArchivedVehicle", isArchivedVehicle);
                elements.$confirmButton.removeAttr("disabled");
            }
        });

        // Confirm the selected vehicle
        if (elements.$confirmButton) {
            elements.$confirmButton.on("click", function () {
                const focusedMatchingVehicle = self.getValue("focusedMatchingVehicle");
                const isArchivedVehicle = self.getValue("isArchivedVehicle");

                if (focusedMatchingVehicle) {
                    self.setCurrentVehicle(focusedMatchingVehicle);
                    self.setField("selectedVehicleId", focusedMatchingVehicle);
                    self.setValue("isNewVehicle", false);

                    if (isArchivedVehicle) {
                        $.ajax({
                            type: "POST",
                            contentType: "application/json",
                            url: Urls.unArchiveVehicle,
                            data: JSON.stringify({ id: focusedMatchingVehicle })
                        });
                    }

                    self.$closeButton.trigger("click");
                    hideMatchingElts && hideMatchingElts();
                }
            });
        }

        // Enable the NEXT BUTTON
        if (elements.$createNewVehicle) {
            elements.$createNewVehicle.on("click", function () {
                if (!$(this).hasClass("new-from-archived-modal")) {
                    elements.$vehicleInfoModal.addClass("isActiveModal");
                } else {
                    elements.$vehicleInfoModal.removeClass("isActiveModal");
                }

                self.setField("selectedVehicleId", "");
                self.setValue("showVehicleInfoModal", true);
                self.setValue("isNewVehicle", true);

                elements.$vehicleInfoModal.foundation("open");
            });
        }
    }
};
