"use strict";

const transformLicensePlateNumber = require("./licenseplate");

//var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    // phone: {
    //     us: naPhone,
    //     ca: naPhone,
    //     fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
    //     it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
    //     jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
    //     cn: /.*/,
    //     gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    // },
    phone: /^\(?([2-9][0-8][0-9])\)?[ ]?([2-9][0-9]{2})[ ]?([0-9]{4})(\s*x[0-9]+)?$/,
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    email: /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/,  // eslint-disable-line
    flName: /^[-a-zA-Z\-—.,' ]*$/,
    fleetcardnumber:/^[a-zA-Z0-9]+$/,
    fleetcardnumber2:/^[0-9]+$/,
    vehiclelicenseplatenumber: /^[a-zA-Z0-9-]+$/,
    licenseplatenumber: /[^a-zA-Z0-9-\s]/g,
    licensePlate: /^[a-zA-Z0-9-]*$/,
    vinNumber: /^[A-HJ-NPR-Z\d]{17}$/i,
    vehicleunitnumber: /^[a-zA-Z0-9-]+$/,
};
// global form validator settings
var settings = {
    errorClass: "error",
    errorElement: "span",
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
// var validatePhone = function (value, el) {
//     console.log("intrareeeee");
//     var country = $(el).closest('fieldset').find('.country') || $(el).closest('form').find('.country');
//     if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
//         return true;
//     }

//     var rgx = regex.phone[country.val().toLowerCase()];
//     var isOptional = this.optional(el);
//     var isValid = rgx.test($.trim(value));

//     return isOptional || isValid;
// };

// add client-side limit number of characters for inputs
$(document).ready(function () {
    $("input.phone, input.otherPhone").attr({maxLength : 12});
    $("input.postal-code").attr({maxLength : 10});
    $("input.first-name").attr({maxLength : 25});
    $("input.last-name").attr({maxLength : 30});
});

/**
 * @function
 * @description Validates a given phone number
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value) {
    var isValid = regex.phone.test($.trim(value));
    return isValid;
};

var validateFleetCardNumber = function (value) {
    var $fleetcard = $(".fleetcardnumber");
    var isValid = false;

    if ($fleetcard.val().length === 0) {
        isValid = true;
    } else if ($fleetcard.val().length === 12) {
        isValid = regex.fleetcardnumber.test($.trim(value)); // eslint-disable-line
    } else if ($fleetcard.val().length === 16) {
        isValid = regex.fleetcardnumber2.test($.trim(value)); // eslint-disable-line
    }

    return isValid;
};

var validateVehicleLicensePlateNumber = function (value) {
    var isValid = false;
    if ($(".vehiclelicenseplatenumber").val().length <= 8) {
        isValid = regex.vehiclelicenseplatenumber.test($.trim(value));
    }
    return isValid;
};

var validateLicensePlate = function (value) {
    var isValid = false;
    if ($(".licensePlate").val().length <= 8) {
        isValid = regex.licensePlate.test($.trim(value));
    }
    return isValid;
};

/**
* Validates the license plate number
* @param {string} value - the license plate number to validate
* @returns {boolean} - true if the license plate number is valid, false otherwise
*/
var validateLicensePlateNumber = function (value) {
    var isValid = false;
    var licensePlateNumber = $("#license-plate-number").val();

    if (licensePlateNumber && licensePlateNumber.length <= 8) {
        isValid = regex.licenseplatenumber.test($.trim(value));
    }
    return isValid;
};

var validateVinNumber = function (value) {
    var isValid = false;
    if ($(".vinNumber").val().length <= 17) {
        isValid = regex.vinNumber.test($.trim(value));
    }
    return isValid;
};

var validateVehicleUnitNumber = function (value) {
    var isValid = false;
    if ($(".vehicleunitnumber").val().length === 0) {
        isValid = true;
    } else if ($(".vehicleunitnumber").val().length <= 12) {
        isValid = regex.vehicleunitnumber.test($.trim(value));
    }
    return isValid;
};


// Transforms input text of Vehicle License Plate Number into uppercase
$(".vehiclelicenseplatenumber").keyup(function () {
    $(this).val($(this).val().toUpperCase());
});

transformLicensePlateNumber($("#license-plate-number input, .license-plate-inner input, .licensePlate, .vehiclelicenseplatenumber, .plate-no-input"));

// Transforms input text of Vehicle Identification Number into uppercase
$(".vinNumber").keyup(function () {
    $(this).val($(this).val().toUpperCase());
});

// Transforms input text of Vehicle Unit Number into uppercase
$(".vehicleunitnumber").keyup(function () {
    $(this).val($(this).val().toUpperCase());
});

/**
 * @function
 * @description Validates a given other phone number - this phone is not required
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validateOtherPhone = function (value) {
    if ($(".otherPhone").val().length === 0) {
        isValid = true;
    } else {
        var isValid = regex.phone.test($.trim(value));
    }
    return isValid;
};

//Split the phone number
$(".phone, .otherPhone").on("input", function () {
    $(this).val(function (i, phoneNumber) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
        return phoneNumber;
    });
});

/**
 * @function
 * @description Validates a given first/last names on the checkout page
 * @param {String} value The first/last name which will be validated
 * @param {String} el The input field
 */
var validateCheckoutCustomerNames = function (value) {
    var isValid = regex.flName.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

var validateEmail = function (email) {
    var isValid = regex.email.test(email);
    return isValid;
};

var validatePostalCode = function (postal) {
    var isValid = regex.postal.ca.test(postal);
    return isValid;
};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod("phone", validatePhone, Resources.INVALID_PHONE); // eslint-disable-line

$.validator.addMethod("otherPhone", validateOtherPhone, Resources.INVALID_PHONE); // eslint-disable-line

$.validator.addMethod("first-name", validateCheckoutCustomerNames, Resources.CHECKOUT_VALIDATE_FLNAME); // eslint-disable-line

$.validator.addMethod("last-name", validateCheckoutCustomerNames, Resources.CHECKOUT_VALIDATE_FLNAME); // eslint-disable-line

$.validator.addMethod("postal-code", validatePostalCode, Resources.INVALID_POSTAL_CODE); // eslint-disable-line

$.validator.addMethod("fleetcardnumber", validateFleetCardNumber, Resources.VALIDATE_FLEETCARDNUMBER); // eslint-disable-line

$.validator.addMethod("vehiclelicenseplatenumber", validateVehicleLicensePlateNumber, Resources.VALIDATE_VEHICLELICENSEPLATENUMBER); // eslint-disable-line

$.validator.addMethod("licensePlate", validateLicensePlate, Resources.VALIDATE_VEHICLELICENSEPLATENUMBER); // eslint-disable-line

$.validator.addMethod("vinNumber", validateVinNumber, Resources.VALIDATE_VINNUMBER); // eslint-disable-line

$.validator.addMethod("vehicleunitnumber", validateVehicleUnitNumber, Resources.VALIDATE_VEHICLEUNITNUMBER); // eslint-disable-line

$.validator.addMethod("licenseplatenumber", validateLicensePlateNumber, Resources.VALIDATE_LICENSEPLATENUMBER);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod("owner", validateOwner, Resources.INVALID_OWNER); // eslint-disable-line

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated as not a email address
 */
$.validator.addMethod("email", validateEmail, Resources.VALIDATE_EMAIL); // eslint-disable-line

/**
 * Add password validation method to jQuery validation plugin.
 * Text fields must have 'password' css class to be validated as password
 */
$.validator.addMethod("password", function (value) {
    return (value.length >= 7) && (value.length <= 20);
}, Resources.VALIDATE_PASSWORD); // eslint-disable-line

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod("gift-cert-amount", function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID); // eslint-disable-line

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod("positivenumber", function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ""); // '' should be replaced with error message if needed

/**
* Custom validation method for minimum license plate number length
* @param {string} value - the input value to be validated
* @param {HTMLElement} el - the input element being validated
* @returns {boolean} - true if the input value meets the minimum license length requirement, false otherwise
*/
$.validator.addMethod("licenseminlength", function (value, el) {
    var licenseMinLength = $(el).attr("licenseminlength");
    if (licenseMinLength) {
        var minLength = !isNaN(Number(licenseMinLength)) ? Number(licenseMinLength) : 2;

        if (!el.required) {
            return value.length === 1 ? false : true;
        }

        return (value.length >= minLength);
    }
    // return true as fallback
    return true;
}, Resources.VALIDATE_LICENSEMINLENGTH);

$.extend($.validator.messages, {
    /* eslint-disable */
    required: function ($1, ele, $3) {
        var missingValueMessage = $(ele).attr("missing");

        if (missingValueMessage && missingValueMessage.length > 0) {
            return missingValueMessage;
        } else {
            return Resources.MISSINGVAL.replace("{0}", $(ele).data("validation-label"));
        }
    },
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    fleetcardnumber: Resources.VALIDATE_FLEETCARDNUMBER,
    vehiclelicenseplatenumber: Resources.VALIDATE_VEHICLELICENSEPLATENUMBER,
    licensePlate: Resources.VALIDATE_VEHICLELICENSEPLATENUMBER,
    vinNumber: Resources.VALIDATE_VINNUMBER,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
    /* eslint-enable */
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $("form:not(.suppress)").each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
