"use strict";

var giftcert = require("../giftcert"),
    tooltip = require("../tooltip"),
    util = require("../util"),
    dialog = require("../dialog"),
    page = require("../page"),
    validator = require("../validator"),
    visualizer = require("./visualizer"),
    formPrepare = require("./checkout/formPrepare");

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $("#edit-address-form");

    $form.find("input[name=\"format\"]").remove();
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    $form.on("click", ".apply-button", function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr("action"), "format", "ajax");
        var applyName = $form.find(".apply-button").attr("name");
        var options = {
            url: url,
            data: $form.serialize() + "&" + applyName + "=x",
            type: "POST"
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else {
                    window.alert(data.message);
                    return false;
                }
            } else {
                $("#dialog-container").html(data);
                account.init();
                tooltip.init();
            }
        });
    })
        .on("click", ".cancel-button, .close-button", function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on("click", ".delete-button", function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { // eslint-disable-line
                var url = util.appendParamsToUrl(Urls.deleteAddress, { // eslint-disable-line
                    AddressID: $form.find("#addressid").val(),
                    format: "ajax"
                });
                $.ajax({
                    url: url,
                    method: "POST",
                    dataType: "json"
                }).done(function (data) {
                    if (data.status.toLowerCase() === "ok") {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else { //NOSONAR
                        dialog.close();
                        page.refresh();
                    }
                });
            }
        });

    validator.init();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $(".order-items")
        .find("li.hidden:first")
        .prev("li")
        .append("<a class=\"toggle\">View All</a>")
        .children(".toggle")
        .click(function () {
            $(this).parent().siblings("li.hidden").show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $("#addresses");
    if (addresses.length === 0) { return; }

    addresses.on("click", ".address-edit, .address-create", function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                open: initializeAddressForm
            }
        });
    }).on("click", ".delete", function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { // eslint-disable-line
            $.ajax({
                url: util.appendParamToURL($(this).attr("href"), "format", "ajax"),
                dataType: "json"
            }).done(function (data) {
                if (data.status.toLowerCase() === "ok") {
                    page.redirect(Urls.addressesList); // eslint-disable-line
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initOrderHistoryEvents() {
    $(document).on("click.zf.accordion", ".accordion-order-header", function (event) {
        event.preventDefault();

        var $clickedTab = $(event.target);
        if (!$clickedTab.hasClass("accordion-order-header")) {
            $clickedTab = $clickedTab.parents(".accordion-order-header");
        }

        var $orderHistoryContent = $clickedTab.siblings(".accordion-content");
        var $loader = $orderHistoryContent.find(".loader");
        var $orderHistoryBody = $orderHistoryContent.find(".accordion-body-content");
        if ($orderHistoryBody.text().trim().length <= 0) {
            $loader.fadeIn();
            var url = $clickedTab.data("url");
            $.ajax({
                url: url,
                type: "GET"
            }).done(function (response) {
                $orderHistoryBody.append(response);
                $loader.fadeOut();
            });
        }
    });

    $(document).on("click", ".no-order-selector-tab", function (event) {
        var $target = $(event.target);
        var link = $target.find(".selector-tab-link").attr("href");
        window.location = link;
    });

    $(document).on("click", ".reset-password-button", function (event) {
        event.preventDefault();
        var $this = $(this);
        var url = $(this).attr("href");
        var $loader = $this.siblings(".loader");

        $loader.fadeIn();

        $.ajax({
            url: url,
            type: "GET"
        }).done(function () {
            $loader.fadeOut();
        });
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $(".add-card").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href")
        });
    });

    var paymentList = $(".payment-list");
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD)); // eslint-disable-line

    $("form[name=\"payment-remove\"]").on("submit", function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find(".delete");
        $("<input/>").attr({
            type: "hidden",
            name: button.attr("name"),
            value: button.attr("value") || "delete card"
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: "POST",
            url: $(this).attr("action"),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList); // eslint-disable-line
            });
    });
}
/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    //o-auth binding for which icon is clicked
    $(".oAuthIcon").bind("click", function () {
        $("#OAuthProvider").val(this.id);
    });

    $(document).on("click", ".register-form-toggle, .register-form-toggle-cancel", function () {
        $("#account-information").toggleClass("hide");
    });

    $(document).on("click", ".register-form-toggle", function () {
        var $registerForm = $(".register-form");

        // Trigger validation on the first and last name inputs within the register form
        $registerForm.find(".first-name, .last-name").each(function () {
            $(this).valid();
        });
    });

    //toggle the value of the rememberme checkbox
    $("#dwfrm_login_rememberme").bind("change", function () {
        if ($("#dwfrm_login_rememberme").attr("checked")) {
            $("#rememberme").val("true");
        } else {
            $("#rememberme").val("false");
        }
    });
    $("#password-reset").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href"),
            options: {
                dialogClass: "no-buttonpane"
            }
        });
    });
    $(document).on("click", "#password-reset-btn", function (e) {
        validator.init();
        var $requestPasswordForm = $(this).parents("[name$=\"_requestpassword\"]");
        if (!$requestPasswordForm.valid()) {
            return;
        }
        e.preventDefault();
        dialog.submit($(this).attr("name"));
    });
}
/**
 * @private
 * @function
 * @description Loads more orders on infinite scroll
 */
function loadMoreOrders() {
    var $loadMoreData = $(".load-more-data");
    var $orderList = $(".order-history");

    if ($loadMoreData.data("url") && util.elementInViewport($loadMoreData.get(0), 100) && !$orderList.hasClass("loading")) {
        $orderList.addClass("loading");

        var $loadMoreContainer = $loadMoreData.parent();
        var $loader = $loadMoreData.siblings(".loader");
        var url = $loadMoreData.data("url");
        $loader.fadeIn();

        $.ajax({
            url: url,
            type: "GET"
        }).done(function (response) {
            $loader.fadeOut();
            $loadMoreContainer.remove();
            $orderList.append(response);
            var $newAccordion = $orderList.children().last();

            $newAccordion.foundation();
            $orderList.removeClass("loading");
        });
    }
}

/**
 * @private
 * @function
 * @description Loads archived vehicles
 */
function loadArchivedVehicles() {
    if ($(".switcher input").is(":checked")) {
        $(".js-archived-car").removeClass("hide");
    } else {
        $(".js-archived-car").addClass("hide");
    }
}

/**
 * @private
 * @function
 * @description Filters orders by vehicle id
 */
function filterOrders(vehicleId) {
    var $ordersContainer = $(".js-orders-section");

    if (!$ordersContainer.hasClass("loading")) {
        $ordersContainer.addClass("loading");

        var $loader = $ordersContainer.prev(".loader");
        $loader.fadeIn();

        var dataObject = {
            "isAjax": true
        };

        if (vehicleId) {
            dataObject.selectedVehicleId = vehicleId;

            if (location.href.indexOf(dataObject.selectedVehicleId) == -1) {
                window.location.hash = "?selectedVehicleId=" + dataObject.selectedVehicleId;
            }
        } else {
            window.location.hash = "";
        }

        $.ajax({
            type: "GET",
            url: Urls.orderHistory, // eslint-disable-line
            data: dataObject
        }).done(function (response) {
            $loader.fadeOut();
            $ordersContainer.html(response);

            var $newAccordion = $ordersContainer.find(".order-history-list");

            $newAccordion.foundation();
            $ordersContainer.removeClass("loading");
        });
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
    initOrderHistoryEvents();
    $(window).on("scroll", loadMoreOrders);
    $(".switcher input").on("click", function () {
        loadArchivedVehicles();
    });
    $(".order-history-filter-box input").on("click", function () {
        var vehicleId = $(this).val();

        filterOrders(vehicleId);
    });

    formPrepare.prepareFormSubmissionForRTM();
    formPrepare.initializeCaptureFormTrigger("#RegistrationForm");
}

/**
 * Add appoitment info, order details page
 */
function addAppointmentInfo() {
    if ($(".appointment-info").length > 0 && $(".appointment-info").data("appointment")) {
        var appointment = $(".appointment-info").data("appointment");
        var appointmentData = moment(appointment.startDate, "YYYYMMDD"); // eslint-disable-line
        appointmentData.minutes(appointment.startTime);
        $(".account-info-date").text(appointmentData.format("dddd, MMMM D, YYYY"));
        $(".account-info-time").text(appointmentData.format("H:mm A"));
    }
}

/**
 * Activate Order filter
 */
function activateCurrentFilter() {
    var activeFilterValue = "";
    if (location.href.split("?selectedVehicleId=").length > 0) {
        activeFilterValue = location.href.split("?selectedVehicleId=")[1];
    }

    if (activeFilterValue) {
        var $selectedCar = $(".car-container input[value=\"" + activeFilterValue + "\"]");

        if (!$selectedCar.is(":visible")) {
            $(".switcher input").trigger("click");
            loadArchivedVehicles();
        }
        $selectedCar.attr("checked", true);
    } else {
        $("#filterAllItem").attr("checked", true);
    }
    filterOrders(activeFilterValue);
}

var account = {
    init: function () {
        if (pageContext && pageContext.type == "OrderHistory") { // eslint-disable-line
            activateCurrentFilter();
        }

        loadArchivedVehicles();
        // RideStyler Visualizer integration
        visualizer.initMygarageVehicleImageUrl();
        initializeEvents();
        giftcert.init();
        addAppointmentInfo();
    },
    initCartLogin: function () {
        initLoginPage();
    }
};

module.exports = account;
