"use strict";

var ajax = require("../../ajax"),
    formPrepare = require("./formPrepare"),
    giftcard = require("../../giftcard"),
    util = require("../../util");

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $("[data-method=\"CREDIT_CARD\"]");
    $creditCard.find("input[name$=\"creditCard_owner\"]").val(data.holder).trigger("change");
    $creditCard.find("select[name$=\"_type\"]").val(data.type).trigger("change");
    $creditCard.find("input[name*=\"_creditCard_number\"]").val(data.maskedNumber).trigger("change");
    $creditCard.find("[name$=\"_month\"]").val(data.expirationMonth).trigger("change");
    $creditCard.find("[name$=\"_year\"]").val(data.expirationYear).trigger("change");
    $creditCard.find("input[name$=\"_cvn\"]").val("").trigger("change");
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, "creditCardUUID", cardID); // eslint-disable-line
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR); // eslint-disable-line
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Check Card Holder Name field
 */
function checkOutCardHolderName() {
    var value = $("#monerisNameInput");
    var isValid = /^[-a-zA-Z\.', ]{1,100}$/.test(value.val()); // eslint-disable-line

    if (!value.val() || !isValid) {
        value.removeClass("valid").addClass("error");
        $("#monerisMsg span").text(Resources.VALIDATE_NAME); // eslint-disable-line
    }
}

/**
 * @function
 * @description Save current billing form input fields values
 * @param {Object} form current form
 */
function saveCurrentFormValues(form) {
    var result = {};
    result.firstName = form.find("[name$=\"_firstName\"]").val();
    result.lastName = form.find("[name$=\"_lastName\"]").val();
    result.address1 = form.find("[name$=\"_address1\"]").val();
    result.city = form.find("[name$=\"_city\"]").val();
    result.state = form.find("[name$=\"_state\"]").val();
    result.postal = form.find("[name$=\"_postal\"]").val();

    return result;
}

/**
 * @function
 * @description Clear form values
 * @param {Object} form current form
 */
function clearAllFormValues(form) {
    //form.find('[name$="_firstName"]').val('');
    //form.find('[name$="_lastName"]').val('');
    form.find("[name$=\"_address1\"]").val("");
    form.find("[name$=\"_city\"]").val("");
    form.find("[name$=\"_postal\"]").val("");
}

/**
 * @function
 * @description Add form values
 * @param {Object} form current form
 */
function appendAllFormValues(form, values) {
    //form.find('[name$="_firstName"]').val(values.firstName);
    //form.find('[name$="_lastName"]').val(values.lastName);
    form.find("[name$=\"_address1\"]").val(values.address1);
    form.find("[name$=\"_city\"]").val(values.city);
    form.find("[name$=\"_postal\"]").val(values.postal);
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $(".payment-method");
    $paymentMethods.removeClass("payment-method-expanded");

    var $selectedPaymentMethod = $paymentMethods.filter("[data-method=\"" + paymentMethodID + "\"]");
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $("[data-method=\"Custom\"]");
    }
    $selectedPaymentMethod.addClass("payment-method-expanded");

    // ensure checkbox of payment method is checked
    $("input[name$=\"_selectedPaymentMethodID\"]").removeAttr("checked");
    $("input[value=" + paymentMethodID + "]").prop("checked", "checked");

    formPrepare.validateForm();
}

/**
 * @function
 * @description adds listening event to handle response from Moneris
 */
function initMoneris() {
    var respMsg = function (e) {
        var respData      = eval("(" + e.data + ")"),
            respCode      = respData && respData.responseCode ? respData.responseCode[0] : "",
            $checkoutForm = $(".checkout-billing");

        // 001 means success
        if (respCode === "001") {
            var isAmex = /3[47]\d{4}/.test(respData.bin);

            if (isAmex) {
                $("#monerisToken").val("");
                $("#monerisMsg span").html("Oops, you’ve entered an invalid credit card type. <br/> We do not accept AMEX at this time. Please try again with a valid VISA or Mastercard.");
            } else {
                $("#monerisToken").val(respData.dataKey);
                $("#monerisMsg span").text("");
                if ($("#monerisNameInput").length) {
                    if ($("#monerisNameInput").val() && !$("#monerisNameInput").hasClass("error")) {
                        $checkoutForm.off("submit").submit();
                    } else {
                        $("#monerisMsg span").text(Resources.VALIDATE_NAME); // eslint-disable-line
                    }
                } else {
                    $checkoutForm.off("submit").submit();
                }
            }
        } else {
            var errorMsg = "";

            if (respCode === "943") {
                errorMsg = "Invalid credit card information. Please try again.";
            } else if (respCode === "944") {
                errorMsg = "Invalid expiration date.";
            } else if (respCode === "945") {
                errorMsg = "Invalid CVD data.";
            }

            $("#monerisToken").val("");
            $("#monerisMsg span").text(errorMsg);
        }
    };

    window.onload = function () {
        if (window.addEventListener) {
            window.addEventListener ("message", respMsg, false);
        } else {
            if (window.attachEvent) {
                window.attachEvent("onmessage", respMsg);
            }
        }
    };
}

/**
 * @function
 * @description send data to Moneris
 */
function doMonerisSubmit() {
    var monFrameRef = document.getElementById("monerisFrame").contentWindow,
        targetURL   = document.getElementById("monerisToken").getAttribute("data-target");
    monFrameRef.postMessage("", targetURL);
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $(".checkout-billing");
    var $addGiftCert = $("#add-giftcert");
    var $giftCertCode = $("input[name$=\"_giftCertCode\"]");
    var $addCoupon = $("#add-coupon");
    var $couponCode = $("input[name$=\"_couponCode\"]");
    var $selectPaymentMethod = $(".payment-method-options");
    var selectedPaymentMethod = $selectPaymentMethod.find(":checked").val();

    // if Moneris is enabled, we call it at form submit
    if ($checkoutForm.find("#monerisFrame").length > 0) {
        initMoneris();

        $checkoutForm.on("submit", function (e) {
            e.preventDefault();
            checkOutCardHolderName();
            doMonerisSubmit();
        });
    }

    formPrepare.init({
        formSelector: "form[id$=\"billing\"]",
        continueSelector: "[name$=\"billing_save\"]"
    });

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : "CREDIT_CARD");
    $selectPaymentMethod.on("click", "input[type=\"radio\"]", function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    $("#creditCardList").on("change", function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $(".required.error").removeClass("error");
        $(".error-message").remove();
    });

    $("#check-giftcert").on("click", function (e) {
        e.preventDefault();
        var $balance = $(".balance");
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find("span.error");
            if (error.length === 0) {
                error = $("<span>").addClass("error").appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING); // eslint-disable-line
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass("success").addClass("error"); // eslint-disable-line
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + " " + data.giftCertificate.balance).removeClass("error").addClass("success"); // eslint-disable-line
        });
    });

    $addGiftCert.on("click", function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find(".giftcert-error");
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING); // eslint-disable-line
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: "ajax"}); // eslint-disable-line
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = "";
            if (!data) {
                msg = Resources.BAD_RESPONSE; // eslint-disable-line
                fail = true;
            } else if (!data.success) {
                msg = data.message.split("<").join("&lt;").split(">").join("&gt;");
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                window.location.assign(Urls.billing); // eslint-disable-line
            }
        });
    });

    $addCoupon.on("click", function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find(".coupon-error"),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING); // eslint-disable-line
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: "ajax"}); // eslint-disable-line
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = "";
            if (!data) {
                msg = Resources.BAD_RESPONSE; // eslint-disable-line
                fail = true;
            } else if (!data.success) {
                msg = data.message.split("<").join("&lt;").split(">").join("&gt;");
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing); // eslint-disable-line
            }
        });
    });

    // trigger events on enter
    $couponCode.on("keydown", function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on("keydown", function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    $("form.submit-order").on("submit", function () {
        $(this).submit(function () {
            return false;
        });
        return true;
    });

    $("fieldset .checkout-fields-container").hide();
    var $billingForm = $("form#dwfrm_billing");
    var $formValuesObject = saveCurrentFormValues($billingForm);

    $("#billing-address-useexisting").click(function () {
        appendAllFormValues($billingForm, $formValuesObject);
        $("fieldset .checkout-fields-container").hide();
        formPrepare.validateForm();
    });

    $("#billing-address-removeexisting").click(function () {
        clearAllFormValues($billingForm);
        $("fieldset .checkout-fields-container").show();
        formPrepare.validateForm();
    });

    $("#monerisNameInput").blur(function () {
        var isValid = /^[-a-zA-Z\.', ]{1,100}$/.test($(this).val()); // eslint-disable-line

        if (!$(this).val() || !isValid) {
            $(this).removeClass("valid").addClass("error");
            $("#monerisMsg span").text(Resources.VALIDATE_NAME); // eslint-disable-line
        } else {
            var $cardHolderName = $(this).val().split(" ");
            if ($cardHolderName.length == 3) {
                $billingForm.find("[name$=\"_firstName\"]").val($cardHolderName[0]);
                $billingForm.find("[name$=\"_lastName\"]").val($cardHolderName[1] + " " + $cardHolderName[2]);
            } else {
                $billingForm.find("[name$=\"_firstName\"]").val($cardHolderName[0]);
                $billingForm.find("[name$=\"_lastName\"]").val($cardHolderName[1]);
            }

            $("#monerisMsg span").text("");
        }
    });
};
