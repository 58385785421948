const validator = require("../../validator");

module.exports = {
    formName: "carfax",
    stepsWrapperSelector: "#carfax-history-modal",
    formSteps: ["initial", "search", "loading", "error"],
    formSelector: "#vehicle-fieldset",
    closeButtonSelector: "#carfax-history-modal .carfax-modal-close",
    elements: {
        $searchAgainButton: "#carfax-search-button",
        $continueButton: "#carfax-continue-button",
        $serviceButton: "#carfax-service-button",
        $unavailableErrorTitle: ".service-unavailable-section .section-title",
        $carfaxServiceWrap: ".service-history-wrap"
    },
    fieldSelectors: {
        vin: ".vin-input input",
        vinSave: ".vin-save-input input",
        plate: ".license-plate-inner input",
        state: ".provicence-input select",
        vehicleSave: ".save-vehicle-container input"
    },
    fieldValidators: {
        vin: function (value) {
            // Returning true if the value is undefined because it's not a mandatory field.
            const isValid = value ? validator.regex.vinNumber.test(value) : true;
            return isValid;
        }
    },
    values: {},
    // Add the listeners related to this form context
    listeners: (formCtrl, elements) => {
        // Reset the form when the close button of the modal is clicked
        formCtrl.$closeButton.on("click", function () {
            formCtrl.resetForm();
        });

        // CONTINUE button action
        elements.$continueButton.on("click", function () {
            formCtrl.goToStep("search");
        });

        // SEARCH AGAIN button action
        elements.$searchAgainButton.on("click", function () {
            formCtrl.resetForm();
        });

        // Clear license plate validation
        $("#carfax-history-modal").on("closed.zf.reveal", function () {
            var $licensePlateInput = $(".license-plate-inner input");
            var $errorMessage = $(".license-plate-inner input + span.error");

            // Hides error messages from previous modal if it still visible
            if ($errorMessage.css("display") === "block") {
                $licensePlateInput.removeClass("error");
                $errorMessage.css("display", "none");
            }
        });

        // GET SERVICE HISTORY button action
        elements.$serviceButton.on("click", function () {
            const saveVehicleField = formCtrl.getInputValue("vehicleSave");
            const currentVehicle = formCtrl.getCurrentVehicle();
            const serviceData = currentVehicle.carfaxResponse;
            const serviceHistory = serviceData && serviceData.serviceHistory;
            const ajaxData = formCtrl.serviceHistoryAjaxData();

            formCtrl.goToStep("loading");

            if (saveVehicleField) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json",
                    url: Urls.savePlateNumberOrVin,
                    data: ajaxData,
                    success: () => {
                        if (serviceHistory) {
                            formCtrl.renderCarfaxResults(serviceHistory);
                        }
                    },
                    error: () => formCtrl.goToStep("error")
                });
            } else {
                formCtrl.renderCarfaxResults(serviceHistory);
            }

            serviceData && formCtrl.setToSessionStorage(currentVehicle.id, serviceData);
        });

        // Enable or disable the CONTINUE button
        formCtrl.getFields().on("change keyup blur", function () {
            if (formCtrl.validateFields()) {
                elements.$continueButton.removeAttr("disabled");
            } else {
                elements.$continueButton.attr("disabled", "true");
            }
        });

        // Send the request to CARFAX to query the service history.
        formCtrl.$form.on("submit", function (e) {
            e.preventDefault();
            const vehicleServiceHistoryURL = $(this).data("search-url");
            const currentVehicle = formCtrl.getCurrentVehicle();
            const ajaxData = formCtrl.serviceHistoryAjaxData();

            formCtrl.goToStep("loading");

            if (formCtrl.validateFields() && currentVehicle && ajaxData) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json",
                    url: vehicleServiceHistoryURL,
                    data: ajaxData,
                    success: (data) => {
                        const serviceHistory = data.object && data.object.serviceHistory;
                        const displayRecords = serviceHistory && serviceHistory.displayRecords;

                        if (!data.success || !displayRecords) {
                            formCtrl.searchResponseError(data);
                        } else {
                            formCtrl.fillVehicleDataOnModal("#carfax-history-modal", serviceHistory);
                            
                            currentVehicle.vin = serviceHistory.vin;
                            currentVehicle.model = serviceHistory.model;
                            currentVehicle.make = serviceHistory.make;
                            currentVehicle.year = serviceHistory.year;
                            currentVehicle.carfaxResponse = data.object;

                            formCtrl.setVehicle(currentVehicle);
                            formCtrl.goToStep("search");
                        }
                    },
                    error: (err) => formCtrl.searchResponseError(err)
                });
            } else {
                formCtrl.goToStep("error");
            }
        });
    }
};
