"use strict";

var newsletter = {
    init: function () {
        var $newsletterForm = $("#email-alert-signup"),
            $submitButton = $newsletterForm.find("button");

        $submitButton.on("click", function (event) {
            event.preventDefault();
            $submitButton.attr("disabled", true);
            // validate the form
            if (!$newsletterForm.valid()) {
                $submitButton.attr("disabled", false);
                return;
            }

            var email = $newsletterForm.find("input[name=subscribe-email]").val();
            var url = Urls.newsletterSubscribe + "?email=" + email;
            var $newsletterMessage = $(".newsletter-message");

            // do the ajax call to Newsletter-Add
            $.ajax({
                url: url,
            }).done(function () {
                setNewsletterMessage($newsletterMessage, Resources.NEWSLETTER_SUCCESS, false);
            }).fail(function () {
                setNewsletterMessage($newsletterMessage, Resources.NEWSLETTER_SUCCESS, false);
            });
        });

        /**
         * @param {object} element
         * @param {string} message
         * @param {boolean} error
         * @description Set the newsletter success or error messages
         */
        function setNewsletterMessage(element, message, error) {
            if (error) {
                if (element.length > 0) {
                    // remove old error
                    element.remove();
                }

                $submitButton.before("<span id=\"subscribe-email-error\" class=\"error\">" + message + "</span>");
            } else {
                $(".newsletter-data").hide();
                element.removeClass("hide");
                element.html("<i class=\"icon-circle-checked\"></i> <span>" + message + "</span>");
            }
        }
    }
};

module.exports = newsletter;
