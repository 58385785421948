/**
 * A class containing methods for handling vehicles and their data.
 * @class
 */
class VehicleTools {
    /**
     * Create a new instance of VehicleTools
     * @constructor
     * @param {object} setup - The setup object.
     * @param {string} setup.carContainerSelector - The CSS selector for the container element that holds the car data.
     */
    constructor(setup) {
        const { carContainerSelector } = setup || {};
        this.carContainerSelector = carContainerSelector;
    }

    /**
     * Initialize the vehicle tools.
     * If the `$carContainer` property is not empty, set the vehicle data for each car in the container,
     * that can be retrieved with the FormCtrl.getVehicle method.
     * @function
     * @param {string} [carContainerSelector] - The CSS selector for the container element that holds the car data.
     */
    initTools(carContainerSelector) {
        this.$carContainer = $(carContainerSelector || this.carContainerSelector || ".car-container");
        if (this.$carContainer.length) {
            this.$carContainer.map((_, car) => {
                const $car = $(car);

                const vehicleData = {
                    id: $car.data("vehicle-id") || "",
                    vin: $car.data("vehicle-vin") || "",
                    name: $car.data("vehicle-name") || "",
                    plate: $car.data("vehicle-plate") || "",
                    year: $car.data("vehicle-year") || "",
                    make: $car.data("vehicle-make") || "",
                    model: $car.data("vehicle-model") || "",
                    submodel: $car.data("vehicle-submodel") || "",
                    option: $car.data("vehicle-option") || "",
                    archived: $car.data("vehicle-archived") || "",
                };
                this.setVehicle(vehicleData);
            });
        }
    }

    /**
     * Store data of a vehicle.
     * @function
     * @param {object} vehicleData - The data vehicle to store inside of the instance class.
     * @param {array} [saveCopyAt] - Any CarfaxFormCtrl that you want to copy the values to it.
     * @returns {object} Saved object.
     */
    setVehicle(vehicleData, saveCopyAt) {
        if (vehicleData && !vehicleData.id) {
            // The "vehicleData.id" prop is required to set a vehicle
            return null;
        }
        return this.setValue("vehicle-" + vehicleData.id, vehicleData, saveCopyAt);
    }

    /**
     * Retrieve data of a vehicle.
     * @function
     * @param {string} vehicleId - The vehicle id to get the vehicle data.
     * @returns {object} Vehicle data.
     */
    getVehicle(vehicleId) {
        const vehicle = this.values["vehicle-" + vehicleId];
        if (vehicle) {
            this.setCurrentVehicle(vehicleId);
            return vehicle;
        } else {
            return;
        }
    }

    /**
     * Retrieve data of all the vehicles.
     * @function
     * @returns {array} An array containing the data of all vehicles.
     */
    getAllVehicles() {
        const result = [];
        Object.keys(this.values).map(key => { //NOSONAR
            const value = this.values[key];
            if (key.indexOf("vehicle-") > -1) {
                result.push(value);
            }
        });
        return result;
    }

    /**
     * To get the current vehicle in focus of the user
     * @returns {object} Vehicle data
     */
    getCurrentVehicle() {
        const currentVehicleId = VehicleTools.currentVehicle;
        return this.getVehicle(currentVehicleId);
    }

    /**
    * Retrieves the count of active (non-archived) vehicles
    * @returns {number} The number of active vehicles
    */
    getActiveVehiclesCount() {
        const vehicles = this.getAllVehicles();
        return vehicles.filter(vehicle => !vehicle.archived).length;
    }

    /**
    * Checks if all vehicles are archived
    * This method retrieves all vehicles and checks if every vehicle in the list is archived
    * @returns {boolean} - Returns true if all vehicles are archived, otherwise false.
    */
    isOnlyArchivedVehicles() {
        const vehicles = this.getAllVehicles();
        return vehicles.length > 0 && vehicles.every(vehicle => vehicle.archived);
    }

    /**
     * Retrieves matching vehicles for the given data object.
     * @function
     * @param {object} toMatch - The data object with the vehicle data to compare.
     * @returns {array} - Array with the vehicles that match with the object provided on the toMatch param
     */
    getMatchingVehicles(toMatch) {
        const vehicles = this.getAllVehicles();

        if (!toMatch) {
            toMatch = {};
        }

        return vehicles.filter(vehicle => [
            (vehicle.year === toMatch.year),
            (vehicle.model === toMatch.model),
            (vehicle.make === toMatch.make),
            (vehicle.option === toMatch.option || vehicle.option === toMatch.fitment)
        ].every(item => item));
    }

    /**
     * Handles the new vehicle selector and returns an array of matching vehicles.
     * @function
     * @param {object} dataObject - The data object with the current step data.
     * @param {object} dataObject.postData - The post data for the request.
     * @param {string} dataObject.$productSelectorType - The product selector type.
     * @returns {false|array} A promise that resolves with false if no matching vehicle is matching, or an array with the vehicles.
     */
    handleNewVehicleSelector(dataObject) {
        const postData = dataObject && dataObject.postData;
        const $productSelectorType = dataObject && dataObject.$productSelectorType;
        const currentRef = postData.currentRef || [];        
        const toSave = {ft_CYEAR: "year", ft_MODEL: "model", ft_MAKE2: "make", ft_OPTION2: "fitment"};
        const isMatchingVehicleResolved = this.getValue("isMatchingVehicleResolved");
        const data = {};

        currentRef.map(item => { //NOSONAR
            const labelToSave = toSave[item.refName];
            if (labelToSave) {
                data[labelToSave] = item.refValue;
                return;
            }

            data[item.refName] = item.refValue;
        });

        this.setValue("newVehicle", data);
        if (dataObject && (!dataObject.$isMyGarage || dataObject.$isMyGarage === "undefined")) {
            return false;
        }

        if ($productSelectorType !== "add_edit_vehicle") {
            return false;
        }

        if (isMatchingVehicleResolved) {
            var isSameAsResolved = true;

            Object.keys(isMatchingVehicleResolved).map((key) => { //NOSONAR
                if (isMatchingVehicleResolved[key] !== data[key]) isSameAsResolved = false;
            });

            if (isSameAsResolved) {
                return false;
            }
        }

        return this.getMatchingVehicles({
            year: data.year,
            make: data.make,
            model: data.model,
            option: data.fitment
        });
    }

    /**
     * To get the current vehicle in focus of the user
     * @returns {object} Vehicle data
     */
    setCurrentVehicle(vehicleId) {
        return VehicleTools.currentVehicle = vehicleId;
    }
}

module.exports = VehicleTools;
