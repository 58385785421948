"use strict";

var imagesLoaded        = require("imagesloaded"),
    addProductToCart    = require("./product/addToCart"),
    ajax                = require("../ajax"),
    page                = require("../page"),
    productTile         = require("../product-tile"),
    quickview           = require("../quickview");

/**
 * @private
 * @function
 * @description Binds the click events to the remove-link and quick-view button
 */
function initializeEvents() {
    $("#compare-table").on("click", ".compare-remove-product", function (e) {
        e.preventDefault();
        ajax.getJson({
            url: this.href,
            callback: function () {
                page.refresh();
            }
        });
    })
        .on("click", ".open-quick-view", function (e) {
            e.preventDefault();
            var url = $(this).closest(".product").find(".thumb-link").attr("href");
            quickview.show({
                url: url,
                source: "quickview"
            });
        });

    $("#compare-category-list").on("change", function () {
        $(this).closest("form").submit();
    });

    // Setting equal heights
    imagesLoaded("#compare-table").on("done", function () {
        
        // Elements from compare page which must be synchronized
        var elements = [".tiles-container", ".categories", ".summary",  ".test-score", ".tire-specs"];

        for (var i = 0; i < elements.length; i++) {

            // Cache the highest
            var highestBox = 0;
              
            // Select and loop the elements you want to equalise
            $(elements[i]).each(function () {
                // If this box is higher than the cached highest then store it
                if ($(this).height() > highestBox) {
                    highestBox = $(this).height(); 
                }
            });  
                    
            // Set the height of all those children to whichever was highest 
            $(elements[i]).height(highestBox);
        } 
    });
}

exports.init = function () {
    productTile.init();
    initializeEvents();
    addProductToCart();
};
