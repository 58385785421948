const FormEvent = require("./FormEvent");

/**
 * Represents a model of events for a form.
 *
 * @class
 */
class FormEventsModel {
    /**
     * Creates an instance of FormEventsModel.
     *
     * @constructor
     * @param {{
     *   onConstructStart: function,
     *   onConstructEnd: function,
     *   onListenersLoadStart: function,
     *   onListenersLoadEnd: function,
     *   onSetValue: function,
     *   onStepChange: function,
     *   customEvents: Array.<{name: string, handler: function}>
     * }} options - Options for various events.
     * @param {FormCtrl} [_parentForm] - An optional reference to the parent form.
    */
    constructor({
        onConstructStart,
        onConstructEnd,
        onListenersLoadStart,
        onListenersLoadEnd,
        onSetValue,
        onStepChange,
        customEvents
    }, _parentForm) {
        this._parentForm = () => _parentForm;

        this.onConstructStart = new FormEvent("constructStart", onConstructStart, this);
        this.onConstructEnd = new FormEvent("constructEnd", onConstructEnd, this);
        this.onListenersLoadStart = new FormEvent("listenersLoadStart", onListenersLoadStart, this);
        this.onListenersLoadEnd = new FormEvent("listenersLoadEnd", onListenersLoadEnd, this);
        this.onSetValue = new FormEvent("setValue", onSetValue, this);
        this.onStepChange = new FormEvent("stepChange", onStepChange, this);

        if (Array.isArray(customEvents)) {
            customEvents.map((ev, index) => { //NOSONAR
                if (typeof ev.name !== "string") {
                    ev.name = "custom_event_" + index;
                }

                this[ev.name] = new FormEvent(ev.name, ev, this);
            });
        }
    }

    /**
     * Gets the parent FormCtrl object from the _parentForm parameter.
     *
     * @readonly
     * @returns {FormCtrl} - The parent form object.
     */
    get parentForm() {
        return this._parentForm();
    }
}

/**
 * @module FormEventsModel
 */
module.exports = FormEventsModel;
