const FormCtrl = require("./index");

/**
 * @class
 * @description A class to control the Carfax query process, here you can find a bunch of useful methods to get values from the form, navigate through the form steps, reseet the fields and store data to the session storage.
 * @param {object} param0 To setup the CarfaxFormCtrl
 * @param {function} param0.listeners Function with the custom listeners needed to handle the form.
 * @param {string} param0.renderResultAsStep The step name which should be redirected when the ajax call arrive with success.
 * @param {boolean} param0.renderResultOnVehicleTable If you have multible places in the same page, set this to true, this way the result will be rendered in the correct place
 */
class CarfaxFormCtrl extends FormCtrl {
    constructor({
        renderResultAsStep,
        renderResultOnVehicleTable,
        redirectToCarfaxHistory
    }) {
        super(arguments[0] || {});

        this.plateUnsupportedStates = ["AB"];
        this.renderResultAsStep = renderResultAsStep;
        this.renderResultOnVehicleTable = renderResultOnVehicleTable || false;
        this.redirectToCarfaxHistory = redirectToCarfaxHistory;

        this.resetForm();
    }

    /**
     * Build the data object stringified to use in the AJAX call that request the service history from Carfax
     * @returns {JSON} Stringified data to send in the Carfax ajax call
     */
    serviceHistoryAjaxData() {
        const vinValue = this.getInputValue("vin");
        const plateValue = this.getInputValue("plate");
        const stateValue = this.getInputValue("state");
        const isToSaveVin = this.getInputValue("vehicleSave");
        const currentVehicle = this.getCurrentVehicle();
        const result = {
            vehicleId: currentVehicle.id
        };

        if (vinValue || (isToSaveVin && currentVehicle.vin)) {
            result.action = "vin";
            result.vin = vinValue || currentVehicle.vin;
        } else if (plateValue && stateValue) {
            result.action = "plate";
            result.plateNo = plateValue;
            result.state = stateValue;
        } else {
            return;
        }

        return JSON.stringify(result);
    }

    /**
     * For the Carfax query, it's necessary that the VIN field is provided or, plate and state. The VIN is priority, so if VIN was provided the other isn't necessary. But if the plate or state was used, then we need the both. plate and state.
     * @returns {boolean}
     */
    validateFields() {
        const stateValue = this.getInputValue("state");
        const $vin = this.getFields("vin");
        const $plate = this.getFields("plate");
        const $state = this.getFields("state");
        const required = Boolean(this.getInputValue("vin") || this.getInputValue("plate") && this.getInputValue("state"));

        if (!$vin || !$plate || !$state) {
            return false;
        }

        if (!this.triggerValidation("vin")) {
            this.elements.$continueButton.attr("disabled", "true");
        }

        if (this.plateUnsupportedStates.find(current => stateValue === current)) {
            $vin.focus();
            $plate.attr("disabled", "true");
            $state.addClass("error");
            $(".error-vin").show();

            return this.getInputValue("vin") ? true : false;
        } else if ($plate && this.getInputValue("plate").length === 1) {
            this.elements.$continueButton.attr("disabled", "true");
            return false;
        } else {
            $state.removeClass("error");
            $plate.removeAttr("disabled");
            $(".error-vin").hide();
        }

        const isInvalidFieldsEmpty = !Object.keys(this.invalidFields).length;
        return required && isInvalidFieldsEmpty;
    }

    /**
     * To delete Carfax vehicle data from the sessionStorage
     * @param {string} vehicleId Unique identification of the vehicle
     * @returns {void}
     */
    deleteVehicleOnSessionStorage(vehicleId) {
        window.sessionStorage.removeItem("carfax_" + vehicleId);
    }

    /**
     * Redirects the user to the Carfax History page for the currently selected vehicle.
     * @function
     * @returns {void}
    */
    redirectToCarfaxHistoryPage() {
        const currentVehicle = this.getCurrentVehicle();
        this.goToStep("loading");
        currentVehicle && open(Urls.carfaxHistoryPage + `?selectedVehicle=${currentVehicle.id}`, "_self");
    }

    /**
     * To save vehicle data coming from Carfax on the browser sessionStorage.
     * @param {string} vehicleId Unique identification of the vehicle to save on sessionStorage.
     * @param {object} data Vehicle data to save.
     * @returns {void}
     */
    setToSessionStorage(vehicleId, data) {
        window.sessionStorage.setItem("carfax_" + vehicleId, JSON.stringify(data));
    }

    /**
     * To get Carfax vehicle data from the sessionStorage
     * @param {string} vehicleId Unique identification of the vehicle
     * @returns {object} Carfax data stored
     */
    getFromSessionStorage(vehicleId) {
        const result = window.sessionStorage.getItem("carfax_" + vehicleId);

        if (result) {
            return JSON.parse(result);
        } else {
            return null;
        }
    }

    /**
     * @description Handle the rendering of Carfax results.
     * @param {object} serviceHistory The serviceHistory property returned from the Carfax service history request
     * @param {CarfaxFormCtrl} formCtrl 
     */
    renderCarfaxResults(serviceHistory) {
        const currentVehicle = this.getCurrentVehicle();

        if (this.renderResultAsStep) {
            this.goToStep("result");
        } else {
            if (this.redirectToCarfaxHistory) {
                this.redirectToCarfaxHistoryPage();
            } else {
                this.resetForm();
                this.$closeButton.trigger("click");
            }
        }

        if (this.renderResultOnVehicleTable) {
            this.switchVehicleDataView("has-service", currentVehicle.id);
            this.renderHistoryTable(serviceHistory, $(`.service-history-wrap[vehicle-id="${currentVehicle.id}"]`));
        } else {
            this.switchVehicleDataView("has-service");
            this.renderHistoryTable(serviceHistory);
        }

        this.fillVehicleDataOnModal("#carfax-history-modal", serviceHistory);
    }

    /**
     * @description To alternate the vehicle data on the DOM
     * @param {string} state There are 3 states: 'initial', 'no-service' and 'has-service'. Choose one of them to alternate the views.
     */
    switchVehicleDataView(state, vehicleId) {
        switch (state) {
            case "initial": break;
            case "no-service": break;
            case "has-service": break;
            default: return;
        }

        if (vehicleId) {
            let $wrap = $(`.service-history-wrap[vehicle-id="${vehicleId}"]`);

            if (!$wrap.length) {
                $wrap = this.elements.$carfaxServiceWrap;
            }

            $wrap.attr("toggle-state", state);
        } else {
            this.elements.$carfaxServiceWrap.attr("toggle-state", state);
        }
    }

    /**
     * @function
     * @description Have the purpose of fill the vehicle data on the vehicle confirmation step of the Carfax modal
     * @param {string} modalSelector String with the unique CSS selector of the Carfax modal
     * @param {object} serviceHistory The serviceHistory property returned from the Carfax service history request
     */
    fillVehicleDataOnModal(modalSelector, serviceHistory) {
        const $modal = $(modalSelector);
        const $vehicleInformation = $modal.find(".vehicle-information");
        const $make = $vehicleInformation.find(".make");
        const $model = $vehicleInformation.find(".model");
        const $year = $vehicleInformation.find(".year");

        $make.text(serviceHistory.make);
        $model.text(serviceHistory.model);
        $year.text(serviceHistory.year);
    }

    /**
     * @description Error callback to use on Carfax queries.
     * @param {object} err The error response coming from carfax query
     * @param {array} err.errors An array with the errors messages
     */
    searchResponseError(err) {
        this.goToStep("error");
        this.elements.$continueButton.attr("disabled", "true");

        if (err && err.errors && Array.isArray(err.errors)) {
            const unavailableError = err.errors.find(message => Resources.SERVICE_UNAVAILABLE === message);

            if (unavailableError) {
                this.elements.$unavailableErrorTitle.show();
            } else {
                this.elements.$unavailableErrorTitle.hide();
            }
        } 
    }

    /**
     * @description Render the Carfax response in the table
     * @param {object} serviceHistory Data object coming from Carfax.
     * @param {jquery} $elementToRender If you need to render the result in a different place, add the JQuery element here.
     */
    renderHistoryTable(serviceHistory, $elementToRender) {
        const $wrapper = $elementToRender || this.elements.$carfaxServiceWrap;
        const $serviceHistory = $wrapper.find(".service-history");
        const $headerTitle = $serviceHistory.find(".header-content .header-title");
        const $historyTable = $serviceHistory.find(".history-table .table-body");

        // Adding car name (table title)
        if ($headerTitle.length && $historyTable.length) {
            $historyTable.empty();
            $headerTitle.text(`${serviceHistory.year || ""} ${serviceHistory.make || ""} ${serviceHistory.model || ""}`);
        }

        // Adding history data
        if ($historyTable.length && serviceHistory.displayRecords) {
            serviceHistory.displayRecords.map(record => {
                if (record.odometerReading) {
                    if (typeof record.odometerReading === "number" || (record.odometerReading.indexOf && record.odometerReading.indexOf(Resources.CARFAX_DEFAULT_ODOMETER_UNIT) === -1)) {
                        record.odometerReading = record.odometerReading.toLocaleString("en-CA") + " " + Resources.CARFAX_DEFAULT_ODOMETER_UNIT;
                    }
                }

                $historyTable.append(`
                    <div class="table-row">
                        <div class="table-column">
                            <p>${record.displayDate || ""}</p>
                        </div>
                        <div class="table-column">
                            <p>${record.odometerReading || ""}</p>
                        </div>
                        <div class="table-column">
                            <p>${record.shopName || ""}</p>
                        </div>
                        <div class="table-column services-performed">
                            <b class="mobile-header">${Resources.CARFAX_TABLE_SERVICESPERFORMED}</b>
                            ${(record.text && record.text.length && record.text.map(item => item && `<p>${item}</p>`).join("\n")) || ""}
                        </div>
                    </div>
                `);
            });
        }
    }
}

CarfaxFormCtrl.getConfig = FormCtrl.getConfig;
CarfaxFormCtrl.search = FormCtrl.search;

/**
 * @module CarfaxFormCtrl
 */
module.exports = CarfaxFormCtrl;
