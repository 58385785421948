"use strict";

var visualizer      = require("./visualizer"),
    productSelector = require("../productselector"),
    FormCtrl        = require("../FormCtrl"),
    CarfaxFormCtrl  = require("../FormCtrl/carfaxFormCtrl");

/**
 * Show or hide the Archived Cars
 */
function toggleArchivedCars() {
    var $showArchivedText = $(".show-archived-text");
    var $hideArchivedText = $(".hide-archived-text");

    if ($showArchivedText.hasClass("hide")) {
        $showArchivedText.removeClass("hide");
        $hideArchivedText.addClass("hide");
    } else {
        $showArchivedText.addClass("hide");
        $hideArchivedText.removeClass("hide");
    }

    $(".archived-cars").toggleClass("hide");
}

/**
 * Handle Plate Number errors
 * 
 * @param {Object} $this
 * @returns true or false
 */
function handlePlateNumberInputErrors($this) {
    var inputValue    = $this.val(),
        $plateNoError = $this.closest(".plate-no-container").find(".plate-no-error");

    // add or remove error classes and error message
    if (inputValue.length === 1 && !$this.is(":focus")) {
        $this.addClass("error"); 
        $plateNoError.removeClass("hide");

        return false;
    } else {
        $this.removeClass("error");
        $plateNoError.addClass("hide");

        return true;
    }
}


/**
 * Scroll car into view
 *
 * @param {Object} $carContainer
 */
function scrollCarIntoView($carContainer) {
    var $existingVehicleFirstElement = $($carContainer[0]),
        $headerMainNavHeight         = $(".header-main-nav").outerHeight();

    // get car container offset to the top
    var $carContainerTopOffset = $existingVehicleFirstElement.offset().top.toFixed() - $headerMainNavHeight;

    // scroll into view
    $("html, body").animate({
        scrollTop: $carContainerTopOffset
    }, 1000);

    // highlight the vehicle
    $existingVehicleFirstElement.addClass("highlight-existing-car");

    setTimeout(function () {
        // after 2 seconds transition the border to 0
        $existingVehicleFirstElement.addClass("transition-to-end");

        setTimeout(function () {
            // after another half of second reset the highlight
            $existingVehicleFirstElement.removeClass("highlight-existing-car").removeClass("transition-to-end");
            $existingVehicleFirstElement.removeAttr("style");
        }, 500);
    }, 2000);
}

/**
 * Handle Car Archive Delete modal
 *
 * @param {Object} event
 */
function handleCarActionsModal($this, event, actionType) {
    event.preventDefault();

    var $modal                    = $(".car-edit-modal"),
        $archiveCarModalContent   = $(".archive-car-modal-content"),
        $deleteCarModalContent    = $(".delete-car-modal-content"),
        $carArchiveDeleteAction   = $(".car-archive-delete-action"),
        $carContainer             = $this.closest(".car-container"),
        vehicleId                 = $carContainer.data("vehicle-id"),
        $carArchive               = $carContainer.find(".car-archive"),
        $carDelete                = $carContainer.find(".car-delete"),
        $carReinstate             = $carContainer.find(".car-reinstate"),
        $carAppointment           = $carContainer.find(".car-appointment"),
        $carBuyActions            = $carContainer.find(".car-buy-actions"),
        $archivedSectionContainer = $(".archived-section-container"),
        $archivedCarsCount        = $(".archived-cars .car-container").length;

    // hide the modal content
    $archiveCarModalContent.addClass("hide");
    $deleteCarModalContent.addClass("hide");

    var dataObject = {
        "id": vehicleId
    };

    if (actionType === "archive") {
        $archiveCarModalContent.removeClass("hide");

        $carArchiveDeleteAction.off("click").on("click", function () {
            $.ajax({
                type: "POST",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.archiveVehicle, // eslint-disable-line
                data: JSON.stringify(dataObject)
            }).done(function (response) {
                if (response.success) {
                    if ($carContainer.length > 0) {
                        // hide appointment and car actions
                        $carAppointment.addClass("hide");
                        $carBuyActions.addClass("hide");

                        // show the reinstate button and hide the others
                        $carArchive.addClass("hide");
                        $carDelete.addClass("hide");
                        $carReinstate.removeClass("hide");

                        // move to archived cars
                        $carContainer.appendTo(".archived-cars");

                        // show the archived section
                        $archivedSectionContainer.removeClass("hide");

                        initializedModal.close();
                    }
                }
            });
        });
    } else if (actionType === "delete") {
        $deleteCarModalContent.removeClass("hide");

        $carArchiveDeleteAction.off("click").on("click", function () {
            $.ajax({
                type: "POST",
                dataType: "json",
                cache: false,
                contentType: "application/json",
                url: Urls.deleteVehicle, // eslint-disable-line
                data: JSON.stringify(dataObject)
            }).done(function (response) {
                if (response.success) {
                    if ($carContainer.length > 0) {
                        $carContainer.remove();
                        initializedModal.close();
                    }
                }
            });
        });
    }

    if (actionType === "reinstate") {
        $.ajax({
            type: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json",
            url: Urls.unArchiveVehicle, // eslint-disable-line
            data: JSON.stringify(dataObject)
        }).done(function (response) {
            if (response.success) {
                if ($carAppointment.length > 0) {
                    $carAppointment.removeClass("hide");
                } else {
                    $carBuyActions.removeClass("hide");
                }

                // show the archive button and hide the others
                $carArchive.removeClass("hide");
                $carDelete.addClass("hide");
                $carReinstate.addClass("hide");

                if ($archivedCarsCount === 1) {
                    // hide the archived section
                    $archivedSectionContainer.addClass("hide");
                }

                // move car to cars container
                $carContainer.prependTo(".cars-container");

                // scroll reinstated car into view
                scrollCarIntoView($carContainer);
            }
        });
    } else {
        $modal.foundation("destroy");
        var initializedModal = new Foundation.Reveal($modal); // eslint-disable-line
        initializedModal.open();
    }
}

/**
 * Initialize car Archive & Delete events
 */
function initializeCarArchiveDeleteEvents() {
    var $document = $(document);

    $document.on("click", ".car-archive", function (event) {
        handleCarActionsModal($(this), event, "archive");
    });

    $document.on("click", ".car-delete", function (event) {
        handleCarActionsModal($(this), event, "delete");
    });

    $document.on("click", ".car-reinstate", function (event) {
        handleCarActionsModal($(this), event, "reinstate");
    });
}

/**
 * Initialize Car Buy Actions
 */
function initializeCarBuyActions() {
    var $serviceActionsModal = $(".service-actions-modal");

    $(".services-cta").on("click", function () {
        var vehicleId  = $(this).data("vehicle-id");

        if ($serviceActionsModal.length && vehicleId && vehicleId.length) {
            $serviceActionsModal.attr("data-vehicle-id", vehicleId);
        }
    });

    $(document).on("click", ".car-buy-action", function (event) {
        event.preventDefault();
        const addNewVehicleForm = FormCtrl.search("addNewVehicle");
        const newVehicle = Object((addNewVehicleForm instanceof FormCtrl) && addNewVehicleForm.getValue("newVehicle"));
        var $this = $(this);
        var $carContainer = null;

        if ($this.closest(".car-container").length) {
            $carContainer = $this.closest(".car-container");
        } else if ($serviceActionsModal.length && $($serviceActionsModal[0]).attr("data-vehicle-id").length) {
            var servicesModalVehicle = $($serviceActionsModal[0]).attr("data-vehicle-id");

            $carContainer = $(`.car-container[data-vehicle-id="${servicesModalVehicle}"]`);
        }

        var vehicleAction     = $this.data("action"),
            dataFolder        = $this.data("folder"),
            serviceUrl        = $this.data("service-url"),
            vehicleId         = newVehicle.id || $carContainer.data("vehicle-id"),
            vehicleYear       = newVehicle.year || $carContainer.data("vehicle-year"),
            vehicleMake       = newVehicle.make || $carContainer.data("vehicle-make"),
            vehicleModel      = newVehicle.model || $carContainer.data("vehicle-model"),
            vehicleSubModel   = newVehicle.fitment || $carContainer.data("vehicle-submodel"),
            vehicleOption     = newVehicle.option || $carContainer.data("vehicle-option"),
            vehicleSize       = newVehicle.Size || $carContainer.data("fitmentids");

        FormCtrl.selectedVehicleToAction = vehicleId;
        sessionStorage.setItem("verifyVehicle_selectedVehicleId", vehicleId);

        // check if vehicle size / fitment ID is missing when it's not brakes context (which is only YMM)
        if (!vehicleSize && vehicleAction !== "brakes_selector") {
            productSelector.getMissingDataPopup(vehicleYear, vehicleMake, vehicleModel, vehicleSubModel, vehicleOption, vehicleAction, dataFolder, serviceUrl, vehicleId);

            return;
        }

        productSelector.performShopAction(vehicleYear, vehicleMake, vehicleModel, vehicleSubModel, vehicleOption, vehicleSize, vehicleAction, serviceUrl, vehicleId);
    });
}

/**
 * Calls backend to get the appointment URL and makes a redirect
 *
 * @param {string} orderNumber
 */
function getAppointmentURL(orderNumber) {
    var dataObject = {
        "orderNumber": orderNumber
    };

    $.ajax({
        type: "POST",
        dataType: "json",
        cache: false,
        contentType: "application/json",
        url: Urls.createAppointmentLink, // eslint-disable-line
        data: JSON.stringify(dataObject)
    }).done(function (response) {
        if (response.success) {
            window.location = response.modifyAppointmentLink;
        }
    });
}

/**
 * Initialize Appointement Button Action
 */
function initializeAppointmentButtonAction() {
    $(document).on("click", ".modify-appointment", function (event) {
        event.preventDefault();
        var orderNumber = $(this).data("ordernumber");
        getAppointmentURL(orderNumber);
    });
}

/**
 * Initialize events
 */
function initializeEvents() {
    var $toggleArchivedCars = $(".toggle-archived-cars"),
        $modifyAppointment  = $(".modify-appointment"),
        carfaxModal         = CarfaxFormCtrl.search("carfax");

    initializeCarArchiveDeleteEvents();
    initializeCarBuyActions();
    initializeAppointmentButtonAction();

    // TODO: Uncomment and add actions in Drop4
    // $carHistory.on('click', function(event) {
    //     event.preventDefault();
    // });

    $modifyAppointment.on("click", function (event) {
        event.preventDefault();
    });

    $toggleArchivedCars.on("click", function (event) {
        event.preventDefault();
        toggleArchivedCars();
    });

    $toggleArchivedCars.on("keydown", function (event) {
        event.preventDefault();
        if (event.keyCode === 13 || event.keyCode === 32) {
            toggleArchivedCars();
        }
    });

    $(".cars-container [data-open]").on("click", function () {
        carfaxModal.goToStep("initial"); // eslint-disable-line
    });

    // Vehicle cards, select the vehicle clicked
    $(".car-carfax-history").on("click", function () {
        const $this = $(this);
        const $carContainer = $this.parents(".car-container");
        const vehicle = carfaxModal.getVehicle($carContainer.data("vehicle-id")); // eslint-disable-line

        $carContainer.parents(".service-history-wrap").attr("vehicle-id", vehicle.id);
        $("#carfax-current-vehicle").val(vehicle.id);

        // Filling the form with the existents values
        if (vehicle.plate) {
            carfaxModal.setField("plate", vehicle.plate);

            if (vehicle.vin) {
                carfaxModal.setField("vin", vehicle.vin);
            }
        }

        const dataStored = carfaxModal.getFromSessionStorage(vehicle.id); // eslint-disable-line
        if (dataStored) {
            carfaxModal.fillVehicleDataOnModal("#carfax-history-modal", dataStored.serviceHistory);

            if (carfaxModal.renderResultOnVehicleTable) {
                carfaxModal.renderHistoryTable(dataStored.serviceHistory, $(`.service-history-wrap[vehicle-id="${vehicle.id}"]`));
                carfaxModal.switchVehicleDataView("has-service", vehicle.id);
                carfaxModal.goToStep("search");
            } else {
                carfaxModal.renderHistoryTable(dataStored.serviceHistory);
                carfaxModal.switchVehicleDataView("has-service", vehicle.id);
            }

            if (carfaxModal.renderResultAsStep) { // eslint-disable-line
                carfaxModal.goToStep("result"); // eslint-disable-line
            }

            if (carfaxModal.redirectToCarfaxHistory) { // eslint-disable-line
                carfaxModal.redirectToCarfaxHistoryPage(); // eslint-disable-line
            }
            carfaxModal.setValue("vehicleVin", dataStored.serviceHistory.vin); // eslint-disable-line
        } else {
            carfaxModal.goToStep("initial"); // eslint-disable-line
        }

        if (carfaxModal.validateFields()) { // eslint-disable-line
            carfaxModal.elements.$continueButton.removeAttr("disabled"); // eslint-disable-line
        } else {
            carfaxModal.elements.$continueButton.attr("disabled", "true"); // eslint-disable-line
        }
    });
}

var mygarage = {
    init: function () {
        // RideStyler Visualizer integration
        visualizer.initMygarageVehicleImageUrl();

        // Changing default config
        const carfaxFormConfig = CarfaxFormCtrl.getConfig("carfax");
        const editVehicleDataFormConfig = FormCtrl.getConfig("editVehicleData");
        carfaxFormConfig.redirectToCarfaxHistory = true;
        editVehicleDataFormConfig.values.handlePlateNumberInputErrors = handlePlateNumberInputErrors;

        // Initializing CarfaxFormCtrl, it's using the default setup, if you want to use another form here, provide the setup manually
        new CarfaxFormCtrl(carfaxFormConfig);
        // Initialize the vehicle data edit form
        new FormCtrl(editVehicleDataFormConfig);

        initializeEvents();
    }
};

module.exports = mygarage;
